import React, { useEffect } from "react";
import styles from "./Agreement.module.scss";
import { Title } from "../../content/title";
import closeIcon from "../../static/close_button.svg";

interface Props {
  lang: string;
  handleCloseModal: () => void;
  handleAccept: () => void;
}

export default function AgreementMsk({ lang, handleCloseModal, handleAccept }: Props) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.parentIFrame && window.parentIFrame.sendMessage('scrollTop')
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.closeModalBtnBox}>
        <img
          src={closeIcon}
          alt="x"
          onClick={() => {
            handleCloseModal();
            window.parentIFrame && window.parentIFrame.sendMessage('scrollBottom')
          }}
        />
      </div>
      {lang === "ru" && (
        <div className={styles.agreementText}>
          <h1>Использование персональных данных </h1>
          <p>
            Чтобы стать клиентом ООО «Ренталс» и пользоваться услугами компании необходимо зарегистрироваться на Сайте и заключить договор аренды движимого имущества. Для этого Пользователю необходимо заполнить регистрационную анкету, предоставив ООО «Ренталс» свои персональные данные.
            Предоставляя свои персональные данные при регистрации на Сайте, Пользователь даёт ООО «Ренталс» согласие на использование своих персональных данных согласно ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. различными способами на срок, пока Пользователь не отзовет свое согласие, либо в случае утраты необходимости достижения цели использования.
            ООО «Ренталс» осуществляет использование персональных данных следующим образом: хранение, систематизация, сбор, накопление, уточнение, использование, обезличивание, передача, удаление.
            Пользователь предоставляет следующую информацию: фамилию, имя, отчество, контактный номер телефона, адрес электронной почты, серию и номер паспорта гражданина РФ, дату и орган выдачи паспорта гражданина РФ, дату рождения, адрес регистрации, фактический адрес проживания, ссылки на личные страницы в социальных сетях, ссылку на персональный сайт, место работы и/или учёбы, фотографию, сканы документов: паспорт РФ, заграничный паспорт, ИНН, СНИЛС, водительское удостоверение, военный билет.
            Персональные данные используются ООО «Ренталс» в целях:
          </p>
          <ul>
            <li>регистрации Пользователя на Сайте;</li>
            <li>исполнения обязательств в рамках договора аренды движимого имущества;</li>
            <li>
                продвижения услуг и/или товаров ООО «Ренталс» и/или партнеров ООО «Ренталс» на рынке путем осуществления прямых контактов с клиентами ООО «Ренталс» с помощью различных средств связи, в т.ч., но не ограничиваясь, по телефону, электронной почте, почтовой рассылке, в сети Интернет и т.д.;
            </li>
            <li>осуществления прав и законных интересов ООО «Ренталс»;</li>
            <li>оказания иных услуг и в иных целях, если действия ООО «Ренталс» не противоречат действующему законодательству.</li>
            <p>
                ООО «Ренталс» обязуется не разглашать полученную от Пользователя информацию. 
                При этом не считается нарушением обязательств разглашение информации в случае, когда обязанность такого раскрытия установлена требованиями действующего законодательства РФ.
            </p>
            <p>
                Пользователь вправе отозвать своё согласие на использование своих персональных данных путем написания и передачи заявления на бумажном носителе представителю ООО «Ренталс» в главном офисе компании, находящемся по адресу Каменноостровский пр., дом 26-28. 
                В этом случае ООО «Ренталс» автоматически прекращает исполнение своих обязательств в рамках договора движимого имущества и иных, возникших между Пользователем и ООО «Ренталс». Персональные данные Пользователя будут использоваться до момента исполнения всех обязательств Пользователя перед ООО «Ренталс» в рамках договора движимого имущества и иных, а после будут удалены.
            </p>
          </ul>
          <b>
            ВАЖНО! На данном сайте используется SSL-сертификат, и передача всех данных происходит по протоколу HTTPS. Это уникальная технология, необходимая для организации защищенного соединения
            между клиентом и сервером, что особенно важно при передаче конфиденциальной информации и проведении финансовых операций.
          </b>
        </div>
      )}
      {lang === "en" && (
        <div className={styles.agreementText}>
          <h1>Use of personal data </h1>
          <p>
            To become a client of LLC "Rental" and use the services of the company, it is necessary to register on the Site and enter into a lease of movable property. To do this, the user must fill out the registration form, providing LLC "Rental" with their personal data.
            By providing its personal data upon registration on the Site, the User grants to LLC "Rental" the consent to use its personal data in accordance with Federal Law No. 152-FZ "On Personal Data" of July 27, 2006 in various ways for a period until the User withdraws his consent, or in the event of the loss of the need to achieve the purpose of use.
            LLC "Rentals" uses personal data as follows: storage, systematization, collection, accumulation, clarification, use, depersonalization, transfer, removal.
            The user provides the following information: surname, first name, patronymic, contact phone number, e-mail address, serial number and passport number of the citizen of the Russian Federation, date and body issuing the passport of a citizen of the Russian Federation, date of birth, registration address, actual address, links to personal pages in social networks, a link to a personal website, a place of work and / or studies, a photo, scans of documents: a passport of the Russian Federation, a foreign passport, TIN, SNILS, driver's license, military ticket.
            Personal data is used by LLC "Rental" in order to:
          </p>
          <ul>
            <li>Registration of the User on the Site;</li>
            <li>fulfillment of obligations under the lease of movable property;</li>
            <li>
                promotion of the services and / or goods of LLC "Rental" and / or partners of LLC "Rental" on the market by making direct contacts with clients of LLC "Rental" through various means of communication, including, but not limited to, telephone, electronic mail, mailing, on the Internet, etc .;
            </li>
            <li>implementation of rights and legal interests of LLC "Rental";</li>
            <li>rendering of other services and for other purposes, if the actions of LLC "Rental" do not contradict the current legislation.</li>
            <p>
                LLC "Rental" undertakes not to disclose the information received from the User. 
                In this case, disclosure of information in the case when the duty of such disclosure is established by the requirements of the current legislation of the Russian Federation is not considered a violation of obligations.
            </p>
            <p>
                The user has the right to withdraw his consent to use his personal data by writing and submitting the application on paper medium to the representative of LLC "Rental" in the company's head office, located at Kamennoostrovsky pr., House 26-28. In this case, LLC "Rental" automatically terminates its obligations under the contract of movable property and other arisen between the User and LLC "Rental". 
                Personal data of the User will be used until the fulfillment of all obligations of the User to LLC "Rentals" under the contract of movable property and others, and then will be removed.
            </p>
          </ul>
          <b>
            important! This site uses an SSL certificate, and all data is transmitted over the HTTPS protocol. This is a unique technology necessary for the organization of a secure connection between
            the client and the server, which is especially important when transferring confidential information and conducting financial transactions.
          </b>
        </div>
      )}

      <div className={styles.buttonsContainer}>
        <button className={styles.hollowButton} value={"Назад"} onClick={() => handleCloseModal()}>
          {Title.back[lang]}
        </button>
        <button
          className={styles.blueButton}
          value={"Принять"}
          onClick={() => {
            handleAccept();
            handleCloseModal();
          }}
        >
          {Title.accept[lang]}
        </button>
      </div>
    </div>
  );
}
