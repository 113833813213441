import React, { useMemo } from "react";
import LabeledInput from "./inputs/LabeledInput";
import { Fields } from "../content/fields";
import { Errors } from "../content/errors";
import { Lang } from "src/types/common-types";
import gs from "../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../store/store";
import { validateDate, yearsAgo } from "../validate/validator";
import { CITY, INSTANCE } from "../service/irenta-server-parameters";
import AlaDistrict from "../content/District/ala_district";
import MskDistrict from "../content/District/msk_district";
import SpbDistrict from "../content/District/spb_district";
import SelectInput from "./inputs/SelectInput";
import citizenships from "../content/citizenship";
import CheckBox from "./inputs/CheckBox";
import AddressInputs from "./AddressInputs/AddressInputs";
import KzRegionCityListKz from "../content/RegionCity/kz_region-city_kz";
import KzRegionCityListEn from "../content/RegionCity/kz_region-city_eng";
import KzRegionCityList from "../content/RegionCity/kz_region-city";
import DateInput from "./inputs/DateInput";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
  schedule: number;
}

function MainInfo({ lang, state, invalidFields, dispatch, disabled, schedule }: Props) {
  const districtLocales = useMemo(() => {
    return CITY === "spb" ? SpbDistrict : CITY === "msk" ? MskDistrict : AlaDistrict;
  }, [lang]);
  const districtOptions = districtLocales.map((item) => {
    console.log(INSTANCE);
    return {
      value: item.value[lang],
      label: item?.label[lang]
    }
  }).sort((x: any, y: any) =>
  INSTANCE === 'spb' ? 
    ['Петроградский район', 'Petrogradsky district'].includes(x.value) ? -1 : 1 :
  INSTANCE === 'msk' ? 
    ['Южнопортовый', 'Yuzhnoportovy'].includes(x.value) ? -1 : 1 :
  INSTANCE === 'second' ? 
    ['Центральный район', 'Tsentralny District'].includes(x.value) ? -1 : 1 :
    ['Алмалинский район', 'Almaly district'].includes(x.value) ? -1 : 1
  
)
  const citizenshipsOptions = 
  INSTANCE === 'ala' ? 
    citizenships.map((item) => {
      return {
        value: item.value,
        label: item?.label[lang]
      }
    }).sort((x: any, y: any) =>
      ['398', '643'].includes(x.value.toString()) ? -1 : 1
    )
    .sort((x: any, y: any) => {
      return x.value.toString() === '398'
        ? -1
        : y.value.toString() == '398'
        ? 1
        : 0
    }) : citizenships.map((item) => {
      return {
        value: item.value,
        label: item?.label[lang]
    }})
  return (
    <>
      <div className={gs.row}>
        <div className={gs.col12}>
          <LabeledInput
            name={"lastname"}
            disabled={disabled}
            label={Fields.lastName?.label[lang]}
            onChange={(e) => {
              dispatch({
                type: actions.LASTNAME,
                data: e.target.value,
              });
            }}
            value={state.lastname}
            required={true}
            errorBorder={invalidFields.includes("lastname")}
            error={invalidFields.includes("lastname") && Errors.lastName[lang]}
          />
        </div>
        <div className={gs.col12}>
          <LabeledInput
            name={"firstname"}
            disabled={disabled}
            label={Fields.firstName?.label[lang]}
            onChange={(e) => {
              dispatch({
                type: actions.FIRSTNAME,
                data: e.target.value,
              });
            }}
            value={state.firstname}
            required={true}
            errorBorder={invalidFields.includes("firstname")}
            error={invalidFields.includes("firstname") && Errors.firstName[lang]}
          />
        </div>
        <div className={gs.col12}>
          <LabeledInput
            name={"patronymic"}
            disabled={disabled}
            label={Fields.patronymic?.label[lang]}
            onChange={(e) => {
              dispatch({
                type: actions.PATRONYMIC,
                data: e.target.value,
              });
            }}
            value={state.patronymic}
            errorBorder={invalidFields.includes("patronymic")}
            error={invalidFields.includes("patronymic") && Errors.patronymic[lang]}
          />
        </div>
      </div>
      <div className={gs.row}>
        <div className={gs.col6}>
          <DateInput
            name={"birthdate"}
            lang={lang}
            disabled={disabled}
            label={Fields.birthDate?.label[lang]}
            onChange={(e) => {
              dispatch({
                type: actions.BIRTHDATE,
                data: e,
              });
            }}
            value={state.birthdate ?? new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
            curr={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
            min={new Date(new Date().setFullYear(new Date().getFullYear() - 85))}
            max={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
            required={true}
            errorBorder={
              invalidFields.includes("birthdate")
            }
            error={invalidFields.includes("birthdate") && Errors.birthDate[lang]}
          />
        </div>
      </div>
      <div className={gs.row}>
        <div className={gs.col6}>
          <SelectInput
            name={"citizenship"}
            className="citizenship"
            disabled={disabled}
            options={citizenshipsOptions}
            changeHandler={(e: { value: string; label: string }) => {
              dispatch({
                type: actions.CITIZENSHIP,
                data: e.value,
              });
            }}
            placeholder={Fields.citizenship?.label[lang]}
            value={citizenshipsOptions.find((e) => {
              return e.value === state.citizenship;
            })}
            required={true}
            invalidFields={invalidFields}
            errorBorder={invalidFields.includes("citizenship")}
            error={invalidFields.includes("citizenship") && Errors.citizenship[lang]}
          />
        </div>
        { schedule !== 6 && <div className={gs.col12}>
          <CheckBox
            name={"currentlyInAnotherCityAndReadyForMove"}
            className={gs.grey}
            label={Fields.anotherCity?.label[lang]}
            onClick={() => {
              state.residenceAddress.currentlyInAnotherCityAndReadyForMove = !state.residenceAddress.currentlyInAnotherCityAndReadyForMove;
              state.residenceAddress.district = '';
              dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress,
              });
            }}
            active={state.residenceAddress.currentlyInAnotherCityAndReadyForMove}
          />
        </div>}
      </div>
      <div className={gs.row}>
        { schedule === 6 && <AddressInputs lang={lang} state={state} invalidFields={invalidFields} dispatch={dispatch} disabled={disabled} />}
        { schedule !== 6 && <div className={gs.col6}>
          <SelectInput
            name={"district"}
            className="district"
            disabled={disabled || state.residenceAddress.currentlyInAnotherCityAndReadyForMove}
            options={districtOptions}
            changeHandler={(e: { value: string; label: string }) => {
              state.residenceAddress.district = e.value;
              dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress,
              });
            }}
            placeholder={Fields.district?.label[lang]}
            value={districtOptions.find((e) => {
              return e.value === state.residenceAddress.district;
            })}
            required={true}
            invalidFields={invalidFields}
            errorBorder={invalidFields.includes("district")}
            error={invalidFields.includes("district") && Errors.district[lang]}
          />
        </div>}
      </div>
    </>
  );
}

export default MainInfo;
