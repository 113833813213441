import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";

declare global {
  interface Window {
    iFrameResizer: any;
    parentIFrame: any;
  }
}

createRoot(document.querySelector("#root") as HTMLElement).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
