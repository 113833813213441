import { TOKEN_ENDPOINT, SUBMIT_ENDPOINT, UPLOAD_ENDPOINT, GET_VACANCIES } from "./irenta-server-parameters";
import Applicant from "src/types/applicant";
import { Vacancy } from "src/types/vacancy";
import axios from "axios";

const getToken = async () => {
  const token = window.sessionStorage.getItem("token");
  const tokenLifeTime = window.sessionStorage.getItem("tokenLifeTime") ?? "0";
  console.log("tokenLifeTime", tokenLifeTime);
  console.log("newDate", Date.now() + 60 * 1000);
  if (token && tokenLifeTime >= (Date.now() + 60 * 1000).toString()) {
    return token;
  }

  const response = await fetch(`${TOKEN_ENDPOINT}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
  });

  if (response.status !== 200) {
    throw new Error("Server error: " + response.status);
  }

  const { access_token } = await response.json();
  if (!access_token) {
    throw new Error("access_token is empty");
  }

  try {
    window.sessionStorage.setItem("token", access_token);
    window.sessionStorage.setItem("tokenLifeTime", `${Date.now()}`);
  } catch {
    // do nothing
  }

  return access_token;
};
export const getVacancies = async (lang: string) => {
  const token = await getToken();
  try {
    const result = await axios({
      method: "POST",
      url: GET_VACANCIES + `?language=${lang}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return result.data.filter((e: Vacancy) => {
      return e.vacancyStatus > 0;
    });
  } catch (error) {
    console.error(error);
    return "";
  }
};
export const uploadFile = async (file: FormData) => {
  const token = await getToken();
  const result = await axios({
    method: "POST",
    url: UPLOAD_ENDPOINT,
    data: file,
    headers: {
      authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  return result.data;
};
export const submitForm = async (data: Applicant) => {
  const token = await getToken();
  const response = await fetch(SUBMIT_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
    credentials: "include",
  })
    .then((response) => response.status)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  return response;
};
