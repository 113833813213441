import React from "react";
import CheckBox from "../inputs/CheckBox";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import gs from "../../Layout.module.scss";
import style from "./DriverLicense.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  showCar: boolean;
  driverLicenses: string[];
  dispatch: Dispatch;
}

function DriverLicense({ lang, state, invalidFields, showCar, driverLicenses, dispatch }: Props) {
  console.log('driverLicenses', driverLicenses)
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={style.driverLicenseButtonBox}>
          {/* <span className={gs.header}>
            {Title.driverLicenseTitle[lang]} ({Title.prerequisitesDriverLicenses[lang]} {driverLicenses.join(", ")})
          </span> */}
          { driverLicenses.includes('A') && <CheckBox
            label={Fields.driverA?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.DRIVER_LICENSE,
                data: state.driverLicenses.indexOf("A") === -1 ? state.driverLicenses.concat("A").filter((e) => e !== "No") : state.driverLicenses.filter((e) => e !== "A"),
              });
            }}
            active={state.driverLicenses.indexOf("A") !== -1}
            required={true}
          /> }
          { driverLicenses.includes('B') && <CheckBox
            label={Fields.driverB?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.DRIVER_LICENSE,
                data: state.driverLicenses.indexOf("B") === -1 ? state.driverLicenses.concat("B").filter((e) => e !== "No") : state.driverLicenses.filter((e) => e !== "B"),
              });
            }}
            active={state.driverLicenses.indexOf("B") !== -1}
            required={true}
          /> }
          { driverLicenses.includes('C') && <CheckBox
            label={Fields.driverC?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.DRIVER_LICENSE,
                data: state.driverLicenses.indexOf("C") === -1 ? state.driverLicenses.concat("C").filter((e) => e !== "No") : state.driverLicenses.filter((e) => e !== "C"),
              });
            }}
            active={state.driverLicenses.indexOf("C") !== -1}
            required={true}
          /> }
          { driverLicenses.includes('D') && <CheckBox
            label={Fields.driverD?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.DRIVER_LICENSE,
                data: state.driverLicenses.indexOf("D") === -1 ? state.driverLicenses.concat("D").filter((e) => e !== "No") : state.driverLicenses.filter((e) => e !== "D"),
              });
            }}
            active={state.driverLicenses.indexOf("D") !== -1}
            required={true}
          /> }
          {showCar ? (
            <CheckBox
              label={Fields.hasACar?.label[lang]}
              onClick={() => {
                dispatch({
                  type: actions.HAS_A_CAR,
                  data: !state.hasACar,
                });
              }}
              active={state.hasACar}
              required={true}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default DriverLicense;
