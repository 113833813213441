import React, { useEffect } from "react";
import styles from "./Agreement.module.scss";
import { Title } from "../../content/title";
import closeIcon from "../../static/close_button.svg";

interface Props {
  lang: string;
  handleCloseModal: () => void;
  handleAccept: () => void;
}

export default function AgreementAla({ lang, handleCloseModal, handleAccept }: Props) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.parentIFrame && window.parentIFrame.sendMessage('scrollTop')
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.closeModalBtnBox}>
        <img
          src={closeIcon}
          alt="x"
          onClick={() => {
            handleCloseModal();
            window.parentIFrame && window.parentIFrame.sendMessage('scrollBottom')
          }}
        />
      </div>
      {lang === "ru" && (
        <div className={styles.agreementText}>
          <h1>Использование персональных данных </h1>
          <p>
            Чтобы стать соискателем на трудоустройство, необходимо на сайте оформить отклик на вакансию. Для этого Пользователю необходимо заполнить регистрационную анкету, предоставив ТОО
            «Ренталс-Ала» свои персональные данные. Предоставляя свои персональные данные при регистрации на Сайте, Пользователь даёт ТОО «Ренталс-Ала» согласие на использование своих персональных
            данных различными способами на срок, пока Пользователь не отзовет свое согласие, либо в случае утраты необходимости достижения цели использования. ТОО «Ренталс-Ала» осуществляет
            использование персональных данных следующим образом: хранение, систематизация, сбор, накопление, уточнение, использование, обезличивание, передача, удаление. Пользователь предоставляет
            следующую информацию: фамилию, имя, отчество, контактный номер телефона, адрес электронной почты, серию и номер паспорта гражданина РФ, дату и орган выдачи паспорта гражданина РФ, дату
            рождения, адрес регистрации, фактический адрес проживания, ссылки на личные страницы в социальных сетях, ссылку на персональный сайт, место работы и/или учёбы, фотографию, сканы
            документов: паспорт РФ, заграничный паспорт, ИНН, СНИЛС, водительское удостоверение, военный билет. Персональные данные используются ТОО «Ренталс-Ала» в целях:
          </p>
          <ul>
            <li>регистрации Пользователя на Сайте;</li>
            <li>исполнения обязательств в рамках договора аренды движимого имущества;</li>
            <li>
              продвижения услуг и/или товаров ТОО «Ренталс-Ала» и/или партнеров ТОО «Ренталс-Ала» на рынке путем осуществления прямых контактов с клиентами ТОО «Ренталс-Ала» с помощью различных
              средств связи, в т.ч., но не ограничиваясь, по телефону, электронной почте, почтовой рассылке, в сети Интернет и т.д.;
            </li>
            <li>осуществления прав и законных интересов ТОО «Ренталс-Ала»;</li>
            <li>оказания иных услуг и в иных целях, если действия ТОО «Ренталс-Ала» не противоречат действующему законодательству.</li>
            <p>
              ТОО «Ренталс-Ала» обязуется не разглашать полученную от Пользователя информацию. При этом не считается нарушением обязательств разглашение информации в случае, когда обязанность такого
              раскрытия установлена требованиями действующего законодательства РФ. Пользователь вправе отозвать своё согласие на использование своих персональных данных путем написания и передачи
              заявления на бумажном носителе представителю ТОО «Ренталс-Ала» в главном офисе компании, находящемся по адресу Наурызбай Батыра 111. В этом случае ТОО «Ренталс-Ала» автоматически
              прекращает исполнение своих обязательств в рамках договора движимого имущества и иных, возникших между Пользователем и ТОО «Ренталс-Ала». Персональные данные Пользователя будут
              использоваться до момента исполнения всех обязательств Пользователя перед ТОО «Ренталс-Ала» в рамках договора движимого имущества и иных, а после будут удалены.
            </p>
          </ul>
          <b>
            ВАЖНО! На данном сайте используется SSL-сертификат, и передача всех данных происходит по протоколу HTTPS. Это уникальная технология, необходимая для организации защищенного соединения
            между клиентом и сервером, что особенно важно при передаче конфиденциальной информации и проведении финансовых операций.
          </b>
        </div>
      )}
      {lang === "en" && (
        <div className={styles.agreementText}>
          <h1>Use of personal data </h1>
          <p>
            To become a job seeker, it is necessary to issue a response to a vacancy on the website. To do this, the User must fill out a registration form by providing an LLP Rentals-Ala your
            personal data. By submitting their personal data when registering on the Site, the User gives Rentals-Ala LLP consent to the use of their personal data. data in various ways for a period
            until the User withdraws his consent, or in case of loss of the need to achieve the purpose of use. Rentals-Ala LLP carries out The use of personal data is as follows: storage,
            systematization, collection, accumulation, clarification, use, depersonalization, transfer, deletion. The user provides the following information: surname, first name, patronymic, contact
            phone number, e-mail address, series and number of the passport of a citizen of the Russian Federation, date and issuing authority of the passport of a citizen of the Russian Federation,
            date date of birth, registration address, actual address of residence, links to personal pages on social networks, a link to a personal website, place of work and/or study, photo, scans
            documents: Russian passport, foreign passport, TIN, SNILS, driver's license, military ID. Personal data is used by Rentals-Ala LLP for the following purposes:
          </p>
          <ul>
            <li>User registration on the Site;</li>
            <li>fulfillment of obligations under the lease agreement of movable property;</li>
            <li>
              promotion of services and/or goods of Rentals-Ala LLP and/or partners of Rentals-Ala LLP in the market by making direct contacts with clients of Rentals-Ala LLP through various means of
              communication, including, but not limited to, by telephone, e-mail, mailing list, on the Internet, etc.;
            </li>
            <li>exercising the rights and legitimate interests of Rentals-Ala LLP;</li>
            <li>provision of other services and for other purposes, if the actions of Rentals-Ala LLP do not contradict the current legislation.</li>
            <p>
              Rentals-Ala LLP undertakes not to disclose the information received from the User. At the same time, disclosure of information is not considered a violation of obligations when the
              obligation of such disclosure is established by the requirements of the current legislation of the Russian Federation. The user has the right to withdraw his consent to the use of his
              personal data by writing and transmitting applications on paper to the representative of Rentals-Ala LLP at the main office of the company located at Nauryzbai Batyr 111. In this case,
              Rentals-Ala LLP automatically terminates the performance of its obligations under the contract of movable property and other arising between the User and Rentals-Ala LLP. The User's
              personal data will be be used until the moment of fulfillment of all the User's obligations to Rentals-Ala LLP under the contract of movable property and others, and after that they will
              be deleted.
            </p>
          </ul>
          <b>
            important! This site uses an SSL certificate, and all data is transmitted over the HTTPS protocol. This is a unique technology necessary for the organization of a secure connection between
            the client and the server, which is especially important when transferring confidential information and conducting financial transactions.
          </b>
        </div>
      )}
      {lang === "kz" && (
        <div className={styles.agreementText}>
          <h1>Жеке деректерді пайдалану</h1>
          <p>
            Жұмысқа орналасуға үміткер болу үшін сайтта бос орынға жауап беру керек. Ол үшін пайдаланушыға ЖШС ұсыну арқылы тіркеу сауалнамасын толтыру қажет "Rentals-Ала" өзінің жеке деректері.
            Сайтта тіркелу кезінде өзінің дербес деректерін ұсына отырып, Пайдаланушы "Ренталс-АТА" ЖШС-не өзінің дербес деректерін пайдалануға келісім береді пайдаланушы өз келісімін қайтарып алғанға
            дейін немесе пайдалану мақсатына жету қажеттілігін жоғалтқан жағдайда әртүрлі тәсілдермен берілген. "Ренталс-АТА" ЖШС жүзеге асырады жеке деректерді келесідей пайдалану: сақтау, жүйелеу,
            жинау, жинақтау, нақтылау, пайдалану, иесіздендіру, беру, жою. Пайдаланушы қамтамасыз етеді келесі ақпарат: Тегі, Аты, Әкесінің аты, байланыс телефон нөмірі, электрондық пошта мекенжайы,
            Ресей азаматының паспортының сериясы мен нөмірі, Ресей Федерациясының азаматының паспортын беру күні мен органы, күні туған жері, тіркеу мекен-жайы, нақты тұрғылықты мекен-жайы, әлеуметтік
            желілердегі жеке беттерге сілтемелер, жеке сайтқа сілтеме, жұмыс және/немесе оқу орны, фотосурет, сканерлеу құжаттар: Ресей Федерациясының төлқұжаты, шетелдік төлқұжат, СТН, СНИЛС,
            жүргізуші куәлігі, әскери билет. Дербес деректерді "Ренталс-АТА" ЖШС мақсатта пайдаланады:
          </p>
          <ul>
            <li>пайдаланушыны Сайтта тіркеу;</li>
            <li>жылжымалы мүлікті жалдау шарты шеңберінде міндеттемелерді орындау;</li>
            <li>
              "Ренталс-Ала" ЖШС және/немесе "Ренталс-Ала" ЖШС серіктестерінің қызметтерін және/немесе тауарларын нарықта "Ренталс-Ала" ЖШС клиенттерімен тікелей байланысты жүзеге асыру арқылы әр түрлі
              көмегімен ілгерілету байланыс құралдары, оның ішінде, бірақ телефон, электрондық пошта, пошта арқылы, Интернет желісінде және т. б.;
            </li>
            <li>"Ренталс-Ала" ЖШС құқықтары мен заңды мүдделерін жүзеге асыру;</li>
            <li>егер "Ренталс-АТА" ЖШС-нің іс-әрекеті қолданыстағы заңнамаға қайшы келмесе, өзге де және өзге де мақсаттарда қызмет көрсету.</li>
            <p>
              "Ренталс-АТА" ЖШС пайдаланушыдан алынған ақпаратты жария етпеуге міндеттенеді. Бұл ретте міндеті осындай болған жағдайда ақпаратты жария ету міндеттемелерді бұзу болып саналмайды ашу
              Ресей Федерациясының қолданыстағы заңнамасының талаптарымен белгіленген. Пайдаланушы өзінің дербес деректерін жазуға және беруге келісімін қайтарып алуға құқылы Наурызбай батыр 111
              мекенжайындағы компанияның бас кеңсесіндегі "Ренталс-АТА" ЖШС өкіліне қағаз жеткізгіштегі өтініштер. Бұл жағдайда" Ренталс-АТА " ЖШС автоматты түрде жылжымалы мүлік шарты шеңберінде және
              пайдаланушы мен "Ренталс-АТА" ЖШС арасында туындаған өзге де өз міндеттемелерін орындауды тоқтатады. Пайдаланушының жеке деректері жылжымалы мүлік шарты шеңберінде "Ренталс-АТА" ЖШС
              алдындағы пайдаланушының барлық міндеттемелері орындалған сәтке дейін және басқалары пайдаланылады, ал кейін жойылады.
            </p>
          </ul>
          <b>
            Маңызды! Бұл сайтта SSL сертификаты қолданылады және барлық деректерді беру HTTPS арқылы жүзеге асырылады. Бұл қорғалған қосылымды ұйымдастыру үшін қажет бірегей технология клиент пен
            сервер арасында, бұл әсіресе құпия ақпаратты беру және қаржылық операцияларды жүргізу кезінде маңызды.
          </b>
        </div>
      )}

      <div className={styles.buttonsContainer}>
        <button className={styles.hollowButton} value={"Назад"} onClick={() => handleCloseModal()}>
          {Title.back[lang]}
        </button>
        <button
          className={styles.blueButton}
          value={"Принять"}
          onClick={() => {
            handleAccept();
            handleCloseModal();
          }}
        >
          {Title.accept[lang]}
        </button>
      </div>
    </div>
  );
}
