import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactSelect from "react-select";
import styles from "./SelectInput.scss";

export interface SelectInputProps {
  className?: string
  options: {
    value: string;
    label: string;
  }[];
  placeholder: string;
  errorBorder?: boolean;
  error?: string | false;
  changeHandler: (e: any) => void;
  disabled: boolean;
  value?: {
    value: string;
    label: string;
  };
  required?: boolean;
  name: string
  invalidFields: (string | false)[]
}

function SelectInput({ className, options, error, placeholder, errorBorder, changeHandler, disabled, value, required, name, invalidFields}: SelectInputProps) {
  const ref = useRef<HTMLInputElement>(null)
  useEffect(()=> {
    invalidFields[0] === name && ref?.current?.scrollIntoView()
  }, [invalidFields])
  console.log(name)
  return (
    <>
    <div ref={ref} className={`react_select_box ${className && className}`}>
      <label>{placeholder}{required && <span className={'required'}>*</span>}</label>
      { <ReactSelect
        name={name}
        className={`react_select ${errorBorder && "errorBorder"} ${disabled && "disabled"}`}
        classNamePrefix="react_select"
        options={options}
        onChange={(e) => {
          changeHandler(e)
        }}
        placeholder={''}
        isSearchable={false}
        styles={styles}
        isDisabled={disabled}
        value={(value?.value??'').length > 0 && value}
        openMenuOnFocus={true}
      /> }
      {error && <span className="red_color_text">{error && error.toString()}</span>}
    </div>
    </>
  );
}

export default SelectInput;
