import React, { useState, useEffect, useContext } from "react";
import styles from "./Main.module.scss";
import { getVacancies } from "../../service/service";
import { Vacancy } from "../../types/vacancy";
import { Title } from "../../content/title";
import useDebounce from "../../utils/use-debounce";
import { Vacancies } from "../../content/vacancies";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import noVacanciesImage from "../../static/noVacancies.png";
import cn from "classnames";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { LangContext } from "../../store/LangContext";
import loaderIcon from "../../static/loader.svg";
import gs from "../../Layout.module.scss";

function Main() {
    const navigate = useNavigate();
    const lang = useContext(LangContext)
    const debounceLang = useDebounce(lang, 100);
    const formatMoney = (amount: string) => {
        if (amount.split(".")[1]?.length < 2) {
            return amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "0";
        }
        return amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    const [vacanciesJson, setVacanciesJson] = useState([]);
    const [startList, setStartList] = useState(false)
    useEffect(() => {
        if (lang)
            getVacancies(debounceLang).then((response) => {
                setVacanciesJson(response);
                setStartList(true);
            });
    }, [debounceLang]);
    const schedulesEnum = [
        {
          value: 1,
          label: "2/2",
        },
        {
          value: 2,
          label: "5/2",
        },
        {
          value: 3,
          label: Vacancies.partTime[lang],
        },
        {
          value: 4,
          label: Vacancies.flexible[lang],
        },
        {
          value: 5,
          label: Vacancies.shiftwork[lang],
        },
        {
          value: 6,
          label: Vacancies.remotely[lang],
        },
        {
          value: 7,
          label: Vacancies.shiftMethod[lang],
        },
      ];
    return (
        <div className={styles.app}>
            { !startList ? 
                <div className={styles.loaderBox}>
                    <img width={40} src={loaderIcon} className={gs.loader}/> 
                </div>
            :
                <div className={styles.vacancies_container}>
                    {vacanciesJson.length > 0 ? (
                        <Accordion className={styles.vacancies_box}>
                            {vacanciesJson.map((v: Vacancy, i: number) => {
                                const prerequisites = [v.showCar && "hasACar", v.showPortfolio && "portfolio", v.showResume && "resume"].filter((e) => e);
                                const prerequisitesTranslate = prerequisites.map((e) => {
                                    return Vacancies[e as "hasACar" | "portfolio" | "resume"][lang].toLowerCase();
                                });
                                return (
                                    <Accordion.Item eventKey={i.toString()} key={i} className={styles.collapsePanel}>
                                        <Accordion.Header className={styles.collapsePanel_header}>
                                            <div className={styles.collapsePanel_item}>
                                                <span className={styles.collapsePanel_item__grey}>{Vacancies.vacancy[lang]}:</span>
                                                <span className={styles.collapsePanel_item__black}>{v.vacancyName}</span>
                                            </div>
                                            <div className={styles.collapsePanel_item}>
                                                <span className={styles.collapsePanel_item__grey}>{Vacancies.salary[lang]}:</span>
                                                <span className={styles.collapsePanel_item__black}>
                                                    {v?.salaryType === 0
                                                        ? Vacancies.from[lang] + " " + formatMoney(v?.salary?.toString() ?? "")
                                                        : v?.salaryType === 1
                                                            ? Vacancies.to[lang] + " " + formatMoney(v?.salary?.toString() ?? "")
                                                            : v?.salaryType === 2
                                                                ? formatMoney(v?.salary?.toString() ?? "")
                                                                : null}{" "}
                                                    {v.currencySign}
                                                </span>
                                            </div>
                                            <div className={cn(styles.collapsePanel_item, styles.second_hidden_mobile)}>
                                                <span className={styles.collapsePanel_item__grey}>{Vacancies.experience[lang]}:</span>
                                                <span className={styles.collapsePanel_item__black}>{v.experience}</span>
                                            </div>
                                            <div className={cn(styles.collapsePanel_item, styles.first_hidden_mobile)}>
                                                <span className={styles.collapsePanel_item__grey}>{Vacancies.schedule[lang]}:</span>
                                                <span className={styles.collapsePanel_item__black}>{schedulesEnum.find((e) => e.value === +v.schedule)?.label}</span>
                                            </div>
                                            <div className={cn(styles.collapsePanel_item, styles.first_hidden_mobile)}>
                                                <span className={styles.collapsePanel_item__grey}>{Vacancies.affiliate[lang]}:</span>
                                                <span className={styles.collapsePanel_item__black}>{Vacancies?.[v.affiliate][lang]}</span>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body className={styles.collapsePanel_section}>
                                            {prerequisitesTranslate.length > 0 || v?.driverLicenses.filter((item:any)=> item.length > 0)?.length > 0 || v?.languages.filter((item:any)=> item.length > 0)?.length > 0 ? (
                                                <p>
                                                    <b>{Vacancies.prerequisites[lang]}:</b> {prerequisitesTranslate.join(", ")}
                                                    {prerequisitesTranslate.length > 0 && (v?.driverLicenses.filter((item:any)=> item.length > 0)?.length > 0 || v?.languages.filter((item:any)=> item.length > 0)?.length > 0) ? ", " : null}
                                                    {v?.driverLicenses.filter((item:any)=> item.length > 0)?.length > 0 ? Vacancies.haveLicense[lang] + ": " + v.driverLicenses.join(", ") : null}
                                                    {v?.driverLicenses.filter((item:any)=> item.length > 0)?.length > 0 && v?.languages.filter((item:any)=> item.length > 0)?.length > 0 ? ", " : null}
                                                    {v?.languages.filter((item:any)=> item.length > 0)?.length > 0 ? Vacancies.knowledgeLang[lang] + ": " + v.languages.map((l) => Vacancies[l as "RUS" | "ENG" | "KZ"]?.[lang]?.toLowerCase())?.join(", ") : null}
                                                </p>
                                            ) : null}
                                            <ul>
                                                <li>
                                                    <b>{Vacancies.description[lang]}:</b>
                                                    <pre>{v.responsibilities}</pre>
                                                </li>
                                                <li>
                                                    <b>{Vacancies.requirements[lang]}:</b>
                                                    <pre>{v.requirements}</pre>
                                                </li>
                                                <li>
                                                    <b>{Vacancies.additionally[lang]}:</b>
                                                    <pre>{v.additionally}</pre>
                                                </li>
                                                <li>
                                                    <b>{Vacancies.conditions[lang]}:</b>
                                                    <pre>{v.workingConditions}</pre>
                                                </li>
                                            </ul>
                                            <div className={styles.collapsePanel_section_btn_box}>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        navigate({
                                                            pathname: `vacancie`,
                                                            search: createSearchParams({
                                                                lang: lang,
                                                                vacancyId: JSON.stringify(v?.id),
                                                                showCar: JSON.stringify(v?.showCar),
                                                                showPortfolio: JSON.stringify(v?.showPortfolio),
                                                                showResume: JSON.stringify(v?.showResume),
                                                                positionCandidate: JSON.stringify(v.vacancyName),
                                                                knowledgeLang: JSON.stringify(v.languages.filter((item:any)=> item.length > 0)),
                                                                driverLicenses: JSON.stringify(v.driverLicenses.filter((item:any)=> item.length > 0)),
                                                                schedule: JSON.stringify(v.schedule)
                                                            }).toString()
                                                        })
                                                    }}
                                                >
                                                    {Vacancies.respond[lang]}
                                                </Button>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <div className={styles.noVacanciesMessageBlock}>
                            <img src={noVacanciesImage} alt="noVacanciesImage" />
                            <div className={styles.noVacanciesMessage}>
                                <b>{Title.noVacanciesMessageHeader[lang]}</b>
                                <p>{Title.noVacanciesMessage[lang]}</p>
                            </div>
                        </div>
                    )
                    }
                </div>
            }
        </div>
    );
}

export default Main;
