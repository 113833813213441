import React from "react";
import LabeledInput from "./inputs/LabeledInput";
import SelectInput from "./inputs/SelectInput";
import { Fields } from "../content/fields";
import { Title } from "../content/title";
import { Lang } from "src/types/common-types";
import gs from "../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../store/store";
import { validateUrl } from "../validate/validator";
import { Errors } from "../content/errors";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
}

function SocialLinks({ lang, state, invalidFields, dispatch, disabled }: Props) {
  const SocialLinksOptions = [
    { value: Fields.vk.value, label: Fields.vk?.label[lang] },
    { value: Fields.fb.value, label: Fields.fb?.label[lang] },
    { value: Fields.youtube.value, label: Fields.youtube?.label[lang] },
    { value: Fields.vimeo.value, label: Fields.vimeo?.label[lang] },
    { value: Fields.instagram.value, label: Fields.instagram?.label[lang] },
    { value: Fields.worksite.value, label: Fields.worksite?.label[lang] },
    { value: Fields.another.value, label: Fields.another?.label[lang] },
    { value: Fields.ok.value, label: Fields.ok?.label[lang] },
    { value: Fields.twitter.value, label: Fields.twitter?.label[lang] },
  ];
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <span className={gs.header}>{Title.socialLinksTitle[lang]}</span>
      </div>
      <div className={gs.col6}>
        <SelectInput
          name={'socialLinkType'}
          disabled={disabled}
          options={SocialLinksOptions}
          changeHandler={(e) => {
            dispatch({
              type: actions.SOCIAL_LINKS,
              data: [
                {
                  type: e.value,
                  description: e.label,
                  link: state.socialLinks[0]?.link ?? "",
                },
              ],
            });
          }}
          value={
            state.socialLinks.map((e) => {
              return { value: e.type, label: e.description };
            })[0]
          }
          required={true}
          placeholder={Fields.socialLinkType?.label[lang]}
          invalidFields={invalidFields}
          errorBorder={invalidFields.includes("socialLinkType")}
          error={invalidFields.includes("socialLinkType") && Errors.socialLinkType[lang]}
        ></SelectInput>
      </div>
      <div className={gs.col6}>
        <LabeledInput
          name={"socialLink"}
          disabled={disabled}
          label={Fields.socialLink?.label[lang]}
          onChange={(e) => {
            dispatch({
              type: actions.SOCIAL_LINKS,
              data: state.socialLinks[0]
                ? [
                    {
                      type: state.socialLinks[0]?.type ?? "",
                      description: state.socialLinks[0]?.description ?? "",
                      link: e.target.value ?? "",
                    },
                  ]
                : [
                    {
                      type: "",
                      description: "",
                      link: e.target.value,
                    },
                  ],
            });
          }}
          value={state?.socialLinks[0]?.link}
          required={true}
          errorBorder={invalidFields.includes("socialLink") || (state.socialLinks.length > 0 && !validateUrl(state.socialLinks[0]?.link, 200, true))}
          error={(invalidFields.includes("socialLink") || (state.socialLinks.length > 0 && !validateUrl(state.socialLinks[0]?.link, 200, true))) && Errors.socialLink[lang]}
        />
      </div>
    </div>
  );
}

export default SocialLinks;
