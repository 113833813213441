import React from "react";
import RadioButton from "../inputs/RadioButton";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import gs from "../../Layout.module.scss";
import style from "./Sex.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
}

function Sex({ lang, state, invalidFields, dispatch, disabled }: Props) {
  return (
    <div className={style.sexButtonBox}>
      <span className={gs.header}>{Title.sexTitle[lang]}</span>
      <RadioButton
        disabled={disabled}
        label={Fields.male?.label[lang]}
        onClick={() => {
          dispatch({
            type: actions.SEX,
            data: 2,
          });
        }}
        active={state.sex === 2}
      />
      <RadioButton
        disabled={disabled}
        label={Fields.female?.label[lang]}
        onClick={() => {
          dispatch({
            type: actions.SEX,
            data: 1,
          });
        }}
        active={state.sex === 1}
      />
    </div>
  );
}

export default Sex;
