import React, { useContext } from "react";
import style from './SuccessPage.module.scss'
import SumbitMessage from "../../components/SubmitMessage/SubmitMessage";
import Button from "react-bootstrap/Button";
import { createSearchParams, useNavigate } from "react-router-dom";
import { LangContext } from "../../store/LangContext";
import { Vacancies } from "../../content/vacancies";

export default function SuccessPage() {
    const navigate = useNavigate();
    const lang = useContext(LangContext)

    return <>
        <SumbitMessage status={"success"} lang={lang} />
        <div className={style.btn_box}>
            <Button variant="primary" onClick={() => navigate({
                pathname: '/',
                search: createSearchParams({
                    lang: lang
                }).toString()
            })}>
                {Vacancies.close[lang]}
            </Button>
        </div>
    </>
}