import React, { useEffect, useState } from "react";
import styles from './Messengers.module.scss'
import SelectInput from "../inputs/SelectInput";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import gs from "../../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";
import AddButton from "../AddButton/AddButton";
import MessengersItem from "../MessengersItem/MessengersItem";

interface Props {
    lang: Lang;
    state: Applicant;
    invalidFields: (string | false)[];
    dispatch: Dispatch;
    disabled: boolean;
}

function Messengers({ lang, state, invalidFields, dispatch, disabled }: Props) {
    const CommunicationTypeOptions = [
        { value: Fields.voiceAndText.value, label: Fields.voiceAndText?.label[lang] },
        { value: Fields.voice.value, label: Fields.voice?.label[lang] },
        { value: Fields.text.value, label: Fields.text?.label[lang] },
    ];
    const CommunicationTimeOptions = [
        { value: Fields.morning.value, label: Fields.morning?.label[lang] },
        { value: Fields.day.value, label: Fields.day?.label[lang] },
        { value: Fields.evening.value, label: Fields.evening?.label[lang] },
        { value: Fields.night.value, label: Fields.night?.label[lang] }
    ];
    const [messengersCount, setMessengersCount] = useState(1)
    const messengers = () => {
        let list = []
        for (let i = 0; i < messengersCount; i++) {
            list.push(
                <MessengersItem 
                    key={`messenger-${i}`}
                    lang={lang} 
                    state={state} 
                    invalidFields={invalidFields} 
                    dispatch={dispatch} 
                    disabled={
                        disabled ||
                        (state.telegramCheck && state.messengers[i]?.type === Fields.telegram.value ||
                        state.whatsappCheck && state.messengers[i]?.type === Fields.whatsapp.value ||
                        state.viberCheck && state.messengers[i]?.type === Fields.viber.value ||
                        state.skypeCheck && state.messengers[i]?.type === Fields.skype.value)
                    } 
                    index={i} />
            )
        }
        return list
    }
    const [messengersList, setMessengersList] = useState(messengers)
    useEffect(() => {
        setMessengersList(messengers)
    }, [
        messengersCount,
        invalidFields,
        disabled
    ])
    useEffect(()=> {
        state.messengers.length > 0 && setMessengersCount(state.messengers.length)
    }, [state.messengers])
    return (
        <>
            <div className={gs.row}>
                <div className={gs.col12}>
                    <span className={gs.header}>{Title.messengersTitle[lang]}<span className={gs.red}>*</span></span>
                </div>
                <div className={gs.col12}>
                    <span className={styles.description}>{Title.contactsMessengersDescription[lang]}</span>
                </div>
                { messengersList}
                <div className={gs.col6}>
                    {messengersCount < 4 && <AddButton lang={lang} onClick={()=> {
                        setMessengersCount(messengersCount+1)
                    }}/>}
                </div>
            </div>

            <div className={gs.row}>
                <div className={gs.col6}>
                    <SelectInput
                        name={'preferredCommunicationType'}
                        disabled={disabled}
                        options={CommunicationTypeOptions}
                        changeHandler={(e) => {
                            dispatch({
                                type: actions.COMMUNICATION_TYPE,
                                data: e.value
                            });
                            +e.value === 2 && dispatch({
                                type: actions.COMMUNICATION_TIME,
                                data: 0
                            });
                        }}
                        value={
                            CommunicationTypeOptions.find((e) => {
                                return e.value === state.preferredCommunicationType
                            })
                        }
                        placeholder={Fields.preferredCommunicationType?.label[lang]}
                        invalidFields={invalidFields}
                    // errorBorder={invalidFields.includes("preferredCommunicationType")}
                    // error={invalidFields.includes("preferredCommunicationType") && Errors.preferredCommunicationType[lang]}
                    ></SelectInput>
                </div>
                { +(state?.preferredCommunicationType??0) !== 2 && <div className={gs.col6}>
                    <SelectInput
                        name={'preferredCommunicationTime'}
                        disabled={disabled}
                        options={CommunicationTimeOptions}
                        changeHandler={(e) => {
                            dispatch({
                                type: actions.COMMUNICATION_TIME,
                                data: e.value
                            });
                        }}
                        value={
                            CommunicationTimeOptions.find((e) => {
                                return e.value === state.preferredCommunicationTime
                            })
                        }
                        placeholder={Fields.preferredCommunicationTime?.label[lang]}
                        invalidFields={invalidFields}
                    // errorBorder={invalidFields.includes("preferredCommunicationTime")}
                    // error={invalidFields.includes("preferredCommunicationTime") && Errors.preferredCommunicationTime[lang]}
                    ></SelectInput>
                </div>}
            </div>
        </>
    );
}

export default Messengers;
