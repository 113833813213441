import { RegionCity } from '../../types/common-types'
const RegionCityListEn: RegionCity[] = [
  {
    region: 'Moscow and Moscow region',
    city: 'Moscow',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Abramtsevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Alabino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Aprelevka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Arkhangelskoye',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Ashitkovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Baikonur',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Baksheevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Balashikha',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Barybino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Beloomut',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'White Pillars',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Borodino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Bronnitsy',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Bykovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Valuevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Verbilki',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Vereya',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Visible',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Vnukovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Leader of the Proletariat',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Volokolamsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Voronovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Voskresensk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Vostochny',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Vostryakovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Vysokovsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Golitsyno',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dedenevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dedovsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dzherzhinsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dmitrov',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dolgoprudny',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Domodedovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dorokhovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Drezna',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Oaks',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Dubna',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Yegoryevsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Railway',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zhilevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zhukovsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zagorsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zagoryansky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zaprudnaya',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zaraysk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zvenigorod',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Zelenograd',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Ivanteevka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Iksha',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Ilyinsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Istra',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kaliningrad',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kashira',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kerva',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Klimovsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Wedge',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Klyazma',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kozhino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kokoshkino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kolomna',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kolyubakino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Korolev',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kosino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kotelniki',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kraskovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Krasnoarmeysk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Krasnogorsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Krasnozavodsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Krasnoznamensk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Red Weaver',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kryukovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kubinka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kupavna',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Kurovskoye',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Forest town',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Likino-Dulevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lobnya',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lopatinsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Losino-Petrovsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lotoshino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lukino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lukhovitsy',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lytkarino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lviv',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lyubertsy',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Malakhovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Mikhailovskoye',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Mikhnevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Mozhaisk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Monino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Mukhanovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Mytishchi',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Narofominsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Nakhabino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Nekrasovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Nemchinovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Novobrattsevskiy',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Novopodrezkovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Noginsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Obukhovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Odintsovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Necklace',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Lakes',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Oktyabrsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Opalikha',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Orekhovo-Zuyevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Pavlovsky Posad',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Pervomaisky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Sands',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Pirogovsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Podolsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Polushkino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Pravdinsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Railway Station',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Proletarian',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Protvino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Pushkino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Pushchino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Radovitsky',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Ramenskoye',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Reutov',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Reshetnikovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Springs',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Roshal',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Rublevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Ruza',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Saltykovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Northern',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Sergiev Posad',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Silver Ponds',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Serpukhov',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Solnechnogorsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Solntsevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Sofrino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Staraya Kupavna',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Starbeevo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Stupino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Skhodnya',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Taldom',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Tekstilshchik',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Tempo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Tishkovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Tomilino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Troitsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Tugolessky Bor',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Tuchkovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Uvarovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Specific',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Uspenskoe',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Firsanovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Phosphorite',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Fryazino',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Fryanovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Khimki',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Khorlovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Khotkovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Cherkizovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Chernogolovka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Cherusti',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Chekhov',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Sharapovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Shatura',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Shaturtorf',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Shakhovskaya',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Sheremetyevskiy',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Shchelkovo',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Shcherbinka',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Elektrogorsk',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Elektrostal',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Elektrougli',
  },
  {
    region: 'Moscow and Moscow region',
    city: 'Yakhroma',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Saint-Petersburg',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Alexandrovskaya',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Boksitogorsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Bolshaya Izhora',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Budogoshch',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Ascension',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Volosovo',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Volkhov',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Vsevolozhsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Vyborg',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Vyritsa',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Vysotsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Gatchina',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Druzhnaya Gorka',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Dubrovka',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Efimovsky',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Zelenogorsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Ivangorod',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kamennogorsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kikerino',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kingisepp',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kirishi',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kirovsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kobrinskoye',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kolpino',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kommunar',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Kronstadt',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Fox Nose',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Lodeynoye Pole',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Lomonosov',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Meadows',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Pavlovsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Pargolovo',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Petrodvorets',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Pikalevo',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Podporozhye',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Priozersk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Pushkin',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Sestroretsk',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Slates',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Sosnovy Bor',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Tikhvin',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Tosno',
  },
  {
    region: 'St. Petersburg and the region',
    city: 'Shlisselburg',
  },
  {
    region: 'Adygea',
    city: 'Adygeysk',
  },
  {
    region: 'Adygea',
    city: 'Maikop',
  },
  {
    region: 'Altai Territory',
    city: 'Aktash',
  },
  {
    region: 'Altai Territory',
    city: 'Akuchiha',
  },
  {
    region: 'Altai Territory',
    city: 'Aleysk',
  },
  {
    region: 'Altai Territory',
    city: 'Altai',
  },
  {
    region: 'Altai Territory',
    city: 'Baevo',
  },
  {
    region: 'Altai Territory',
    city: 'Barnaul',
  },
  {
    region: 'Altai Territory',
    city: 'Belovo',
  },
  {
    region: 'Altai Territory',
    city: 'Belokurikha',
  },
  {
    region: 'Altai Territory',
    city: 'Beloyarsk',
  },
  {
    region: 'Altai Territory',
    city: 'Biysk',
  },
  {
    region: 'Altai Territory',
    city: 'Blagoveshchensk',
  },
  {
    region: 'Altai Territory',
    city: 'Borovlyanka',
  },
  {
    region: 'Altai Territory',
    city: 'Burla',
  },
  {
    region: 'Altai Territory',
    city: 'Bursol',
  },
  {
    region: 'Altai Territory',
    city: 'Wolf',
  },
  {
    region: 'Altai Territory',
    city: 'Gorno-Altaisk',
  },
  {
    region: 'Altai Territory',
    city: 'Miner',
  },
  {
    region: 'Altai Territory',
    city: 'Eltsovka',
  },
  {
    region: 'Altai Territory',
    city: 'Zalesovo',
  },
  {
    region: 'Altai Territory',
    city: 'Zarinsk',
  },
  {
    region: 'Altai Territory',
    city: 'Cut',
  },
  {
    region: 'Altai Territory',
    city: 'Zmeinogorsk',
  },
  {
    region: 'Altai Territory',
    city: 'Stone-on-the-Ob',
  },
  {
    region: 'Altai Territory',
    city: 'Keys',
  },
  {
    region: 'Altai Territory',
    city: 'Kosh-Agach',
  },
  {
    region: 'Altai Territory',
    city: 'Krasnogorskoye',
  },
  {
    region: 'Altai Territory',
    city: 'Krasnoshchekovo',
  },
  {
    region: 'Altai Territory',
    city: 'Kulunda',
  },
  {
    region: 'Altai Territory',
    city: 'Kytmanovo',
  },
  {
    region: 'Altai Territory',
    city: 'Mamontovo',
  },
  {
    region: 'Altai Territory',
    city: 'Novichiha',
  },
  {
    region: 'Altai Territory',
    city: 'Novoaltaisk',
  },
  {
    region: 'Altai Territory',
    city: 'Ongudai',
  },
  {
    region: 'Altai Territory',
    city: 'Pavlovsk',
  },
  {
    region: 'Altai Territory',
    city: 'Petropavlovskoye',
  },
  {
    region: 'Altai Territory',
    city: 'Pospeliha',
  },
  {
    region: 'Altai Territory',
    city: 'Rebriha',
  },
  {
    region: 'Altai Territory',
    city: 'Rodino',
  },
  {
    region: 'Altai Territory',
    city: 'Rubtsovsk',
  },
  {
    region: 'Altai Territory',
    city: 'Slavgorod',
  },
  {
    region: 'Altai Territory',
    city: 'Smolenskoye',
  },
  {
    region: 'Altai Territory',
    city: 'Soloneshnoye',
  },
  {
    region: 'Altai Territory',
    city: 'Salton',
  },
  {
    region: 'Altai Territory',
    city: 'Staroalleyskoe',
  },
  {
    region: 'Altai Territory',
    city: 'Herds',
  },
  {
    region: 'Altai Territory',
    city: 'Talmenka',
  },
  {
    region: 'Altai Territory',
    city: 'Topchikha',
  },
  {
    region: 'Altai Territory',
    city: 'Troitskoye',
  },
  {
    region: 'Altai Territory',
    city: 'Turochak',
  },
  {
    region: 'Altai Territory',
    city: 'Tyumentsevo',
  },
  {
    region: 'Altai Territory',
    city: 'Uglovskoye',
  },
  {
    region: 'Altai Territory',
    city: 'Ust-Kalmanka',
  },
  {
    region: 'Altai Territory',
    city: 'Ust-Kan',
  },
  {
    region: 'Altai Territory',
    city: 'Ust-Koksa',
  },
  {
    region: 'Altai Territory',
    city: 'Ust-Ulagan',
  },
  {
    region: 'Altai Territory',
    city: 'Ust-Charyshskaya Pristan',
  },
  {
    region: 'Altai Territory',
    city: 'Khabary',
  },
  {
    region: 'Altai Territory',
    city: 'Tselinnoe',
  },
  {
    region: 'Altai Territory',
    city: 'Charyshskoye',
  },
  {
    region: 'Altai Territory',
    city: 'Shebalino',
  },
  {
    region: 'Altai Territory',
    city: 'Shelabolikha',
  },
  {
    region: 'Altai Territory',
    city: 'Shipunovo',
  },
  {
    region: 'Amur region',
    city: 'Aigun',
  },
  {
    region: 'Amur region',
    city: 'Argali',
  },
  {
    region: 'Amur region',
    city: 'Belogorsk',
  },
  {
    region: 'Amur region',
    city: 'Blagoveshchensk (Amur region)',
  },
  {
    region: 'Amur region',
    city: 'Bureya',
  },
  {
    region: 'Amur region',
    city: 'Vozzhaevka',
  },
  {
    region: 'Amur region',
    city: 'Yekaterinoslavka',
  },
  {
    region: 'Amur region',
    city: 'Erofei Pavlovich',
  },
  {
    region: 'Amur region',
    city: 'Zavitinsk',
  },
  {
    region: 'Amur region',
    city: 'Zeya',
  },
  {
    region: 'Amur region',
    city: 'Zlatoustovsk',
  },
  {
    region: 'Amur region',
    city: 'Ivanovka',
  },
  {
    region: 'Amur region',
    city: 'Koboldo',
  },
  {
    region: 'Amur region',
    city: 'Magdagachi',
  },
  {
    region: 'Amur region',
    city: 'Novobureysky',
  },
  {
    region: 'Amur region',
    city: 'Poyarkovo',
  },
  {
    region: 'Amur region',
    city: 'Raichikhinsk',
  },
  {
    region: 'Amur region',
    city: 'Romny',
  },
  {
    region: 'Amur region',
    city: 'Free',
  },
  {
    region: 'Amur region',
    city: 'Seryshevo',
  },
  {
    region: 'Amur region',
    city: 'Skovorodino',
  },
  {
    region: 'Amur region',
    city: 'Stoyba',
  },
  {
    region: 'Amur region',
    city: 'Tambovka',
  },
  {
    region: 'Amur region',
    city: 'Tynda',
  },
  {
    region: 'Amur region',
    city: 'Shimanovsk',
  },
  {
    region: 'Amur region',
    city: 'Ekimchan',
  },
  {
    region: 'Amur region',
    city: 'Yadrino',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Amderma',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Arkhangelsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Bereznik',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Velsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Verkhnyaya Toyma',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Voloshka',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Vychegodsky',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Yemtsa',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Ileza',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Ilyinsko-Podomskoye',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Kargopol',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Karpogory',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Codino',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Konosha',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Driftwood',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Kotlas',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Krasnoborsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Leshukonskoye',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Mezen',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Peaceful',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Naryan-Mar',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Novodvinsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Nyandoma',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Onega',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Pinega',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Plesetsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Severodvinsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Solvychegodsk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Kholmogory',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Shenkursk',
  },
  {
    region: 'Arkhangelsk region',
    city: 'Yarensk',
  },
  {
    region: 'Astrakhan region',
    city: 'Astrakhan',
  },
  {
    region: 'Astrakhan region',
    city: 'Akhtubinsk',
  },
  {
    region: 'Astrakhan region',
    city: 'Verkhniy Baskunchak',
  },
  {
    region: 'Astrakhan region',
    city: 'Volodarsky',
  },
  {
    region: 'Astrakhan region',
    city: 'Enotaevka',
  },
  {
    region: 'Astrakhan region',
    city: 'Eggs',
  },
  {
    region: 'Astrakhan region',
    city: 'Kamyzyak',
  },
  {
    region: 'Astrakhan region',
    city: 'Kapustin Yar',
  },
  {
    region: 'Astrakhan region',
    city: 'Krasny Yar',
  },
  {
    region: 'Astrakhan region',
    city: 'Liman',
  },
  {
    region: 'Astrakhan region',
    city: 'Nachalovo',
  },
  {
    region: 'Astrakhan region',
    city: 'Kharabali',
  },
  {
    region: 'Astrakhan region',
    city: 'Chorny Yar',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Aksakovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Amzya',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Askino',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Baymak',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Groceries',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Belebey',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Beloretsk',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Bizhbulyak',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Birsk',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Blagoveshchensk',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Bolsheustikinskoe',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Buraevo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Verkhneyarkeevo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Upper Kigi',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Verkhniye Tatyshly',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Upper Avzyan',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Davlekanovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Duvan',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Dyurtyuli',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Ermekeevo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Ermolaevo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Zilair',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Zirgan',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Iglino',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Inzer',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Isyangulovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Ishimbay',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Kananikolskoye',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Kandry',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Karaidel',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Karaidelsky',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Kirghiz-Miyaki',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Krasnousolsky',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Kumertau',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Kushnarenkovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Maloyaz',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Meleuz',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Mesyagutovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Mrakovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Neftekamsk',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Oktyabrsky',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Raevsky',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Salavat',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Sibay',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Starobaltachevo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Starosubkhangulovo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Sterlibashevo',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Sterlitamak',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Tuymazy',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Ufa',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Uchaly',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Fedorovka',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Chekmagush',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Chishmy',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Sharan',
  },
  {
    region: 'Bashkortostan (Bashkiria)',
    city: 'Yanaul',
  },
  {
    region: 'Belgorod region',
    city: 'Alekseevka',
  },
  {
    region: 'Belgorod region',
    city: 'Belgorod',
  },
  {
    region: 'Belgorod region',
    city: 'Borisovka',
  },
  {
    region: 'Belgorod region',
    city: 'Valuyki',
  },
  {
    region: 'Belgorod region',
    city: 'Veydelevka',
  },
  {
    region: 'Belgorod region',
    city: 'Volokonovka',
  },
  {
    region: 'Belgorod region',
    city: 'Grayvoron',
  },
  {
    region: 'Belgorod region',
    city: 'Gubkin',
  },
  {
    region: 'Belgorod region',
    city: 'Willow',
  },
  {
    region: 'Belgorod region',
    city: 'Short',
  },
  {
    region: 'Belgorod region',
    city: 'Krasnogvardeyskoye',
  },
  {
    region: 'Belgorod region',
    city: 'Novy Oskol',
  },
  {
    region: 'Belgorod region',
    city: 'Rakitnoe',
  },
  {
    region: 'Belgorod region',
    city: 'Rovenki',
  },
  {
    region: 'Belgorod region',
    city: 'Stary Oskol',
  },
  {
    region: 'Belgorod region',
    city: 'Builder',
  },
  {
    region: 'Belgorod region',
    city: 'Chernyanka',
  },
  {
    region: 'Belgorod region',
    city: 'Shebekino',
  },
  {
    region: 'Bryansk region',
    city: 'Altukhovo',
  },
  {
    region: 'Bryansk region',
    city: 'White Birch',
  },
  {
    region: 'Bryansk region',
    city: 'White Shores',
  },
  {
    region: 'Bryansk region',
    city: 'Big Polpino',
  },
  {
    region: 'Bryansk region',
    city: 'Bryansk',
  },
  {
    region: 'Bryansk region',
    city: 'Bytosh',
  },
  {
    region: 'Bryansk region',
    city: 'Vygonichi',
  },
  {
    region: 'Bryansk region',
    city: 'Vyshkov',
  },
  {
    region: 'Bryansk region',
    city: 'Gordeevka',
  },
  {
    region: 'Bryansk region',
    city: 'Dubrovka',
  },
  {
    region: 'Bryansk region',
    city: 'Dyatkovo',
  },
  {
    region: 'Bryansk region',
    city: 'Zhiryatino',
  },
  {
    region: 'Bryansk region',
    city: 'Zhukovka',
  },
  {
    region: 'Bryansk region',
    city: 'Zlynka',
  },
  {
    region: 'Bryansk region',
    city: 'Ivot',
  },
  {
    region: 'Bryansk region',
    city: 'Karachev',
  },
  {
    region: 'Bryansk region',
    city: 'Cage',
  },
  {
    region: 'Bryansk region',
    city: 'Klimovo',
  },
  {
    region: 'Bryansk region',
    city: 'Klintsy',
  },
  {
    region: 'Bryansk region',
    city: 'Kokarevka',
  },
  {
    region: 'Bryansk region',
    city: 'Komarichi',
  },
  {
    region: 'Bryansk region',
    city: 'Krasnaya Gora',
  },
  {
    region: 'Bryansk region',
    city: 'Elbow',
  },
  {
    region: 'Bryansk region',
    city: 'Mglin',
  },
  {
    region: 'Bryansk region',
    city: 'Navlya',
  },
  {
    region: 'Bryansk region',
    city: 'Novozybkov',
  },
  {
    region: 'Bryansk region',
    city: 'Pogar',
  },
  {
    region: 'Bryansk region',
    city: 'Pochep',
  },
  {
    region: 'Bryansk region',
    city: 'Rzhanitsa',
  },
  {
    region: 'Bryansk region',
    city: 'Rognedin',
  },
  {
    region: 'Bryansk region',
    city: 'Sevsk',
  },
  {
    region: 'Bryansk region',
    city: 'Starodub',
  },
  {
    region: 'Bryansk region',
    city: 'Suzemka',
  },
  {
    region: 'Bryansk region',
    city: 'Surazh',
  },
  {
    region: 'Bryansk region',
    city: 'Trubchevsk',
  },
  {
    region: 'Bryansk region',
    city: 'Unecha',
  },
  {
    region: 'Buryatia',
    city: 'Babushkin',
  },
  {
    region: 'Buryatia',
    city: 'Bagdarin',
  },
  {
    region: 'Buryatia',
    city: 'Barguzin',
  },
  {
    region: 'Buryatia',
    city: 'Bayangol',
  },
  {
    region: 'Buryatia',
    city: 'Bichura',
  },
  {
    region: 'Buryatia',
    city: 'Vydrino',
  },
  {
    region: 'Buryatia',
    city: 'Goose Lake',
  },
  {
    region: 'Buryatia',
    city: 'Gusinoozersk',
  },
  {
    region: 'Buryatia',
    city: 'Zaigraevo',
  },
  {
    region: 'Buryatia',
    city: 'Zakamensk',
  },
  {
    region: 'Buryatia',
    city: 'Ivolginsk',
  },
  {
    region: 'Buryatia',
    city: 'Ilka',
  },
  {
    region: 'Buryatia',
    city: 'Kabansk',
  },
  {
    region: 'Buryatia',
    city: 'Kamensk',
  },
  {
    region: 'Buryatia',
    city: 'Kizhinga',
  },
  {
    region: 'Buryatia',
    city: 'Kurumkan',
  },
  {
    region: 'Buryatia',
    city: 'Kyren',
  },
  {
    region: 'Buryatia',
    city: 'Kyakhta',
  },
  {
    region: 'Buryatia',
    city: 'Monds',
  },
  {
    region: 'Buryatia',
    city: 'Mukhorshibir',
  },
  {
    region: 'Buryatia',
    city: 'Nizhneangarsk',
  },
  {
    region: 'Buryatia',
    city: 'Orlik',
  },
  {
    region: 'Buryatia',
    city: 'Petropavlovka',
  },
  {
    region: 'Buryatia',
    city: 'Romanovka',
  },
  {
    region: 'Buryatia',
    city: 'Severobaikalsk',
  },
  {
    region: 'Buryatia',
    city: 'Selenginsk',
  },
  {
    region: 'Buryatia',
    city: 'Sosnovo-Ozerskoye',
  },
  {
    region: 'Buryatia',
    city: 'Taksimo',
  },
  {
    region: 'Buryatia',
    city: 'Turuntaevo',
  },
  {
    region: 'Buryatia',
    city: 'Ulan-Ude',
  },
  {
    region: 'Buryatia',
    city: 'Khorinsk',
  },
  {
    region: 'Vladimir region',
    city: 'Alexandrov',
  },
  {
    region: 'Vladimir region',
    city: 'Andreevo',
  },
  {
    region: 'Vladimir region',
    city: 'Anopino',
  },
  {
    region: 'Vladimir region',
    city: 'Bavleny',
  },
  {
    region: 'Vladimir region',
    city: 'Balakirevo',
  },
  {
    region: 'Vladimir region',
    city: 'Bogolyubovo',
  },
  {
    region: 'Vladimir region',
    city: 'Velikodvorsky',
  },
  {
    region: 'Vladimir region',
    city: 'Verbovsky',
  },
  {
    region: 'Vladimir region',
    city: 'Vladimir',
  },
  {
    region: 'Vladimir region',
    city: 'Vyazniki',
  },
  {
    region: 'Vladimir region',
    city: 'Gorodishchi',
  },
  {
    region: 'Vladimir region',
    city: 'Gorokhovets',
  },
  {
    region: 'Vladimir region',
    city: 'Gusevsky',
  },
  {
    region: 'Vladimir region',
    city: 'Goose Crystal',
  },
  {
    region: 'Vladimir region',
    city: 'Zolotkovo',
  },
  {
    region: 'Vladimir region',
    city: 'Ivanishchi',
  },
  {
    region: 'Vladimir region',
    city: 'Kameshkovo',
  },
  {
    region: 'Vladimir region',
    city: 'Karabanovo',
  },
  {
    region: 'Vladimir region',
    city: 'Kirzhach',
  },
  {
    region: 'Vladimir region',
    city: 'Kovrov',
  },
  {
    region: 'Vladimir region',
    city: 'Kolchugino',
  },
  {
    region: 'Vladimir region',
    city: 'Krasnaya Gorbatka',
  },
  {
    region: 'Vladimir region',
    city: 'Melenki',
  },
  {
    region: 'Vladimir region',
    city: 'Murom',
  },
  {
    region: 'Vladimir region',
    city: 'Cockerels',
  },
  {
    region: 'Vladimir region',
    city: 'Pokrov',
  },
  {
    region: 'Vladimir region',
    city: 'Sobinka',
  },
  {
    region: 'Vladimir region',
    city: 'Sudogda',
  },
  {
    region: 'Vladimir region',
    city: 'Suzdal',
  },
  {
    region: 'Vladimir region',
    city: 'Yuryev-Polsky',
  },
  {
    region: 'Volgograd region',
    city: 'Alekseevskaya',
  },
  {
    region: 'Volgograd region',
    city: 'Alushchevsk',
  },
  {
    region: 'Volgograd region',
    city: 'Bykovo',
  },
  {
    region: 'Volgograd region',
    city: 'Volgograd',
  },
  {
    region: 'Volgograd region',
    city: 'Volzhsky',
  },
  {
    region: 'Volgograd region',
    city: 'Settlement',
  },
  {
    region: 'Volgograd region',
    city: 'Dubovka',
  },
  {
    region: 'Volgograd region',
    city: 'Elan',
  },
  {
    region: 'Volgograd region',
    city: 'Zhirnovsk',
  },
  {
    region: 'Volgograd region',
    city: 'Ilovlya',
  },
  {
    region: 'Volgograd region',
    city: 'Kalach-on-Don',
  },
  {
    region: 'Volgograd region',
    city: 'Kamyshin',
  },
  {
    region: 'Volgograd region',
    city: 'Kirillov',
  },
  {
    region: 'Volgograd region',
    city: 'Kletsky',
  },
  {
    region: 'Volgograd region',
    city: 'Kotelnikovo',
  },
  {
    region: 'Volgograd region',
    city: 'Kotovo',
  },
  {
    region: 'Volgograd region',
    city: 'Kumylzhenskaya',
  },
  {
    region: 'Volgograd region',
    city: 'Leninsk',
  },
  {
    region: 'Volgograd region',
    city: 'Mikhailovka',
  },
  {
    region: 'Volgograd region',
    city: 'Nekhaevsky',
  },
  {
    region: 'Volgograd region',
    city: 'Nikolaevsk',
  },
  {
    region: 'Volgograd region',
    city: 'Novoanninsky',
  },
  {
    region: 'Volgograd region',
    city: 'Novonikolaevskiy',
  },
  {
    region: 'Volgograd region',
    city: 'Olkhovka',
  },
  {
    region: 'Volgograd region',
    city: 'Pallasovka',
  },
  {
    region: 'Volgograd region',
    city: 'Rudnya',
  },
  {
    region: 'Volgograd region',
    city: 'Svetly Yar',
  },
  {
    region: 'Volgograd region',
    city: 'Serafimovich',
  },
  {
    region: 'Volgograd region',
    city: 'Middle Akhtuba',
  },
  {
    region: 'Volgograd region',
    city: 'Stalingrad',
  },
  {
    region: 'Volgograd region',
    city: 'Staraya Poltavka',
  },
  {
    region: 'Volgograd region',
    city: 'Surovikino',
  },
  {
    region: 'Volgograd region',
    city: 'Uryupinsk',
  },
  {
    region: 'Volgograd region',
    city: 'Frolovo',
  },
  {
    region: 'Volgograd region',
    city: 'Chernyshkovsky',
  },
  {
    region: 'Vologda region',
    city: 'Babaevo',
  },
  {
    region: 'Vologda region',
    city: 'Belozersk',
  },
  {
    region: 'Vologda region',
    city: 'Veliky Ustyug',
  },
  {
    region: 'Vologda region',
    city: 'Verkhovazhye',
  },
  {
    region: 'Vologda region',
    city: 'Vozhega',
  },
  {
    region: 'Vologda region',
    city: 'Vologda',
  },
  {
    region: 'Vologda region',
    city: 'Vokhtoga',
  },
  {
    region: 'Vologda region',
    city: 'Vytegra',
  },
  {
    region: 'Vologda region',
    city: 'Gryazovets',
  },
  {
    region: 'Vologda region',
    city: 'Kadnikov',
  },
  {
    region: 'Vologda region',
    city: 'Kaduy',
  },
  {
    region: 'Vologda region',
    city: 'Kichmeng town',
  },
  {
    region: 'Vologda region',
    city: 'Lipin Bor',
  },
  {
    region: 'Vologda region',
    city: 'Nikolsk',
  },
  {
    region: 'Vologda region',
    city: 'Nyuksenitsa',
  },
  {
    region: 'Vologda region',
    city: 'Falcon',
  },
  {
    region: 'Vologda region',
    city: 'Syamzha',
  },
  {
    region: 'Vologda region',
    city: 'Tarnog Town',
  },
  {
    region: 'Vologda region',
    city: 'Totma',
  },
  {
    region: 'Vologda region',
    city: 'Ustyuzhna',
  },
  {
    region: 'Vologda region',
    city: 'Kharovsk',
  },
  {
    region: 'Vologda region',
    city: 'Chagoda',
  },
  {
    region: 'Vologda region',
    city: 'Cherepovets',
  },
  {
    region: 'Vologda region',
    city: 'Sheksna',
  },
  {
    region: 'Vologda region',
    city: 'Shuyskoye',
  },
  {
    region: 'Voronezh region',
    city: 'Anna',
  },
  {
    region: 'Voronezh region',
    city: 'Bobrov',
  },
  {
    region: 'Voronezh region',
    city: 'Boguchar',
  },
  {
    region: 'Voronezh region',
    city: 'Borisoglebsk',
  },
  {
    region: 'Voronezh region',
    city: 'Buturlinovka',
  },
  {
    region: 'Voronezh region',
    city: 'Upper Mamon',
  },
  {
    region: 'Voronezh region',
    city: 'Verkhnyaya Hawa',
  },
  {
    region: 'Voronezh region',
    city: 'Vorobyevka',
  },
  {
    region: 'Voronezh region',
    city: 'Voronezh',
  },
  {
    region: 'Voronezh region',
    city: 'Gribanovsky',
  },
  {
    region: 'Voronezh region',
    city: 'Davydovka',
  },
  {
    region: 'Voronezh region',
    city: 'Elan-Kolenovsky',
  },
  {
    region: 'Voronezh region',
    city: 'Kalach',
  },
  {
    region: 'Voronezh region',
    city: 'Kantemirovka',
  },
  {
    region: 'Voronezh region',
    city: 'Liski',
  },
  {
    region: 'Voronezh region',
    city: 'Nizhnedevitsk',
  },
  {
    region: 'Voronezh region',
    city: 'New Usman',
  },
  {
    region: 'Voronezh region',
    city: 'Novokhopersk',
  },
  {
    region: 'Voronezh region',
    city: 'Olkhovatka',
  },
  {
    region: 'Voronezh region',
    city: 'Ostrogozhsk',
  },
  {
    region: 'Voronezh region',
    city: 'Pavlovsk',
  },
  {
    region: 'Voronezh region',
    city: 'Panino',
  },
  {
    region: 'Voronezh region',
    city: 'Petropavlovka',
  },
  {
    region: 'Voronezh region',
    city: 'Povorino',
  },
  {
    region: 'Voronezh region',
    city: 'Podgorensky',
  },
  {
    region: 'Voronezh region',
    city: 'Ramon',
  },
  {
    region: 'Voronezh region',
    city: 'Repyevka',
  },
  {
    region: 'Voronezh region',
    city: 'Rossosh',
  },
  {
    region: 'Voronezh region',
    city: 'Semiluki',
  },
  {
    region: 'Voronezh region',
    city: 'Talovaya',
  },
  {
    region: 'Voronezh region',
    city: 'Ternovka',
  },
  {
    region: 'Voronezh region',
    city: 'Khokholsky',
  },
  {
    region: 'Voronezh region',
    city: 'Ertil',
  },
  {
    region: 'Voronezh region',
    city: 'Novovoronezh',
  },
  {
    region: 'Dagestan',
    city: 'Agvali',
  },
  {
    region: 'Dagestan',
    city: 'Akusha',
  },
  {
    region: 'Dagestan',
    city: 'Akhty',
  },
  {
    region: 'Dagestan',
    city: 'Achisu',
  },
  {
    region: 'Dagestan',
    city: 'Babayurt',
  },
  {
    region: 'Dagestan',
    city: 'Bezhta',
  },
  {
    region: 'Dagestan',
    city: 'Botlich',
  },
  {
    region: 'Dagestan',
    city: 'Buinaksk',
  },
  {
    region: 'Dagestan',
    city: 'Wachi',
  },
  {
    region: 'Dagestan',
    city: 'Gergebil',
  },
  {
    region: 'Dagestan',
    city: 'Gunib',
  },
  {
    region: 'Dagestan',
    city: 'Dagestan Lights',
  },
  {
    region: 'Dagestan',
    city: 'Derbent',
  },
  {
    region: 'Dagestan',
    city: 'Dylym',
  },
  {
    region: 'Dagestan',
    city: 'Ershovka',
  },
  {
    region: 'Dagestan',
    city: 'Izberbash',
  },
  {
    region: 'Dagestan',
    city: 'Karabudakhkent',
  },
  {
    region: 'Dagestan',
    city: 'Carata',
  },
  {
    region: 'Dagestan',
    city: 'Kaspiysk',
  },
  {
    region: 'Dagestan',
    city: 'Kasumkent',
  },
  {
    region: 'Dagestan',
    city: 'Kizilyurt',
  },
  {
    region: 'Dagestan',
    city: 'Kizlyar',
  },
  {
    region: 'Dagestan',
    city: 'Kochubey',
  },
  {
    region: 'Dagestan',
    city: 'Kumuh',
  },
  {
    region: 'Dagestan',
    city: 'Kurakh',
  },
  {
    region: 'Dagestan',
    city: 'Magaramkent',
  },
  {
    region: 'Dagestan',
    city: 'Majalis',
  },
  {
    region: 'Dagestan',
    city: 'Makhachkala',
  },
  {
    region: 'Dagestan',
    city: 'Mechelta',
  },
  {
    region: 'Dagestan',
    city: 'Novolakskoye',
  },
  {
    region: 'Dagestan',
    city: 'Rutul',
  },
  {
    region: 'Dagestan',
    city: 'Soviet',
  },
  {
    region: 'Dagestan',
    city: 'Tarumovka',
  },
  {
    region: 'Dagestan',
    city: 'Terekli-Mekteb',
  },
  {
    region: 'Dagestan',
    city: 'Tlyarata',
  },
  {
    region: 'Dagestan',
    city: 'Tpig',
  },
  {
    region: 'Dagestan',
    city: 'Urkarah',
  },
  {
    region: 'Dagestan',
    city: 'Khasavyurt',
  },
  {
    region: 'Dagestan',
    city: 'Khiv',
  },
  {
    region: 'Dagestan',
    city: 'Hunzakh',
  },
  {
    region: 'Dagestan',
    city: 'Tsurib',
  },
  {
    region: 'Dagestan',
    city: 'Yuzhno-Sukhokumsk',
  },
  {
    region: 'Jewish region',
    city: 'Birobidzhan',
  },
  {
    region: 'Ivanovo region',
    city: 'Arkhipovka',
  },
  {
    region: 'Ivanovo region',
    city: 'Upper Landech',
  },
  {
    region: 'Ivanovo region',
    city: 'Vichuga',
  },
  {
    region: 'Ivanovo region',
    city: 'Gavrilov Posad',
  },
  {
    region: 'Ivanovo region',
    city: 'Dolmatovsky',
  },
  {
    region: 'Ivanovo region',
    city: 'Dulyapino',
  },
  {
    region: 'Ivanovo region',
    city: 'Zavolzhsk',
  },
  {
    region: 'Ivanovo region',
    city: 'Zarechny',
  },
  {
    region: 'Ivanovo region',
    city: 'Ivanovo',
  },
  {
    region: 'Ivanovo region',
    city: 'Ivankovsky',
  },
  {
    region: 'Ivanovo region',
    city: 'Ilyinskoye-Khovanskoye',
  },
  {
    region: 'Ivanovo region',
    city: 'Kaminsky',
  },
  {
    region: 'Ivanovo region',
    city: 'Kineshma',
  },
  {
    region: 'Ivanovo region',
    city: 'Komsomolsk',
  },
  {
    region: 'Ivanovo region',
    city: 'Kohma',
  },
  {
    region: 'Ivanovo region',
    city: 'Luh',
  },
  {
    region: 'Ivanovo region',
    city: 'Palekh',
  },
  {
    region: 'Ivanovo region',
    city: 'Pestyaki',
  },
  {
    region: 'Ivanovo region',
    city: 'Privolzhsk',
  },
  {
    region: 'Ivanovo region',
    city: 'Puchezh',
  },
  {
    region: 'Ivanovo region',
    city: 'Springs',
  },
  {
    region: 'Ivanovo region',
    city: 'Savino',
  },
  {
    region: 'Ivanovo region',
    city: 'Sokolskoye',
  },
  {
    region: 'Ivanovo region',
    city: 'Teykovo',
  },
  {
    region: 'Ivanovo region',
    city: 'Furmanov',
  },
  {
    region: 'Ivanovo region',
    city: 'Shuya',
  },
  {
    region: 'Ivanovo region',
    city: 'Yuzha',
  },
  {
    region: 'Ivanovo region',
    city: 'Yuryevets',
  },
  {
    region: 'Irkutsk region',
    city: 'Alekseevsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Alzamay',
  },
  {
    region: 'Irkutsk region',
    city: 'Alygjer',
  },
  {
    region: 'Irkutsk region',
    city: 'Angarsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Artemovsky',
  },
  {
    region: 'Irkutsk region',
    city: 'Atagay',
  },
  {
    region: 'Irkutsk region',
    city: 'Baikal',
  },
  {
    region: 'Irkutsk region',
    city: 'Baikalsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Balagansk',
  },
  {
    region: 'Irkutsk region',
    city: 'Bayandai',
  },
  {
    region: 'Irkutsk region',
    city: 'Biryusinsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Bodaibo',
  },
  {
    region: 'Irkutsk region',
    city: 'Bolshaya Rechka',
  },
  {
    region: 'Irkutsk region',
    city: 'Big Meadow',
  },
  {
    region: 'Irkutsk region',
    city: 'Bohan',
  },
  {
    region: 'Irkutsk region',
    city: 'Bratsk',
  },
  {
    region: 'Irkutsk region',
    city: 'See',
  },
  {
    region: 'Irkutsk region',
    city: 'Vitimsky',
  },
  {
    region: 'Irkutsk region',
    city: 'Vikhorevka',
  },
  {
    region: 'Irkutsk region',
    city: 'Elantsy',
  },
  {
    region: 'Irkutsk region',
    city: 'Erbogachen',
  },
  {
    region: 'Irkutsk region',
    city: 'Zheleznogorsk-Ilimsky',
  },
  {
    region: 'Irkutsk region',
    city: 'Zhigalovo',
  },
  {
    region: 'Irkutsk region',
    city: 'Score',
  },
  {
    region: 'Irkutsk region',
    city: 'Zalari',
  },
  {
    region: 'Irkutsk region',
    city: 'Star',
  },
  {
    region: 'Irkutsk region',
    city: 'Winter',
  },
  {
    region: 'Irkutsk region',
    city: 'Irkutsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Kazachinskoye',
  },
  {
    region: 'Irkutsk region',
    city: 'Kachug',
  },
  {
    region: 'Irkutsk region',
    city: 'Kvitok',
  },
  {
    region: 'Irkutsk region',
    city: 'Kirensk',
  },
  {
    region: 'Irkutsk region',
    city: 'Kuytun',
  },
  {
    region: 'Irkutsk region',
    city: 'Kultuk',
  },
  {
    region: 'Irkutsk region',
    city: 'Kutulik',
  },
  {
    region: 'Irkutsk region',
    city: 'Mom',
  },
  {
    region: 'Irkutsk region',
    city: 'Nizhneudinsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Wasp',
  },
  {
    region: 'Irkutsk region',
    city: 'Sayansk',
  },
  {
    region: 'Irkutsk region',
    city: 'Slyudyanka',
  },
  {
    region: 'Irkutsk region',
    city: 'Tayshet',
  },
  {
    region: 'Irkutsk region',
    city: 'Tulun',
  },
  {
    region: 'Irkutsk region',
    city: 'Usolye-Sibirskoye',
  },
  {
    region: 'Irkutsk region',
    city: 'Ust-Ilimsk',
  },
  {
    region: 'Irkutsk region',
    city: 'Ust-Kut',
  },
  {
    region: 'Irkutsk region',
    city: 'Ust-Ordynsky',
  },
  {
    region: 'Irkutsk region',
    city: 'Ust-Uda',
  },
  {
    region: 'Irkutsk region',
    city: 'Cheremkhovo',
  },
  {
    region: 'Irkutsk region',
    city: 'Chunsky',
  },
  {
    region: 'Irkutsk region',
    city: 'Shelekhov',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Baksan',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Maysky',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Nalchik',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Nartkala',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Cool',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Soviet',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Terek',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Tyrnyauz',
  },
  {
    region: 'Kabardino-Balkaria',
    city: 'Chegem-First',
  },
  {
    region: 'Kaliningrad region',
    city: 'Bagrationovsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Baltiysk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Gvardeysk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Guryevsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Gusev',
  },
  {
    region: 'Kaliningrad region',
    city: 'Railway',
  },
  {
    region: 'Kaliningrad region',
    city: 'Zelenogradsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Znamensk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Königsberg',
  },
  {
    region: 'Kaliningrad region',
    city: 'Kaliningrad',
  },
  {
    region: 'Kaliningrad region',
    city: 'Kenisberg',
  },
  {
    region: 'Kaliningrad region',
    city: 'Krasnoznamensk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Mamonovo',
  },
  {
    region: 'Kaliningrad region',
    city: 'Neman',
  },
  {
    region: 'Kaliningrad region',
    city: 'Nesterov',
  },
  {
    region: 'Kaliningrad region',
    city: 'Ozersk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Polessk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Pravdinsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Svetlogorsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Light',
  },
  {
    region: 'Kaliningrad region',
    city: 'Slavsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Sovetsk',
  },
  {
    region: 'Kaliningrad region',
    city: 'Chernyakhovsk',
  },
  {
    region: 'Kalmykia',
    city: 'Arshan',
  },
  {
    region: 'Kalmykia',
    city: 'Caspian',
  },
  {
    region: 'Kalmykia',
    city: 'Komsomolsky',
  },
  {
    region: 'Kalmykia',
    city: 'Small Derbets',
  },
  {
    region: 'Kalmykia',
    city: 'Priyutnoe',
  },
  {
    region: 'Kalmykia',
    city: 'Soviet',
  },
  {
    region: 'Kalmykia',
    city: 'Troitskoye',
  },
  {
    region: 'Kalmykia',
    city: 'Utta',
  },
  {
    region: 'Kalmykia',
    city: 'Tsagan-Aman',
  },
  {
    region: 'Kalmykia',
    city: 'Elista',
  },
  {
    region: 'Kalmykia',
    city: 'Justa',
  },
  {
    region: 'Kalmykia',
    city: 'Yashalta',
  },
  {
    region: 'Kalmykia',
    city: 'Yashkul',
  },
  {
    region: 'Kaluga region',
    city: 'Babynino',
  },
  {
    region: 'Kaluga region',
    city: 'Balabanovo',
  },
  {
    region: 'Kaluga region',
    city: 'Baryatino',
  },
  {
    region: 'Kaluga region',
    city: 'Belousovo',
  },
  {
    region: 'Kaluga region',
    city: 'Betlitsa',
  },
  {
    region: 'Kaluga region',
    city: 'Borovsk',
  },
  {
    region: 'Kaluga region',
    city: 'Dugna',
  },
  {
    region: 'Kaluga region',
    city: 'Dudorovsky',
  },
  {
    region: 'Kaluga region',
    city: 'Duminici',
  },
  {
    region: 'Kaluga region',
    city: 'Yelensky',
  },
  {
    region: 'Kaluga region',
    city: 'Zhizdra',
  },
  {
    region: 'Kaluga region',
    city: 'Wear',
  },
  {
    region: 'Kaluga region',
    city: 'Kaluga',
  },
  {
    region: 'Kaluga region',
    city: 'Kirov',
  },
  {
    region: 'Kaluga region',
    city: 'Kozelsk',
  },
  {
    region: 'Kaluga region',
    city: 'Kondrovo',
  },
  {
    region: 'Kaluga region',
    city: 'Lyudinovo',
  },
  {
    region: 'Kaluga region',
    city: 'Maloyaroslavets',
  },
  {
    region: 'Kaluga region',
    city: 'Medyn',
  },
  {
    region: 'Kaluga region',
    city: 'Meshchovsk',
  },
  {
    region: 'Kaluga region',
    city: 'Mosalsk',
  },
  {
    region: 'Kaluga region',
    city: 'Obninsk',
  },
  {
    region: 'Kaluga region',
    city: 'Przemysl',
  },
  {
    region: 'Kaluga region',
    city: 'Spas-Demensk',
  },
  {
    region: 'Kaluga region',
    city: 'Sukhinichi',
  },
  {
    region: 'Kaluga region',
    city: 'Tarusa',
  },
  {
    region: 'Kaluga region',
    city: 'Ulyanovo',
  },
  {
    region: 'Kaluga region',
    city: 'Ferzikovo',
  },
  {
    region: 'Kaluga region',
    city: 'Khvastovichi',
  },
  {
    region: 'Kaluga region',
    city: 'Yukhnov',
  },
  {
    region: 'Kamchatka region',
    city: 'Atlasovo',
  },
  {
    region: 'Kamchatka region',
    city: 'Ayanka',
  },
  {
    region: 'Kamchatka region',
    city: 'Bolsheretsk',
  },
  {
    region: 'Kamchatka region',
    city: 'Vilyuchinsk',
  },
  {
    region: 'Kamchatka region',
    city: 'Yelizovo',
  },
  {
    region: 'Kamchatka region',
    city: 'Ilpyrsky',
  },
  {
    region: 'Kamchatka region',
    city: 'Kamenskoye',
  },
  {
    region: 'Kamchatka region',
    city: 'Kirovsky',
  },
  {
    region: 'Kamchatka region',
    city: 'Keys',
  },
  {
    region: 'Kamchatka region',
    city: 'Nettle',
  },
  {
    region: 'Kamchatka region',
    city: 'Milkovo',
  },
  {
    region: 'Kamchatka region',
    city: 'Nikolskoye',
  },
  {
    region: 'Kamchatka region',
    city: 'Ozernovsky',
  },
  {
    region: 'Kamchatka region',
    city: 'Ossora',
  },
  {
    region: 'Kamchatka region',
    city: 'Palana',
  },
  {
    region: 'Kamchatka region',
    city: 'Guy',
  },
  {
    region: 'Kamchatka region',
    city: 'Pahachi',
  },
  {
    region: 'Kamchatka region',
    city: 'Petropavlovsk-Kamchatsky',
  },
  {
    region: 'Kamchatka region',
    city: 'Tigil',
  },
  {
    region: 'Kamchatka region',
    city: 'Tilichiki',
  },
  {
    region: 'Kamchatka region',
    city: 'Ust-Bolsheretsk',
  },
  {
    region: 'Kamchatka region',
    city: 'Ust-Kamchatsk',
  },
  {
    region: 'Karelia',
    city: 'Barn',
  },
  {
    region: 'Karelia',
    city: 'Belomorsk',
  },
  {
    region: 'Karelia',
    city: 'Valaam',
  },
  {
    region: 'Karelia',
    city: 'Virandozero',
  },
  {
    region: 'Karelia',
    city: 'Girvas',
  },
  {
    region: 'Karelia',
    city: 'Derevyanka',
  },
  {
    region: 'Karelia',
    city: 'Idel',
  },
  {
    region: 'Karelia',
    city: 'Ilyinsky',
  },
  {
    region: 'Karelia',
    city: 'Impalahti',
  },
  {
    region: 'Karelia',
    city: 'Kalevala',
  },
  {
    region: 'Karelia',
    city: 'Kem',
  },
  {
    region: 'Karelia',
    city: 'Kestenga',
  },
  {
    region: 'Karelia',
    city: 'Kondopoga',
  },
  {
    region: 'Karelia',
    city: 'Kostomuksha',
  },
  {
    region: 'Karelia',
    city: 'Lahdenpohja',
  },
  {
    region: 'Karelia',
    city: 'Louhi',
  },
  {
    region: 'Karelia',
    city: 'Medvezhyegorsk',
  },
  {
    region: 'Karelia',
    city: 'Muezersky',
  },
  {
    region: 'Karelia',
    city: 'Olonets',
  },
  {
    region: 'Karelia',
    city: 'Petrozavodsk',
  },
  {
    region: 'Karelia',
    city: 'Pitkäranta',
  },
  {
    region: 'Karelia',
    city: 'Povenets',
  },
  {
    region: 'Karelia',
    city: 'Yarn',
  },
  {
    region: 'Karelia',
    city: 'Pudozh',
  },
  {
    region: 'Karelia',
    city: 'Segezha',
  },
  {
    region: 'Karelia',
    city: 'Sortavala',
  },
  {
    region: 'Karelia',
    city: 'Sophporog',
  },
  {
    region: 'Karelia',
    city: 'Suojärvi',
  },
  {
    region: 'Kemerovo region',
    city: 'Anzhero-Sudzhensk',
  },
  {
    region: 'Kemerovo region',
    city: 'Barzas',
  },
  {
    region: 'Kemerovo region',
    city: 'Belovo',
  },
  {
    region: 'Kemerovo region',
    city: 'Belogorsk',
  },
  {
    region: 'Kemerovo region',
    city: 'Berezovsky',
  },
  {
    region: 'Kemerovo region',
    city: 'Gramoteino',
  },
  {
    region: 'Kemerovo region',
    city: 'Guryevsk',
  },
  {
    region: 'Kemerovo region',
    city: 'Izhmorsky',
  },
  {
    region: 'Kemerovo region',
    city: 'Itatsky',
  },
  {
    region: 'Kemerovo region',
    city: 'Kaltan',
  },
  {
    region: 'Kemerovo region',
    city: 'Kedrovka',
  },
  {
    region: 'Kemerovo region',
    city: 'Kemerovo',
  },
  {
    region: 'Kemerovo region',
    city: 'Kiselevsk',
  },
  {
    region: 'Kemerovo region',
    city: 'Krapivinsky',
  },
  {
    region: 'Kemerovo region',
    city: 'Leninsk-Kuznetsky',
  },
  {
    region: 'Kemerovo region',
    city: 'Mariinsk',
  },
  {
    region: 'Kemerovo region',
    city: 'Mezhdurechensk',
  },
  {
    region: 'Kemerovo region',
    city: 'Myski',
  },
  {
    region: 'Kemerovo region',
    city: 'Novokuznetsk',
  },
  {
    region: 'Kemerovo region',
    city: 'Osinniki',
  },
  {
    region: 'Kemerovo region',
    city: 'Prokopyevsk',
  },
  {
    region: 'Kemerovo region',
    city: 'Industrial',
  },
  {
    region: 'Kemerovo region',
    city: 'Taiga',
  },
  {
    region: 'Kemerovo region',
    city: 'Tashtagol',
  },
  {
    region: 'Kemerovo region',
    city: 'Tisul',
  },
  {
    region: 'Kemerovo region',
    city: 'Furnaces',
  },
  {
    region: 'Kemerovo region',
    city: 'Tyazhinsky',
  },
  {
    region: 'Kemerovo region',
    city: 'Yurga',
  },
  {
    region: 'Kemerovo region',
    city: 'Yashkino',
  },
  {
    region: 'Kemerovo region',
    city: 'Yaya',
  },
  {
    region: 'Kirov region',
    city: 'Arbage',
  },
  {
    region: 'Kirov region',
    city: 'Arkul',
  },
  {
    region: 'Kirov region',
    city: 'Belaya Kholunitsa',
  },
  {
    region: 'Kirov region',
    city: 'Bogorodskoye',
  },
  {
    region: 'Kirov region',
    city: 'Borovoy',
  },
  {
    region: 'Kirov region',
    city: 'Verkhoshizhemye',
  },
  {
    region: 'Kirov region',
    city: 'Vyatskiye Polyany',
  },
  {
    region: 'Kirov region',
    city: 'Zuevka',
  },
  {
    region: 'Kirov region',
    city: 'Karintorf',
  },
  {
    region: 'Kirov region',
    city: 'Kiknur',
  },
  {
    region: 'Kirov region',
    city: 'Kilmez',
  },
  {
    region: 'Kirov region',
    city: 'Kirov',
  },
  {
    region: 'Kirov region',
    city: 'Kirovo-Chepetsk',
  },
  {
    region: 'Kirov region',
    city: 'Kirs',
  },
  {
    region: 'Kirov region',
    city: 'Cobra',
  },
  {
    region: 'Kirov region',
    city: 'Kotelnich',
  },
  {
    region: 'Kirov region',
    city: 'Kumeny',
  },
  {
    region: 'Kirov region',
    city: 'Leninskoe',
  },
  {
    region: 'Kirov region',
    city: 'Luza',
  },
  {
    region: 'Kirov region',
    city: 'Malmyzh',
  },
  {
    region: 'Kirov region',
    city: 'Murashi',
  },
  {
    region: 'Kirov region',
    city: 'Nagorsk',
  },
  {
    region: 'Kirov region',
    city: 'Nema',
  },
  {
    region: 'Kirov region',
    city: 'Novovyatsk',
  },
  {
    region: 'Kirov region',
    city: 'Nolinsk',
  },
  {
    region: 'Kirov region',
    city: 'Omutninsk',
  },
  {
    region: 'Kirov region',
    city: 'Oparino',
  },
  {
    region: 'Kirov region',
    city: 'Orichi',
  },
  {
    region: 'Kirov region',
    city: 'Pizhanka',
  },
  {
    region: 'Kirov region',
    city: 'Podosinovets',
  },
  {
    region: 'Kirov region',
    city: 'Sanchursk',
  },
  {
    region: 'Kirov region',
    city: 'Candle',
  },
  {
    region: 'Kirov region',
    city: 'Slobodskoy',
  },
  {
    region: 'Kirov region',
    city: 'Sovetsk',
  },
  {
    region: 'Kirov region',
    city: 'Suna',
  },
  {
    region: 'Kirov region',
    city: 'Tuzha',
  },
  {
    region: 'Kirov region',
    city: 'Uni',
  },
  {
    region: 'Kirov region',
    city: 'Urzhum',
  },
  {
    region: 'Kirov region',
    city: 'Falenki',
  },
  {
    region: 'Kirov region',
    city: 'Khalturin',
  },
  {
    region: 'Kirov region',
    city: 'Yurya',
  },
  {
    region: 'Kirov region',
    city: 'Yaransk',
  },
  {
    region: 'Komi',
    city: 'Abez',
  },
  {
    region: 'Komi',
    city: 'Aikino',
  },
  {
    region: 'Komi',
    city: 'Verkhnyaya Inta',
  },
  {
    region: 'Komi',
    city: 'Vizinga',
  },
  {
    region: 'Komi',
    city: 'Water',
  },
  {
    region: 'Komi',
    city: 'Leader',
  },
  {
    region: 'Komi',
    city: 'Vorkuta',
  },
  {
    region: 'Komi',
    city: 'Vuktyl',
  },
  {
    region: 'Komi',
    city: 'Geshart',
  },
  {
    region: 'Komi',
    city: 'Yeletsky',
  },
  {
    region: 'Komi',
    city: 'Emva',
  },
  {
    region: 'Komi',
    city: 'Zapolyarny',
  },
  {
    region: 'Komi',
    city: 'Izhma',
  },
  {
    region: 'Komi',
    city: 'Inta',
  },
  {
    region: 'Komi',
    city: 'Israel',
  },
  {
    region: 'Komi',
    city: 'Kajerom',
  },
  {
    region: 'Komi',
    city: 'Each',
  },
  {
    region: 'Komi',
    city: 'Kozhym',
  },
  {
    region: 'Komi',
    city: 'Koygorodok',
  },
  {
    region: 'Komi',
    city: 'Kortkeros',
  },
  {
    region: 'Komi',
    city: 'Coslan',
  },
  {
    region: 'Komi',
    city: 'Obyachevo',
  },
  {
    region: 'Komi',
    city: 'Pechora',
  },
  {
    region: 'Komi',
    city: 'Sosnogorsk',
  },
  {
    region: 'Komi',
    city: 'Syktyvkar',
  },
  {
    region: 'Komi',
    city: 'Troitsko-Pechersk',
  },
  {
    region: 'Komi',
    city: 'Usinsk',
  },
  {
    region: 'Komi',
    city: 'Usogorsk',
  },
  {
    region: 'Komi',
    city: 'Ust-Kulom',
  },
  {
    region: 'Komi',
    city: 'Ust-Tsilma',
  },
  {
    region: 'Komi',
    city: 'Uhta',
  },
  {
    region: 'Kostroma region',
    city: 'Antropovo',
  },
  {
    region: 'Kostroma region',
    city: 'Bogovarovo',
  },
  {
    region: 'Kostroma region',
    city: 'Buoy',
  },
  {
    region: 'Kostroma region',
    city: 'Volgorechensk',
  },
  {
    region: 'Kostroma region',
    city: 'Galich',
  },
  {
    region: 'Kostroma region',
    city: 'Gorchukha',
  },
  {
    region: 'Kostroma region',
    city: 'Zebljaki',
  },
  {
    region: 'Kostroma region',
    city: 'Kadyy',
  },
  {
    region: 'Kostroma region',
    city: 'Kologriv',
  },
  {
    region: 'Kostroma region',
    city: 'Kostroma',
  },
  {
    region: 'Kostroma region',
    city: 'Red-on-Volga',
  },
  {
    region: 'Kostroma region',
    city: 'Makariev',
  },
  {
    region: 'Kostroma region',
    city: 'Manturovo',
  },
  {
    region: 'Kostroma region',
    city: 'Nerekhta',
  },
  {
    region: 'Kostroma region',
    city: 'Neya',
  },
  {
    region: 'Kostroma region',
    city: 'Ostrovskoye',
  },
  {
    region: 'Kostroma region',
    city: 'Pavino',
  },
  {
    region: 'Kostroma region',
    city: 'Parfentievo',
  },
  {
    region: 'Kostroma region',
    city: 'Ponazyrevo',
  },
  {
    region: 'Kostroma region',
    city: 'Soligalich',
  },
  {
    region: 'Kostroma region',
    city: 'Sudislavl',
  },
  {
    region: 'Kostroma region',
    city: 'Susanino',
  },
  {
    region: 'Kostroma region',
    city: 'Chukhloma',
  },
  {
    region: 'Kostroma region',
    city: 'Sharya',
  },
  {
    region: 'Kostroma region',
    city: 'Shemyatino',
  },
  {
    region: 'Krasnodar region',
    city: 'Abinsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Abrau-Durso',
  },
  {
    region: 'Krasnodar region',
    city: 'Anapa',
  },
  {
    region: 'Krasnodar region',
    city: 'Apsheronsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Armavir',
  },
  {
    region: 'Krasnodar region',
    city: 'Arkhipo-Osipovka',
  },
  {
    region: 'Krasnodar region',
    city: 'Afipsky',
  },
  {
    region: 'Krasnodar region',
    city: 'Akhtyrsky',
  },
  {
    region: 'Krasnodar region',
    city: 'Achuevo',
  },
  {
    region: 'Krasnodar region',
    city: 'Belorechensk',
  },
  {
    region: 'Krasnodar region',
    city: 'Verkhnebakansky',
  },
  {
    region: 'Krasnodar region',
    city: 'Settlements',
  },
  {
    region: 'Krasnodar region',
    city: 'Gelendzhik',
  },
  {
    region: 'Krasnodar region',
    city: 'Giaginskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Hot Key',
  },
  {
    region: 'Krasnodar region',
    city: 'Dzhubga',
  },
  {
    region: 'Krasnodar region',
    city: 'Dinskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Yeysk',
  },
  {
    region: 'Krasnodar region',
    city: 'Ilsky',
  },
  {
    region: 'Krasnodar region',
    city: 'Kabardinka',
  },
  {
    region: 'Krasnodar region',
    city: 'Kalinino',
  },
  {
    region: 'Krasnodar region',
    city: 'Kalininskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Kamennomostsky',
  },
  {
    region: 'Krasnodar region',
    city: 'Kanevskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Korenovsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Krasnoarmeyskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Krasnodar',
  },
  {
    region: 'Krasnodar region',
    city: 'Kropotkin',
  },
  {
    region: 'Krasnodar region',
    city: 'Krylovskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Krymsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Kurganinsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Kushchevskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Labinsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Leningradskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Maikop',
  },
  {
    region: 'Krasnodar region',
    city: 'Mostovskoy',
  },
  {
    region: 'Krasnodar region',
    city: 'Novorossiysk',
  },
  {
    region: 'Krasnodar region',
    city: 'Gratifying',
  },
  {
    region: 'Krasnodar region',
    city: 'Pavlovskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Primorsko-Akhtarsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Severskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Slavyansk-on-Kuban',
  },
  {
    region: 'Krasnodar region',
    city: 'Sochi',
  },
  {
    region: 'Krasnodar region',
    city: 'Starominskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Staroscherbinovskaya',
  },
  {
    region: 'Krasnodar region',
    city: 'Tbilisi',
  },
  {
    region: 'Krasnodar region',
    city: 'Temryuk',
  },
  {
    region: 'Krasnodar region',
    city: 'Timashevsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Tikhoretsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Tuapse',
  },
  {
    region: 'Krasnodar region',
    city: 'Tula',
  },
  {
    region: 'Krasnodar region',
    city: 'Ust-Labinsk',
  },
  {
    region: 'Krasnodar region',
    city: 'Shovgenovsky',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Zheleznogorsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Abaza',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Abakan',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Aban',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Aginskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Artemovsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Askiz',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Achinsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Baykit',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Balakhta',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Balyksa',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Bely Yar',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Beltyrsky',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Beya',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Biskamzha',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Bogotol',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Bograd',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Boguchany',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Bolshaya Murta',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Big Uluy',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Borodino',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Vanavara',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Verkhneimbatsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Goryachegorsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Dzerzhinskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Divnogorsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Dixon',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Dudinka',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Yemelyanovo',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Yeniseisk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Ermakovskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Zaozerny',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Zelenogorsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Igarka',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Idrinskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Ilansky',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Irbeyskoe',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kazachinskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kansk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Karatuzskoe',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Guard',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kezhma',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kodinsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kozulka',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kopyevo',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Krasnoturansk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Krasnoyarsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Kuragino',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Lesosibirsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Minusinsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Motygino',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Nazarovo',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Nizhny Ingash',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Novoselovo',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Norilsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Partizanskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Pirovskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Sayanogorsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Severo-Yeniseisky',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Sosnovoborsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Taseevo',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Tashtyp',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Tours',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Turukhansk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Tyukhtet',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Uzhur',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Ust-Avam',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Uyar',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Khatanga',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Cheryomushki',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Chernogorsk',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Shalinskoye',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Sharypovo',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Shira',
  },
  {
    region: 'Krasnoyarsk Territory',
    city: 'Shushenskoye',
  },
  {
    region: 'Kurgan region',
    city: 'Vargashi',
  },
  {
    region: 'Kurgan region',
    city: 'Glyadyanskoye',
  },
  {
    region: 'Kurgan region',
    city: 'Dalmatovo',
  },
  {
    region: 'Kurgan region',
    city: 'Kargapole',
  },
  {
    region: 'Kurgan region',
    city: 'Kataysk',
  },
  {
    region: 'Kurgan region',
    city: 'Ketovo',
  },
  {
    region: 'Kurgan region',
    city: 'Kurgan',
  },
  {
    region: 'Kurgan region',
    city: 'Kurtamysh',
  },
  {
    region: 'Kurgan region',
    city: 'Swan',
  },
  {
    region: 'Kurgan region',
    city: 'Makushino',
  },
  {
    region: 'Kurgan region',
    city: 'Mishkino',
  },
  {
    region: 'Kurgan region',
    city: 'Mokrousovo',
  },
  {
    region: 'Kurgan region',
    city: 'Petukhovo',
  },
  {
    region: 'Kurgan region',
    city: 'Half',
  },
  {
    region: 'Kurgan region',
    city: 'Safakulevo',
  },
  {
    region: 'Kurgan region',
    city: 'Tselinnoe',
  },
  {
    region: 'Kurgan region',
    city: 'Shadrinsk',
  },
  {
    region: 'Kurgan region',
    city: 'Shatrovo',
  },
  {
    region: 'Kurgan region',
    city: 'Hype',
  },
  {
    region: 'Kurgan region',
    city: 'Pike',
  },
  {
    region: 'Kurgan region',
    city: 'Yurgamysh',
  },
  {
    region: 'Kursk region',
    city: 'Almenevo',
  },
  {
    region: 'Kursk region',
    city: 'White',
  },
  {
    region: 'Kursk region',
    city: 'Bolshoye Soldatskoye',
  },
  {
    region: 'Kursk region',
    city: 'Glushkovo',
  },
  {
    region: 'Kursk region',
    city: 'Potty',
  },
  {
    region: 'Kursk region',
    city: 'Dmitriev-Lgovsky',
  },
  {
    region: 'Kursk region',
    city: 'Zheleznogorsk',
  },
  {
    region: 'Kursk region',
    city: 'Zolotukhino',
  },
  {
    region: 'Kursk region',
    city: 'Castornoe',
  },
  {
    region: 'Kursk region',
    city: 'Konyshevka',
  },
  {
    region: 'Kursk region',
    city: 'Korenevo',
  },
  {
    region: 'Kursk region',
    city: 'Kursk',
  },
  {
    region: 'Kursk region',
    city: 'Kurchatov',
  },
  {
    region: 'Kursk region',
    city: 'Kshensky',
  },
  {
    region: 'Kursk region',
    city: 'Lgov',
  },
  {
    region: 'Kursk region',
    city: 'Manturovo',
  },
  {
    region: 'Kursk region',
    city: 'Medvenka',
  },
  {
    region: 'Kursk region',
    city: 'Oboyan',
  },
  {
    region: 'Kursk region',
    city: 'Ponyri',
  },
  {
    region: 'Kursk region',
    city: 'Pristen',
  },
  {
    region: 'Kursk region',
    city: 'Pryamitsyno',
  },
  {
    region: 'Kursk region',
    city: 'Rylsk',
  },
  {
    region: 'Kursk region',
    city: 'Suja',
  },
  {
    region: 'Kursk region',
    city: 'Tim',
  },
  {
    region: 'Kursk region',
    city: 'Fatezh',
  },
  {
    region: 'Kursk region',
    city: 'Khomutovka',
  },
  {
    region: 'Kursk region',
    city: 'Cheremisinovo',
  },
  {
    region: 'Kursk region',
    city: 'Shchigry',
  },
  {
    region: 'Lipetsk region',
    city: 'Dirt',
  },
  {
    region: 'Lipetsk region',
    city: 'Danhov',
  },
  {
    region: 'Lipetsk region',
    city: 'Good',
  },
  {
    region: 'Lipetsk region',
    city: 'Dolgorukovo',
  },
  {
    region: 'Lipetsk region',
    city: 'Yelets',
  },
  {
    region: 'Lipetsk region',
    city: 'Zadonsk',
  },
  {
    region: 'Lipetsk region',
    city: 'Izmalkovo',
  },
  {
    region: 'Lipetsk region',
    city: 'Kazinka',
  },
  {
    region: 'Lipetsk region',
    city: 'Lebedyan',
  },
  {
    region: 'Lipetsk region',
    city: 'Leo Tolstoy',
  },
  {
    region: 'Lipetsk region',
    city: 'Lipetsk',
  },
  {
    region: 'Lipetsk region',
    city: 'Terbuny',
  },
  {
    region: 'Lipetsk region',
    city: 'Usman',
  },
  {
    region: 'Lipetsk region',
    city: 'Khlevnoe',
  },
  {
    region: 'Lipetsk region',
    city: 'Chaplygin',
  },
  {
    region: 'Magadan region',
    city: 'Anadyr',
  },
  {
    region: 'Magadan region',
    city: 'Atka',
  },
  {
    region: 'Magadan region',
    city: 'Balygychan',
  },
  {
    region: 'Magadan region',
    city: 'Beringovsky',
  },
  {
    region: 'Magadan region',
    city: 'Bilibino',
  },
  {
    region: 'Magadan region',
    city: 'Bolshevik',
  },
  {
    region: 'Magadan region',
    city: 'Vankarem',
  },
  {
    region: 'Magadan region',
    city: 'Julitin',
  },
  {
    region: 'Magadan region',
    city: 'Kadikchan',
  },
  {
    region: 'Magadan region',
    city: 'Lawrence',
  },
  {
    region: 'Magadan region',
    city: 'Magadan',
  },
  {
    region: 'Magadan region',
    city: 'Cape Schmidt',
  },
  {
    region: 'Magadan region',
    city: 'Ola',
  },
  {
    region: 'Magadan region',
    city: 'Omonsk',
  },
  {
    region: 'Magadan region',
    city: 'Omsukchan',
  },
  {
    region: 'Magadan region',
    city: 'Tent',
  },
  {
    region: 'Magadan region',
    city: 'Pevek',
  },
  {
    region: 'Magadan region',
    city: 'Providence',
  },
  {
    region: 'Magadan region',
    city: 'Seymchan',
  },
  {
    region: 'Magadan region',
    city: 'Sinegorye',
  },
  {
    region: 'Magadan region',
    city: 'Susuman',
  },
  {
    region: 'Magadan region',
    city: 'Ust-Belaya',
  },
  {
    region: 'Magadan region',
    city: 'Ust-Omchug',
  },
  {
    region: 'Magadan region',
    city: 'Evensk',
  },
  {
    region: 'Magadan region',
    city: 'Egvekinot',
  },
  {
    region: 'Magadan region',
    city: 'Berry',
  },
  {
    region: 'Mari El',
    city: 'Volzhsk',
  },
  {
    region: 'Mari El',
    city: 'Dubovsky',
  },
  {
    region: 'Mari El',
    city: 'Zvenigovo',
  },
  {
    region: 'Mari El',
    city: 'Yoshkar-Ola',
  },
  {
    region: 'Mari El',
    city: 'Kilemars',
  },
  {
    region: 'Mari El',
    city: 'Kozmodemyansk',
  },
  {
    region: 'Mari El',
    city: 'Kuzhener',
  },
  {
    region: 'Mari El',
    city: 'Marie-Turek',
  },
  {
    region: 'Mari El',
    city: 'Medvedevo',
  },
  {
    region: 'Mari El',
    city: 'Mores',
  },
  {
    region: 'Mari El',
    city: 'New Toryal',
  },
  {
    region: 'Mari El',
    city: 'Orshanka',
  },
  {
    region: 'Mari El',
    city: 'Paranga',
  },
  {
    region: 'Mari El',
    city: 'Sernur',
  },
  {
    region: 'Mari El',
    city: 'Soviet',
  },
  {
    region: 'Mari El',
    city: 'Yurino',
  },
  {
    region: 'Mordovia',
    city: 'Ardatov',
  },
  {
    region: 'Mordovia',
    city: 'Atyuryevo',
  },
  {
    region: 'Mordovia',
    city: 'Atyashevo',
  },
  {
    region: 'Mordovia',
    city: 'Bolshiye Berezniki',
  },
  {
    region: 'Mordovia',
    city: 'Bolshoye Ignatovo',
  },
  {
    region: 'Mordovia',
    city: 'Higher',
  },
  {
    region: 'Mordovia',
    city: 'Spruce trees',
  },
  {
    region: 'Mordovia',
    city: 'Zubova Polyana',
  },
  {
    region: 'Mordovia',
    city: 'Insar',
  },
  {
    region: 'Mordovia',
    city: 'Kadoshkino',
  },
  {
    region: 'Mordovia',
    city: 'Kemlya',
  },
  {
    region: 'Mordovia',
    city: 'Kovylkino',
  },
  {
    region: 'Mordovia',
    city: 'Komsomolsky',
  },
  {
    region: 'Mordovia',
    city: 'Kochkurovo',
  },
  {
    region: 'Mordovia',
    city: 'Krasnoslobodsk',
  },
  {
    region: 'Mordovia',
    city: 'Lyambir',
  },
  {
    region: 'Mordovia',
    city: 'Romodanovo',
  },
  {
    region: 'Mordovia',
    city: 'Ruzaevka',
  },
  {
    region: 'Mordovia',
    city: 'Saransk',
  },
  {
    region: 'Mordovia',
    city: 'Old Shaygovo',
  },
  {
    region: 'Mordovia',
    city: 'Temnikov',
  },
  {
    region: 'Mordovia',
    city: 'Tengushevo',
  },
  {
    region: 'Mordovia',
    city: 'Torbeevo',
  },
  {
    region: 'Mordovia',
    city: 'Chamzinka',
  },
  {
    region: 'Murmansk region',
    city: 'Apatity',
  },
  {
    region: 'Murmansk region',
    city: 'Africana',
  },
  {
    region: 'Murmansk region',
    city: 'Verkhnetulomsky',
  },
  {
    region: 'Murmansk region',
    city: 'Zaozersk',
  },
  {
    region: 'Murmansk region',
    city: 'Zapolyarny',
  },
  {
    region: 'Murmansk region',
    city: 'Zarechensk',
  },
  {
    region: 'Murmansk region',
    city: 'Neck',
  },
  {
    region: 'Murmansk region',
    city: 'Zelenoborsky',
  },
  {
    region: 'Murmansk region',
    city: 'Kandalaksha',
  },
  {
    region: 'Murmansk region',
    city: 'Kildinstroy',
  },
  {
    region: 'Murmansk region',
    city: 'Kirovsk',
  },
  {
    region: 'Murmansk region',
    city: 'Kovdor',
  },
  {
    region: 'Murmansk region',
    city: 'Cola',
  },
  {
    region: 'Murmansk region',
    city: 'Konda',
  },
  {
    region: 'Murmansk region',
    city: 'Monchegorsk',
  },
  {
    region: 'Murmansk region',
    city: 'Murmansk',
  },
  {
    region: 'Murmansk region',
    city: 'Murmashi',
  },
  {
    region: 'Murmansk region',
    city: 'Nickel',
  },
  {
    region: 'Murmansk region',
    city: 'Olenegorsk',
  },
  {
    region: 'Murmansk region',
    city: 'Polyarnye Zori',
  },
  {
    region: 'Murmansk region',
    city: 'Polar',
  },
  {
    region: 'Murmansk region',
    city: 'Severomorsk',
  },
  {
    region: 'Murmansk region',
    city: 'Snezhnogorsk',
  },
  {
    region: 'Murmansk region',
    city: 'Umba',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Ardatov',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Arzamas',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Arya',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Balakhna',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Bogorodsk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Bolsherechensk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Big Boldino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Bolshoye Kozino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Bolshoye Murashkino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Big Pichino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Bor',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Buturlino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Wade',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Varnavino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vasilsursk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vakhtan',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vacha',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Veletma',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vetluga',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vilya',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Voznesenskoye',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Volodarsk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vorotynets',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vorsma',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Voskresenskoye',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Exit',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Vyksa',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Gagino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Hydropeat',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Gorbatov',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Gorbatovka',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Gorodets',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Gorky',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Far Konstantinovo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Dzerzhinsk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Diveevo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Board',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Zavolzhye',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Katunki',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Kerzhenets',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Knyaginino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Kovernino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Krasnye Baki',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Kstovo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Kulebaki',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Lukoyanov',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Lyskovo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Navashino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Nizhny Novgorod',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Pavlovo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Pervomaisk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Transport',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Pilna',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Repairs',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Sarov',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Sergach',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Sechenovo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Sosnovskoye',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Spasskoye',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Tonkino',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Tonshaevo',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Urazovka',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Uren',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Chkalovsk',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Sharanga',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Shaky',
  },
  {
    region: 'Nizhny Novgorod (Gorkovskaya)',
    city: 'Shahunya',
  },
  {
    region: 'Novgorod region',
    city: 'Antsiferovo',
  },
  {
    region: 'Novgorod region',
    city: 'Batetsky',
  },
  {
    region: 'Novgorod region',
    city: 'Big Vishera',
  },
  {
    region: 'Novgorod region',
    city: 'Borovichi',
  },
  {
    region: 'Novgorod region',
    city: 'Valdai',
  },
  {
    region: 'Novgorod region',
    city: 'Volot',
  },
  {
    region: 'Novgorod region',
    city: 'Demansk',
  },
  {
    region: 'Novgorod region',
    city: 'Zarubino',
  },
  {
    region: 'Novgorod region',
    city: 'Krestsy',
  },
  {
    region: 'Novgorod region',
    city: 'Lyubytino',
  },
  {
    region: 'Novgorod region',
    city: 'Malaya Vishera',
  },
  {
    region: 'Novgorod region',
    city: 'Marevo',
  },
  {
    region: 'Novgorod region',
    city: 'Fraud',
  },
  {
    region: 'Novgorod region',
    city: 'Novgorod',
  },
  {
    region: 'Novgorod region',
    city: 'Okulovka',
  },
  {
    region: 'Novgorod region',
    city: 'Parfino',
  },
  {
    region: 'Novgorod region',
    city: 'Pestovo',
  },
  {
    region: 'Novgorod region',
    city: 'Poddorje',
  },
  {
    region: 'Novgorod region',
    city: 'Soltsy',
  },
  {
    region: 'Novgorod region',
    city: 'Staraya Russa',
  },
  {
    region: 'Novgorod region',
    city: 'Coniferous',
  },
  {
    region: 'Novgorod region',
    city: 'Hill',
  },
  {
    region: 'Novgorod region',
    city: 'Chudovo',
  },
  {
    region: 'Novgorod region',
    city: 'Shimsk',
  },
  {
    region: 'Novosibirsk region',
    city: 'Bagan',
  },
  {
    region: 'Novosibirsk region',
    city: 'Barabinsk',
  },
  {
    region: 'Novosibirsk region',
    city: 'Berdsk',
  },
  {
    region: 'Novosibirsk region',
    city: 'Biasa',
  },
  {
    region: 'Novosibirsk region',
    city: 'Bolotnoe',
  },
  {
    region: 'Novosibirsk region',
    city: 'Vengerovo',
  },
  {
    region: 'Novosibirsk region',
    city: 'Satisfied',
  },
  {
    region: 'Novosibirsk region',
    city: 'Zavyalovo',
  },
  {
    region: 'Novosibirsk region',
    city: 'Iskitim',
  },
  {
    region: 'Novosibirsk region',
    city: 'Karasuk',
  },
  {
    region: 'Novosibirsk region',
    city: 'Kargat',
  },
  {
    region: 'Novosibirsk region',
    city: 'Kolyvan',
  },
  {
    region: 'Novosibirsk region',
    city: 'Krasnozerskoye',
  },
  {
    region: 'Novosibirsk region',
    city: 'Krutiha',
  },
  {
    region: 'Novosibirsk region',
    city: 'Kuibyshev',
  },
  {
    region: 'Novosibirsk region',
    city: 'Kupino',
  },
  {
    region: 'Novosibirsk region',
    city: 'Kyshtovka',
  },
  {
    region: 'Novosibirsk region',
    city: 'Maslyanino',
  },
  {
    region: 'Novosibirsk region',
    city: 'Mikhailovsky',
  },
  {
    region: 'Novosibirsk region',
    city: 'Moshkovo',
  },
  {
    region: 'Novosibirsk region',
    city: 'Novosibirsk',
  },
  {
    region: 'Novosibirsk region',
    city: 'Ordynskoe',
  },
  {
    region: 'Novosibirsk region',
    city: 'Northern',
  },
  {
    region: 'Novosibirsk region',
    city: 'Suzun',
  },
  {
    region: 'Novosibirsk region',
    city: 'Tatarsk',
  },
  {
    region: 'Novosibirsk region',
    city: 'Toguchin',
  },
  {
    region: 'Novosibirsk region',
    city: 'Ubinskoye',
  },
  {
    region: 'Novosibirsk region',
    city: 'Ust-Tarka',
  },
  {
    region: 'Novosibirsk region',
    city: 'Vats',
  },
  {
    region: 'Novosibirsk region',
    city: 'Cherepanovo',
  },
  {
    region: 'Novosibirsk region',
    city: 'Chistoozernoye',
  },
  {
    region: 'Novosibirsk region',
    city: 'Chulym',
  },
  {
    region: 'Omsk region',
    city: 'Beregovoy',
  },
  {
    region: 'Omsk region',
    city: 'Bolsherechye',
  },
  {
    region: 'Omsk region',
    city: 'Big Wooki',
  },
  {
    region: 'Omsk region',
    city: 'Gorkovskoye',
  },
  {
    region: 'Omsk region',
    city: 'Znamenskoye',
  },
  {
    region: 'Omsk region',
    city: 'Isilkul',
  },
  {
    region: 'Omsk region',
    city: 'Kalachinsk',
  },
  {
    region: 'Omsk region',
    city: 'Kolosovka',
  },
  {
    region: 'Omsk region',
    city: 'Kormilovka',
  },
  {
    region: 'Omsk region',
    city: 'Krutinka',
  },
  {
    region: 'Omsk region',
    city: 'Lyubinsky',
  },
  {
    region: 'Omsk region',
    city: 'Maryanovka',
  },
  {
    region: 'Omsk region',
    city: 'Muromtsevo',
  },
  {
    region: 'Omsk region',
    city: 'Nazvaevsk',
  },
  {
    region: 'Omsk region',
    city: 'Nizhnyaya Omka',
  },
  {
    region: 'Omsk region',
    city: 'Novovarshavka',
  },
  {
    region: 'Omsk region',
    city: 'Odessa',
  },
  {
    region: 'Omsk region',
    city: 'Okoneshnikovo',
  },
  {
    region: 'Omsk region',
    city: 'Omsk',
  },
  {
    region: 'Omsk region',
    city: 'Pavlogradka',
  },
  {
    region: 'Omsk region',
    city: 'Poltavka',
  },
  {
    region: 'Omsk region',
    city: 'Russkaya Polyana',
  },
  {
    region: 'Omsk region',
    city: 'Sargatskoe',
  },
  {
    region: 'Omsk region',
    city: 'Sedelnikovo',
  },
  {
    region: 'Omsk region',
    city: 'Tauride',
  },
  {
    region: 'Omsk region',
    city: 'Tara',
  },
  {
    region: 'Omsk region',
    city: 'Tebriz',
  },
  {
    region: 'Omsk region',
    city: 'Tyukalinsk',
  },
  {
    region: 'Omsk region',
    city: 'Ust-Ishim',
  },
  {
    region: 'Omsk region',
    city: 'Cherlak',
  },
  {
    region: 'Omsk region',
    city: 'Sherbakul',
  },
  {
    region: 'Orenburg region',
    city: 'Abdulino',
  },
  {
    region: 'Orenburg region',
    city: 'Adamovka',
  },
  {
    region: 'Orenburg region',
    city: 'Aydyrlinsky',
  },
  {
    region: 'Orenburg region',
    city: 'Akbulak',
  },
  {
    region: 'Orenburg region',
    city: 'Akkermanovka',
  },
  {
    region: 'Orenburg region',
    city: 'Asekeyevo',
  },
  {
    region: 'Orenburg region',
    city: 'Belyaevka',
  },
  {
    region: 'Orenburg region',
    city: 'Buguruslan',
  },
  {
    region: 'Orenburg region',
    city: 'Buzuluk',
  },
  {
    region: 'Orenburg region',
    city: 'Guy',
  },
  {
    region: 'Orenburg region',
    city: 'Grachevka',
  },
  {
    region: 'Orenburg region',
    city: 'Dombarovsky',
  },
  {
    region: 'Orenburg region',
    city: 'Dubensky',
  },
  {
    region: 'Orenburg region',
    city: 'Ilek',
  },
  {
    region: 'Orenburg region',
    city: 'Iriklinsky',
  },
  {
    region: 'Orenburg region',
    city: 'Kuvandyk',
  },
  {
    region: 'Orenburg region',
    city: 'Kurmanaevka',
  },
  {
    region: 'Orenburg region',
    city: 'Matveevka',
  },
  {
    region: 'Orenburg region',
    city: 'Mednogorsk',
  },
  {
    region: 'Orenburg region',
    city: 'Novoorsk',
  },
  {
    region: 'Orenburg region',
    city: 'Novosergievka',
  },
  {
    region: 'Orenburg region',
    city: 'Novotroitsk',
  },
  {
    region: 'Orenburg region',
    city: 'Oktyabrskoye',
  },
  {
    region: 'Orenburg region',
    city: 'Orenburg',
  },
  {
    region: 'Orenburg region',
    city: 'Orsk',
  },
  {
    region: 'Orenburg region',
    city: 'Pervomaisky',
  },
  {
    region: 'Orenburg region',
    city: 'Perevolotsky',
  },
  {
    region: 'Orenburg region',
    city: 'Ponomarevka',
  },
  {
    region: 'Orenburg region',
    city: 'Saraktash',
  },
  {
    region: 'Orenburg region',
    city: 'Light',
  },
  {
    region: 'Orenburg region',
    city: 'Northern',
  },
  {
    region: 'Orenburg region',
    city: 'Sol-Iletsk',
  },
  {
    region: 'Orenburg region',
    city: 'Sorochinsk',
  },
  {
    region: 'Orenburg region',
    city: 'Tashla',
  },
  {
    region: 'Orenburg region',
    city: 'Totskoe',
  },
  {
    region: 'Orenburg region',
    city: 'Tulgan',
  },
  {
    region: 'Orenburg region',
    city: 'Sharlyk',
  },
  {
    region: 'Orenburg region',
    city: 'Energetik',
  },
  {
    region: 'Orenburg region',
    city: 'Clear',
  },
  {
    region: 'Oryol region',
    city: 'Bolkhov',
  },
  {
    region: 'Oryol region',
    city: 'Verkhovye',
  },
  {
    region: 'Oryol region',
    city: 'Glazunovka',
  },
  {
    region: 'Oryol region',
    city: 'Dmitrovsk-Orlovsky',
  },
  {
    region: 'Oryol region',
    city: 'Long',
  },
  {
    region: 'Oryol region',
    city: 'Zalegoshch',
  },
  {
    region: 'Oryol region',
    city: 'Zmievka',
  },
  {
    region: 'Oryol region',
    city: 'Znamenskoye',
  },
  {
    region: 'Oryol region',
    city: 'Kolpny',
  },
  {
    region: 'Oryol region',
    city: 'Krasnaya Zarya',
  },
  {
    region: 'Oryol region',
    city: 'Kromy',
  },
  {
    region: 'Oryol region',
    city: 'Livny',
  },
  {
    region: 'Oryol region',
    city: 'Maloarkhangelsk',
  },
  {
    region: 'Oryol region',
    city: 'Mtsensk',
  },
  {
    region: 'Oryol region',
    city: 'Naryshkino',
  },
  {
    region: 'Oryol region',
    city: 'Novosil',
  },
  {
    region: 'Oryol region',
    city: 'Eagle',
  },
  {
    region: 'Oryol region',
    city: 'Pokrovskoye',
  },
  {
    region: 'Oryol region',
    city: 'Soskovo',
  },
  {
    region: 'Oryol region',
    city: 'Trosna',
  },
  {
    region: 'Oryol region',
    city: 'Khomutovo',
  },
  {
    region: 'Oryol region',
    city: 'Khotynets',
  },
  {
    region: 'Oryol region',
    city: 'Shablykino',
  },
  {
    region: 'Penza region',
    city: 'Bashmakovo',
  },
  {
    region: 'Penza region',
    city: 'Bednodemyanovsk',
  },
  {
    region: 'Penza region',
    city: 'Bekovo',
  },
  {
    region: 'Penza region',
    city: 'Belinsky',
  },
  {
    region: 'Penza region',
    city: 'Bessonovka',
  },
  {
    region: 'Penza region',
    city: 'Vadinsk',
  },
  {
    region: 'Penza region',
    city: 'Verkhozim',
  },
  {
    region: 'Penza region',
    city: 'Settlement',
  },
  {
    region: 'Penza region',
    city: 'Yevlashevo',
  },
  {
    region: 'Penza region',
    city: 'Zemetchino',
  },
  {
    region: 'Penza region',
    city: 'Zolotarevka',
  },
  {
    region: 'Penza region',
    city: 'Issa',
  },
  {
    region: 'Penza region',
    city: 'Kamenka',
  },
  {
    region: 'Penza region',
    city: 'Kolyshley',
  },
  {
    region: 'Penza region',
    city: 'Condolle',
  },
  {
    region: 'Penza region',
    city: 'Kuznetsk',
  },
  {
    region: 'Penza region',
    city: 'Lopatino',
  },
  {
    region: 'Penza region',
    city: 'Malaya Serdoba',
  },
  {
    region: 'Penza region',
    city: 'Mokshan',
  },
  {
    region: 'Penza region',
    city: 'Narovchat',
  },
  {
    region: 'Penza region',
    city: 'Neverkino',
  },
  {
    region: 'Penza region',
    city: 'Nizhny Lomov',
  },
  {
    region: 'Penza region',
    city: 'Nikolsk',
  },
  {
    region: 'Penza region',
    city: 'Pacelma',
  },
  {
    region: 'Penza region',
    city: 'Penza',
  },
  {
    region: 'Penza region',
    city: 'Russian Kameshkir',
  },
  {
    region: 'Penza region',
    city: 'Serdobsk',
  },
  {
    region: 'Penza region',
    city: 'Sosnovoborsk',
  },
  {
    region: 'Penza region',
    city: 'Sura',
  },
  {
    region: 'Penza region',
    city: 'Tamala',
  },
  {
    region: 'Penza region',
    city: 'Shemysheika',
  },
  {
    region: 'Perm region',
    city: 'Barda',
  },
  {
    region: 'Perm region',
    city: 'Berezniki',
  },
  {
    region: 'Perm region',
    city: 'Big Sosnova',
  },
  {
    region: 'Perm region',
    city: 'Vereshchagino',
  },
  {
    region: 'Perm region',
    city: 'Gains',
  },
  {
    region: 'Perm region',
    city: 'Gornozavodsk',
  },
  {
    region: 'Perm region',
    city: 'Gremyachinsk',
  },
  {
    region: 'Perm region',
    city: 'Gubakha',
  },
  {
    region: 'Perm region',
    city: 'Dobryanka',
  },
  {
    region: 'Perm region',
    city: 'Elovo',
  },
  {
    region: 'Perm region',
    city: 'Zūkaika',
  },
  {
    region: 'Perm region',
    city: 'Ilyinsky',
  },
  {
    region: 'Perm region',
    city: 'Karagai',
  },
  {
    region: 'Perm region',
    city: 'Kerchevsky',
  },
  {
    region: 'Perm region',
    city: 'Kiesel',
  },
  {
    region: 'Perm region',
    city: 'Scythe',
  },
  {
    region: 'Perm region',
    city: 'Kochevo',
  },
  {
    region: 'Perm region',
    city: 'Krasnovishersk',
  },
  {
    region: 'Perm region',
    city: 'Krasnokamsk',
  },
  {
    region: 'Perm region',
    city: 'Kudymkar',
  },
  {
    region: 'Perm region',
    city: 'Kueda',
  },
  {
    region: 'Perm region',
    city: 'Kungur',
  },
  {
    region: 'Perm region',
    city: 'Lysva',
  },
  {
    region: 'Perm region',
    city: 'Nyrob',
  },
  {
    region: 'Perm region',
    city: 'Nytva',
  },
  {
    region: 'Perm region',
    city: 'Oktyabrsky',
  },
  {
    region: 'Perm region',
    city: 'Horde',
  },
  {
    region: 'Perm region',
    city: 'Wasp',
  },
  {
    region: 'Perm region',
    city: 'Okhansk',
  },
  {
    region: 'Perm region',
    city: 'Ocher',
  },
  {
    region: 'Perm region',
    city: 'Perm',
  },
  {
    region: 'Perm region',
    city: 'Solikamsk',
  },
  {
    region: 'Perm region',
    city: 'Suksun',
  },
  {
    region: 'Perm region',
    city: 'Winskoe',
  },
  {
    region: 'Perm region',
    city: 'Usolye',
  },
  {
    region: 'Perm region',
    city: 'Ust-Kishert',
  },
  {
    region: 'Perm region',
    city: 'Tchaikovsky',
  },
  {
    region: 'Perm region',
    city: 'Frequent',
  },
  {
    region: 'Perm region',
    city: 'Cherdyn',
  },
  {
    region: 'Perm region',
    city: 'Chernorechensky',
  },
  {
    region: 'Perm region',
    city: 'Chernushka',
  },
  {
    region: 'Perm region',
    city: 'Chusovoy',
  },
  {
    region: 'Perm region',
    city: 'Yurla',
  },
  {
    region: 'Perm region',
    city: 'Yusva',
  },
  {
    region: 'Primorsky Krai',
    city: 'Anuccino',
  },
  {
    region: 'Primorsky Krai',
    city: 'Arsenyev',
  },
  {
    region: 'Primorsky Krai',
    city: 'Artem',
  },
  {
    region: 'Primorsky Krai',
    city: 'Artemovsky',
  },
  {
    region: 'Primorsky Krai',
    city: 'Big Stone',
  },
  {
    region: 'Primorsky Krai',
    city: 'Valentine',
  },
  {
    region: 'Primorsky Krai',
    city: 'Vladivostok',
  },
  {
    region: 'Primorsky Krai',
    city: 'Vysokogorsk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Mountain Keys',
  },
  {
    region: 'Primorsky Krai',
    city: 'Mountain',
  },
  {
    region: 'Primorsky Krai',
    city: 'Dalnegorsk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Dalnerechensk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Zarubino',
  },
  {
    region: 'Primorsky Krai',
    city: 'Kavalerovo',
  },
  {
    region: 'Primorsky Krai',
    city: 'Kamenka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Stone-Fisherman',
  },
  {
    region: 'Primorsky Krai',
    city: 'Kirovsky',
  },
  {
    region: 'Primorsky Krai',
    city: 'Lazo',
  },
  {
    region: 'Primorsky Krai',
    city: 'Lesozavodsk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Luchegorsk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Mikhailovka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Nakhodka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Novopokrovka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Olga',
  },
  {
    region: 'Primorsky Krai',
    city: 'Partizansk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Borderline',
  },
  {
    region: 'Primorsky Krai',
    city: 'Pokrovka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Russian',
  },
  {
    region: 'Primorsky Krai',
    city: 'Samarga',
  },
  {
    region: 'Primorsky Krai',
    city: 'Slavyanka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Spassk-Dalny',
  },
  {
    region: 'Primorsky Krai',
    city: 'Ternei',
  },
  {
    region: 'Primorsky Krai',
    city: 'Ussuriysk',
  },
  {
    region: 'Primorsky Krai',
    city: 'Fokino',
  },
  {
    region: 'Primorsky Krai',
    city: 'Hassan',
  },
  {
    region: 'Primorsky Krai',
    city: 'Khorol',
  },
  {
    region: 'Primorsky Krai',
    city: 'Chernihivka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Chuguevka',
  },
  {
    region: 'Primorsky Krai',
    city: 'Yakovlevka',
  },
  {
    region: 'Pskov region',
    city: 'Bezhanitsy',
  },
  {
    region: 'Pskov region',
    city: 'Velikiye Luki',
  },
  {
    region: 'Pskov region',
    city: 'Gdov',
  },
  {
    region: 'Pskov region',
    city: 'Dedovichi',
  },
  {
    region: 'Pskov region',
    city: 'Bottom',
  },
  {
    region: 'Pskov region',
    city: 'Zaplusye',
  },
  {
    region: 'Pskov region',
    city: 'Idritsa',
  },
  {
    region: 'Pskov region',
    city: 'Krasnogorodskoye',
  },
  {
    region: 'Pskov region',
    city: 'Cunha',
  },
  {
    region: 'Pskov region',
    city: 'Loknya',
  },
  {
    region: 'Pskov region',
    city: 'Nevel',
  },
  {
    region: 'Pskov region',
    city: 'Novorzhev',
  },
  {
    region: 'Pskov region',
    city: 'Novosokolniki',
  },
  {
    region: 'Pskov region',
    city: 'Opochka',
  },
  {
    region: 'Pskov region',
    city: 'Island',
  },
  {
    region: 'Pskov region',
    city: 'Palkino',
  },
  {
    region: 'Pskov region',
    city: 'Pechora',
  },
  {
    region: 'Pskov region',
    city: 'Plussa',
  },
  {
    region: 'Pskov region',
    city: 'Porkhov',
  },
  {
    region: 'Pskov region',
    city: 'Pskov',
  },
  {
    region: 'Pskov region',
    city: 'Wasteland',
  },
  {
    region: 'Pskov region',
    city: 'Pushkin Hills',
  },
  {
    region: 'Pskov region',
    city: 'Pytalovo',
  },
  {
    region: 'Pskov region',
    city: 'Sebezh',
  },
  {
    region: 'Pskov region',
    city: 'Struga-Krasnye',
  },
  {
    region: 'Pskov region',
    city: 'Usvyaty',
  },
  {
    region: 'Rostov region',
    city: 'Azov',
  },
  {
    region: 'Rostov region',
    city: 'Aksai',
  },
  {
    region: 'Rostov region',
    city: 'Diamond',
  },
  {
    region: 'Rostov region',
    city: 'Ayutinsk',
  },
  {
    region: 'Rostov region',
    city: 'Bagaevsky',
  },
  {
    region: 'Rostov region',
    city: 'Bataysk',
  },
  {
    region: 'Rostov region',
    city: 'Belaya Kalitva',
  },
  {
    region: 'Rostov region',
    city: 'Bokovskaya',
  },
  {
    region: 'Rostov region',
    city: 'Bolshaya Martynovka',
  },
  {
    region: 'Rostov region',
    city: 'Veshenskaya',
  },
  {
    region: 'Rostov region',
    city: 'Volgodonsk',
  },
  {
    region: 'Rostov region',
    city: 'Sunrise',
  },
  {
    region: 'Rostov region',
    city: 'Giant',
  },
  {
    region: 'Rostov region',
    city: 'Miner',
  },
  {
    region: 'Rostov region',
    city: 'Gukovo',
  },
  {
    region: 'Rostov region',
    city: 'Donetsk',
  },
  {
    region: 'Rostov region',
    city: 'Donskoy',
  },
  {
    region: 'Rostov region',
    city: 'Dubovskoye',
  },
  {
    region: 'Rostov region',
    city: 'Yegorlykskaya',
  },
  {
    region: 'Rostov region',
    city: 'Zhirnov',
  },
  {
    region: 'Rostov region',
    city: 'Cherished',
  },
  {
    region: 'Rostov region',
    city: 'Factory',
  },
  {
    region: 'Rostov region',
    city: 'Zverevo',
  },
  {
    region: 'Rostov region',
    city: 'Zernograd',
  },
  {
    region: 'Rostov region',
    city: 'Zimovniki',
  },
  {
    region: 'Rostov region',
    city: 'Kagalnitskaya',
  },
  {
    region: 'Rostov region',
    city: 'Kazanskaya',
  },
  {
    region: 'Rostov region',
    city: 'Quarries',
  },
  {
    region: 'Rostov region',
    city: 'Kamensk-Shakhtinsky',
  },
  {
    region: 'Rostov region',
    city: 'Kashary',
  },
  {
    region: 'Rostov region',
    city: 'Coke',
  },
  {
    region: 'Rostov region',
    city: 'Konstantinovsk',
  },
  {
    region: 'Rostov region',
    city: 'Krasny Sulin',
  },
  {
    region: 'Rostov region',
    city: 'Kuibyshevo',
  },
  {
    region: 'Rostov region',
    city: 'Matveev Kurgan',
  },
  {
    region: 'Rostov region',
    city: 'Migulinskaya',
  },
  {
    region: 'Rostov region',
    city: 'Millerovo',
  },
  {
    region: 'Rostov region',
    city: 'Milyutinskaya',
  },
  {
    region: 'Rostov region',
    city: 'Morozovsk',
  },
  {
    region: 'Rostov region',
    city: 'Novocherkassk',
  },
  {
    region: 'Rostov region',
    city: 'Novoshakhtinsk',
  },
  {
    region: 'Rostov region',
    city: 'Oblivskaya',
  },
  {
    region: 'Rostov region',
    city: 'Orlovsky',
  },
  {
    region: 'Rostov region',
    city: 'Peschanokopskoye',
  },
  {
    region: 'Rostov region',
    city: 'Pokrovskoye',
  },
  {
    region: 'Rostov region',
    city: 'Proletarsk',
  },
  {
    region: 'Rostov region',
    city: 'Repair',
  },
  {
    region: 'Rostov region',
    city: 'Rodionovo-Nesvetayskaya',
  },
  {
    region: 'Rostov region',
    city: 'Rostov-on-Don',
  },
  {
    region: 'Rostov region',
    city: 'Salsk',
  },
  {
    region: 'Rostov region',
    city: 'Semikarakorsk',
  },
  {
    region: 'Rostov region',
    city: 'Taganrog',
  },
  {
    region: 'Rostov region',
    city: 'Tarasovsky',
  },
  {
    region: 'Rostov region',
    city: 'Tatsinsky',
  },
  {
    region: 'Rostov region',
    city: 'Ust-Donetsk',
  },
  {
    region: 'Rostov region',
    city: 'Virgin land',
  },
  {
    region: 'Rostov region',
    city: 'Tsimlyansk',
  },
  {
    region: 'Rostov region',
    city: 'Chaltyr',
  },
  {
    region: 'Rostov region',
    city: 'Chertkovo',
  },
  {
    region: 'Rostov region',
    city: 'Mines',
  },
  {
    region: 'Rostov region',
    city: 'Sholokhovskiy',
  },
  {
    region: 'Ryazan region',
    city: 'Alexander Nevsky',
  },
  {
    region: 'Ryazan region',
    city: 'Miner',
  },
  {
    region: 'Ryazan region',
    city: 'Iron Goose',
  },
  {
    region: 'Ryazan region',
    city: 'Elatma',
  },
  {
    region: 'Ryazan region',
    city: 'Ermish',
  },
  {
    region: 'Ryazan region',
    city: 'Zarechny',
  },
  {
    region: 'Ryazan region',
    city: 'Zakharovo',
  },
  {
    region: 'Ryazan region',
    city: 'Kadom',
  },
  {
    region: 'Ryazan region',
    city: 'Kasimov',
  },
  {
    region: 'Ryazan region',
    city: 'Korablino',
  },
  {
    region: 'Ryazan region',
    city: 'Miloslavskoye',
  },
  {
    region: 'Ryazan region',
    city: 'Mikhailov',
  },
  {
    region: 'Ryazan region',
    city: 'Pitelino',
  },
  {
    region: 'Ryazan region',
    city: 'Pronsk',
  },
  {
    region: 'Ryazan region',
    city: 'Putyatino',
  },
  {
    region: 'Ryazan region',
    city: 'Fish',
  },
  {
    region: 'Ryazan region',
    city: 'Ryazhsk',
  },
  {
    region: 'Ryazan region',
    city: 'Ryazan',
  },
  {
    region: 'Ryazan region',
    city: 'Boot',
  },
  {
    region: 'Ryazan region',
    city: 'Sheds',
  },
  {
    region: 'Ryazan region',
    city: 'Sasovo',
  },
  {
    region: 'Ryazan region',
    city: 'Skopin',
  },
  {
    region: 'Ryazan region',
    city: 'Spas-Klepiki',
  },
  {
    region: 'Ryazan region',
    city: 'Spassk-Ryazansky',
  },
  {
    region: 'Ryazan region',
    city: 'Starozhilovo',
  },
  {
    region: 'Ryazan region',
    city: 'Ukholovo',
  },
  {
    region: 'Ryazan region',
    city: 'Chuchkovo',
  },
  {
    region: 'Ryazan region',
    city: 'Shatsk',
  },
  {
    region: 'Ryazan region',
    city: 'Shilovo',
  },
  {
    region: 'Samara region',
    city: 'Alekseevka',
  },
  {
    region: 'Samara region',
    city: 'Bezenchuk',
  },
  {
    region: 'Samara region',
    city: 'Rich',
  },
  {
    region: 'Samara region',
    city: 'Bogatyr',
  },
  {
    region: 'Samara region',
    city: 'Big Glushchitsya',
  },
  {
    region: 'Samara region',
    city: 'Bolshaya Chernigovka',
  },
  {
    region: 'Samara region',
    city: 'Borskoye',
  },
  {
    region: 'Samara region',
    city: 'Volzhsky',
  },
  {
    region: 'Samara region',
    city: 'Zhigulevsk',
  },
  {
    region: 'Samara region',
    city: 'Zolnoe',
  },
  {
    region: 'Samara region',
    city: 'Isakly',
  },
  {
    region: 'Samara region',
    city: 'Kamyshla',
  },
  {
    region: 'Samara region',
    city: 'Kinel',
  },
  {
    region: 'Samara region',
    city: 'Kinel-Cherkasy',
  },
  {
    region: 'Samara region',
    city: 'Klyavlino',
  },
  {
    region: 'Samara region',
    city: 'Cats',
  },
  {
    region: 'Samara region',
    city: 'Krasnoarmeyskoye',
  },
  {
    region: 'Samara region',
    city: 'Krasny Yar',
  },
  {
    region: 'Samara region',
    city: 'Kuibyshev',
  },
  {
    region: 'Samara region',
    city: 'Neftegorsk',
  },
  {
    region: 'Samara region',
    city: 'Novokuibyshevsk',
  },
  {
    region: 'Samara region',
    city: 'Oktyabrsk',
  },
  {
    region: 'Samara region',
    city: 'Gratifying',
  },
  {
    region: 'Samara region',
    city: 'Pestravka',
  },
  {
    region: 'Samara region',
    city: 'Pokhvistnevo',
  },
  {
    region: 'Samara region',
    city: 'Volga region',
  },
  {
    region: 'Samara region',
    city: 'Samara',
  },
  {
    region: 'Samara region',
    city: 'Surgut (Samara region)',
  },
  {
    region: 'Samara region',
    city: 'Syzran',
  },
  {
    region: 'Samara region',
    city: 'Togliatti',
  },
  {
    region: 'Samara region',
    city: 'Khvorostyanka',
  },
  {
    region: 'Samara region',
    city: 'Chapayevsk',
  },
  {
    region: 'Samara region',
    city: 'Chelno-Vershiny',
  },
  {
    region: 'Samara region',
    city: 'Shentala',
  },
  {
    region: 'Samara region',
    city: 'Shigons',
  },
  {
    region: 'Saratov region',
    city: 'Alexandrov Guy',
  },
  {
    region: 'Saratov region',
    city: 'Arkadak',
  },
  {
    region: 'Saratov region',
    city: 'Atkarsk',
  },
  {
    region: 'Saratov region',
    city: 'Bazaar Karabulak',
  },
  {
    region: 'Saratov region',
    city: 'Balakovo',
  },
  {
    region: 'Saratov region',
    city: 'Balashov',
  },
  {
    region: 'Saratov region',
    city: 'Baltai',
  },
  {
    region: 'Saratov region',
    city: 'Revival',
  },
  {
    region: 'Saratov region',
    city: 'Volsk',
  },
  {
    region: 'Saratov region',
    city: 'Voskresenskoye',
  },
  {
    region: 'Saratov region',
    city: 'Mountain',
  },
  {
    region: 'Saratov region',
    city: 'Dergachi',
  },
  {
    region: 'Saratov region',
    city: 'Dukhovnitskoe',
  },
  {
    region: 'Saratov region',
    city: 'Yekaterinovka',
  },
  {
    region: 'Saratov region',
    city: 'Ershov',
  },
  {
    region: 'Saratov region',
    city: 'Zarechny',
  },
  {
    region: 'Saratov region',
    city: 'Ivanteevka',
  },
  {
    region: 'Saratov region',
    city: 'Kalininsk',
  },
  {
    region: 'Saratov region',
    city: 'Kamensky',
  },
  {
    region: 'Saratov region',
    city: 'Krasnoarmeysk',
  },
  {
    region: 'Saratov region',
    city: 'Krasny Kut',
  },
  {
    region: 'Saratov region',
    city: 'Bald Mountains',
  },
  {
    region: 'Saratov region',
    city: 'Marx',
  },
  {
    region: 'Saratov region',
    city: 'Mokrous',
  },
  {
    region: 'Saratov region',
    city: 'Novouzensk',
  },
  {
    region: 'Saratov region',
    city: 'New Burasy',
  },
  {
    region: 'Saratov region',
    city: 'Ozinki',
  },
  {
    region: 'Saratov region',
    city: 'Perelyub',
  },
  {
    region: 'Saratov region',
    city: 'Petrovsk',
  },
  {
    region: 'Saratov region',
    city: 'Piterka',
  },
  {
    region: 'Saratov region',
    city: 'Pugachev',
  },
  {
    region: 'Saratov region',
    city: 'Smooth',
  },
  {
    region: 'Saratov region',
    city: 'Romanovka',
  },
  {
    region: 'Saratov region',
    city: 'Rtishchevo',
  },
  {
    region: 'Saratov region',
    city: 'Samoilovka',
  },
  {
    region: 'Saratov region',
    city: 'Saratov',
  },
  {
    region: 'Saratov region',
    city: 'Stepnoe',
  },
  {
    region: 'Saratov region',
    city: 'Tatishchevo',
  },
  {
    region: 'Saratov region',
    city: 'Turks',
  },
  {
    region: 'Saratov region',
    city: 'Khvalynsk',
  },
  {
    region: 'Saratov region',
    city: 'Engels',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Aby',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Aldan',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Amga',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Batagai',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Berdigestyakh',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Berkakit',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Bestyakh',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Borogontsy',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Verkhnevilyuysk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Verkhnekolymsk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Verkhoyansk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Vilyuysk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Vitim',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Vlasovo',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Zhigansk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Zyryanka',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Kangalassy',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Cancun',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Lensk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Maya',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Menkerya',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Peaceful',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Neryungri',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Nychalakh',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Nyurba',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Olekminsk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Pokrovsk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Sangar',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Saskylah',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Srednekolymsk',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Suntar',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Tiksi',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Ust-Maya',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Ust-Nera',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Khandyga',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Honuu',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Chersky',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Chokurdakh',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Churapcha',
  },
  {
    region: 'Sakha (Yakutia)',
    city: 'Yakutsk',
  },
  {
    region: 'Sakhalin',
    city: 'Aleksandrovsk-Sakhalinsky',
  },
  {
    region: 'Sakhalin',
    city: 'Anbetsu',
  },
  {
    region: 'Sakhalin',
    city: 'Aniva',
  },
  {
    region: 'Sakhalin',
    city: 'Boshnyakovo',
  },
  {
    region: 'Sakhalin',
    city: 'Bykov',
  },
  {
    region: 'Sakhalin',
    city: 'Vakhrushev',
  },
  {
    region: 'Sakhalin',
    city: 'Seaside',
  },
  {
    region: 'Sakhalin',
    city: 'Gastello',
  },
  {
    region: 'Sakhalin',
    city: 'Gornozavodsk',
  },
  {
    region: 'Sakhalin',
    city: 'Dolinsk',
  },
  {
    region: 'Sakhalin',
    city: 'Ilyinsky',
  },
  {
    region: 'Sakhalin',
    city: 'Katangli',
  },
  {
    region: 'Sakhalin',
    city: 'Korsakov',
  },
  {
    region: 'Sakhalin',
    city: 'Kurilsk',
  },
  {
    region: 'Sakhalin',
    city: 'Makarov',
  },
  {
    region: 'Sakhalin',
    city: 'Nevelsk',
  },
  {
    region: 'Sakhalin',
    city: 'Nogliki',
  },
  {
    region: 'Sakhalin',
    city: 'Oha',
  },
  {
    region: 'Sakhalin',
    city: 'Poronaysk',
  },
  {
    region: 'Sakhalin',
    city: 'Severo-Kurilsk',
  },
  {
    region: 'Sakhalin',
    city: 'Smirnykh',
  },
  {
    region: 'Sakhalin',
    city: 'Tomari',
  },
  {
    region: 'Sakhalin',
    city: 'Tymovskoe',
  },
  {
    region: 'Sakhalin',
    city: 'Uglegorsk',
  },
  {
    region: 'Sakhalin',
    city: 'Kholmsk',
  },
  {
    region: 'Sakhalin',
    city: 'Shakhtersk',
  },
  {
    region: 'Sakhalin',
    city: 'Yuzhno-Kurilsk',
  },
  {
    region: 'Sakhalin',
    city: 'Yuzhno-Sakhalinsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Alapaevsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Altynay',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Aramil',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Artemovsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Artie',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Asbestos',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Achit',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Baikalovo',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Basyanovsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Beloyarsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Berezovsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Bogdanovich',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Bulanash',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhny Tagil',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhnyaya Pyshma',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhnyaya Salda',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhnyaya Sinyachikha',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhnyaya Sysert',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhnyaya Tura',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Verkhoturye',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Hanging',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Vovchansk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Vorontsovka',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Gary',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Degtyarsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Ekaterinburg',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Ertarsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Zavodouspenskoe',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Zyryanovsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Zyuzelsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Ivdel',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Emerald',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Irbit',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Is',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Kamensk-Uralsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Kamyshlov',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Karpinsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Karpuninsky',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Kachkanar',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Kirovgrad',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Krasnoturinsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Krasnouralsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Krasnoufimsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Kushva',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Forest',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Mikhailovsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Nevyansk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Nizhniye Sergi',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Nizhny Tagil',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Nizhnyaya Salda',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Nizhnyaya Tura',
  },
  {
    region: 'Sverdlovsk region',
    city: 'New Lyalya',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Novouralsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Ous',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Pervouralsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Polevskoy',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Pyshma',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Revda',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Dir',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Sverdlovsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Severouralsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Serov',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Sosva',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Sredneuralsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Sukhoi Log',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Sysert',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Camps',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Tavda',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Talitsa',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Tugulym',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Turinsk',
  },
  {
    region: 'Sverdlovsk region',
    city: 'Turin Sloboda',
  },
  {
    region: 'North Ossetia',
    city: 'Alagir',
  },
  {
    region: 'North Ossetia',
    city: 'Ardon',
  },
  {
    region: 'North Ossetia',
    city: 'Beslan',
  },
  {
    region: 'North Ossetia',
    city: 'Buron',
  },
  {
    region: 'North Ossetia',
    city: 'Vladikavkaz',
  },
  {
    region: 'North Ossetia',
    city: 'Digora',
  },
  {
    region: 'North Ossetia',
    city: 'Mozdok',
  },
  {
    region: 'North Ossetia',
    city: 'Ordzhonikidze',
  },
  {
    region: 'North Ossetia',
    city: 'Chicola',
  },
  {
    region: 'Smolensk region',
    city: 'Velizh',
  },
  {
    region: 'Smolensk region',
    city: 'Verkhnedneprovsky',
  },
  {
    region: 'Smolensk region',
    city: 'Vorga',
  },
  {
    region: 'Smolensk region',
    city: 'Vyazma',
  },
  {
    region: 'Smolensk region',
    city: 'Gagarin',
  },
  {
    region: 'Smolensk region',
    city: 'Glinka',
  },
  {
    region: 'Smolensk region',
    city: 'Golynki',
  },
  {
    region: 'Smolensk region',
    city: 'Demidov',
  },
  {
    region: 'Smolensk region',
    city: 'Desnogorsk',
  },
  {
    region: 'Smolensk region',
    city: 'Dorogobuzh',
  },
  {
    region: 'Smolensk region',
    city: 'Dukhovshchina',
  },
  {
    region: 'Smolensk region',
    city: 'Ekimovichi',
  },
  {
    region: 'Smolensk region',
    city: 'Yelnya',
  },
  {
    region: 'Smolensk region',
    city: 'Yershichi',
  },
  {
    region: 'Smolensk region',
    city: 'Izdeshkovo',
  },
  {
    region: 'Smolensk region',
    city: 'Kardymovo',
  },
  {
    region: 'Smolensk region',
    city: 'Red',
  },
  {
    region: 'Smolensk region',
    city: 'Monastyrshchina',
  },
  {
    region: 'Smolensk region',
    city: 'Novodugino',
  },
  {
    region: 'Smolensk region',
    city: 'Repair',
  },
  {
    region: 'Smolensk region',
    city: 'Roslavl',
  },
  {
    region: 'Smolensk region',
    city: 'Rudnya',
  },
  {
    region: 'Smolensk region',
    city: 'Safonovo',
  },
  {
    region: 'Smolensk region',
    city: 'Smolensk',
  },
  {
    region: 'Smolensk region',
    city: 'Sychevka',
  },
  {
    region: 'Smolensk region',
    city: 'Ugra',
  },
  {
    region: 'Smolensk region',
    city: 'Hislavichi',
  },
  {
    region: 'Smolensk region',
    city: 'Kholm-Zhirkovsky',
  },
  {
    region: 'Smolensk region',
    city: 'Shumyachi',
  },
  {
    region: 'Smolensk region',
    city: 'Yartsevo',
  },
  {
    region: 'Stavropol Territory',
    city: 'Aleksandrovskoye',
  },
  {
    region: 'Stavropol Territory',
    city: 'Arzgir',
  },
  {
    region: 'Stavropol Territory',
    city: 'Grateful',
  },
  {
    region: 'Stavropol Territory',
    city: 'Budyonnovsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Georgievsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Marvelous',
  },
  {
    region: 'Stavropol Territory',
    city: 'Dombai',
  },
  {
    region: 'Stavropol Territory',
    city: 'Donskoye',
  },
  {
    region: 'Stavropol Territory',
    city: 'Essentuki',
  },
  {
    region: 'Stavropol Territory',
    city: 'Zheleznovodsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Zelenokumsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Abundant',
  },
  {
    region: 'Stavropol Territory',
    city: 'Inozemtsevo',
  },
  {
    region: 'Stavropol Territory',
    city: 'Ipatovo',
  },
  {
    region: 'Stavropol Territory',
    city: 'Karachaevsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Kislovodsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Kochubeevskoye',
  },
  {
    region: 'Stavropol Territory',
    city: 'Krasnogvardeyskoye',
  },
  {
    region: 'Stavropol Territory',
    city: 'Kursavka',
  },
  {
    region: 'Stavropol Territory',
    city: 'Levokumskoe',
  },
  {
    region: 'Stavropol Territory',
    city: 'Mineralnye Vody',
  },
  {
    region: 'Stavropol Territory',
    city: 'Nevinnomyssk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Neftekumsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Novoaleksandrovsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Novoaleksandrovskaya',
  },
  {
    region: 'Stavropol Territory',
    city: 'Novopavlovsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Novoselitskoe',
  },
  {
    region: 'Stavropol Territory',
    city: 'Barrier',
  },
  {
    region: 'Stavropol Territory',
    city: 'Pyatigorsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Svetlograd',
  },
  {
    region: 'Stavropol Territory',
    city: 'Solnechnodolsk',
  },
  {
    region: 'Stavropol Territory',
    city: 'Stavropol',
  },
  {
    region: 'Stavropol Territory',
    city: 'Stepnoe',
  },
  {
    region: 'Stavropol Territory',
    city: 'Teberda',
  },
  {
    region: 'Stavropol Territory',
    city: 'Ust-Dzheguta',
  },
  {
    region: 'Stavropol Territory',
    city: 'Habez',
  },
  {
    region: 'Stavropol Territory',
    city: 'Cherkessk',
  },
  {
    region: 'Tambov region',
    city: 'Coopers',
  },
  {
    region: 'Tambov region',
    city: 'Gavrilovka Vtoraya',
  },
  {
    region: 'Tambov region',
    city: 'Zherdevka',
  },
  {
    region: 'Tambov region',
    city: 'Znamenka',
  },
  {
    region: 'Tambov region',
    city: 'Inzhavino',
  },
  {
    region: 'Tambov region',
    city: 'Kirsanov',
  },
  {
    region: 'Tambov region',
    city: 'Kotovsk',
  },
  {
    region: 'Tambov region',
    city: 'Michurinsk',
  },
  {
    region: 'Tambov region',
    city: 'Mordovo',
  },
  {
    region: 'Tambov region',
    city: 'Morshansk',
  },
  {
    region: 'Tambov region',
    city: 'Muchkapsky',
  },
  {
    region: 'Tambov region',
    city: 'Pervomaisky',
  },
  {
    region: 'Tambov region',
    city: 'Petrovskoye',
  },
  {
    region: 'Tambov region',
    city: 'Pichaevo',
  },
  {
    region: 'Tambov region',
    city: 'Rasskazovo',
  },
  {
    region: 'Tambov region',
    city: 'Rzhaksa',
  },
  {
    region: 'Tambov region',
    city: 'Staroyurievo',
  },
  {
    region: 'Tambov region',
    city: 'Tambov',
  },
  {
    region: 'Tambov region',
    city: 'Tokarevka',
  },
  {
    region: 'Tambov region',
    city: 'Uvarovo',
  },
  {
    region: 'Tambov region',
    city: 'Umet',
  },
  {
    region: 'Tatarstan',
    city: 'Agryz',
  },
  {
    region: 'Tatarstan',
    city: 'Aznakaevo',
  },
  {
    region: 'Tatarstan',
    city: 'Aksubaevo',
  },
  {
    region: 'Tatarstan',
    city: 'Aktobe',
  },
  {
    region: 'Tatarstan',
    city: 'Alekseevskoe',
  },
  {
    region: 'Tatarstan',
    city: 'Almetyevsk',
  },
  {
    region: 'Tatarstan',
    city: 'Almetyevsk',
  },
  {
    region: 'Tatarstan',
    city: 'Apastovo',
  },
  {
    region: 'Tatarstan',
    city: 'Arsk',
  },
  {
    region: 'Tatarstan',
    city: 'Bavly',
  },
  {
    region: 'Tatarstan',
    city: 'Bazaar Mataki',
  },
  {
    region: 'Tatarstan',
    city: 'Baltasi',
  },
  {
    region: 'Tatarstan',
    city: 'Rich Saba',
  },
  {
    region: 'Tatarstan',
    city: 'Brezhnev',
  },
  {
    region: 'Tatarstan',
    city: 'Bugulma',
  },
  {
    region: 'Tatarstan',
    city: 'Buinsk',
  },
  {
    region: 'Tatarstan',
    city: 'Vasilyevo',
  },
  {
    region: 'Tatarstan',
    city: 'Verkhny Uslon',
  },
  {
    region: 'Tatarstan',
    city: 'High Mountain',
  },
  {
    region: 'Tatarstan',
    city: 'Derbeshkinsky',
  },
  {
    region: 'Tatarstan',
    city: 'Yelabuga',
  },
  {
    region: 'Tatarstan',
    city: 'Zainsk',
  },
  {
    region: 'Tatarstan',
    city: 'Zelenodolsk',
  },
  {
    region: 'Tatarstan',
    city: 'Kazan',
  },
  {
    region: 'Tatarstan',
    city: 'Kamskoye Ustye',
  },
  {
    region: 'Tatarstan',
    city: 'Karabash',
  },
  {
    region: 'Tatarstan',
    city: 'Kuibyshev',
  },
  {
    region: 'Tatarstan',
    city: 'Kukmod',
  },
  {
    region: 'Tatarstan',
    city: 'Cookmore',
  },
  {
    region: 'Tatarstan',
    city: 'Laishevo',
  },
  {
    region: 'Tatarstan',
    city: 'Leninogorsk',
  },
  {
    region: 'Tatarstan',
    city: 'Mamadysh',
  },
  {
    region: 'Tatarstan',
    city: 'Mendeleevsk',
  },
  {
    region: 'Tatarstan',
    city: 'Menzelinsk',
  },
  {
    region: 'Tatarstan',
    city: 'Muslyumovo',
  },
  {
    region: 'Tatarstan',
    city: 'Naberezhnye Chelny',
  },
  {
    region: 'Tatarstan',
    city: 'Nizhnekamsk',
  },
  {
    region: 'Tatarstan',
    city: 'Novosheshminsk',
  },
  {
    region: 'Tatarstan',
    city: 'Nurlat',
  },
  {
    region: 'Tatarstan',
    city: 'Pestretsy',
  },
  {
    region: 'Tatarstan',
    city: 'Rybnaya Sloboda',
  },
  {
    region: 'Tatarstan',
    city: 'Sarmanovo',
  },
  {
    region: 'Tatarstan',
    city: 'Old Yeast',
  },
  {
    region: 'Tatarstan',
    city: 'Aunties',
  },
  {
    region: 'Tatarstan',
    city: 'Chistopol',
  },
  {
    region: 'Tver region',
    city: 'Andreapol',
  },
  {
    region: 'Tver region',
    city: 'Bezhetsk',
  },
  {
    region: 'Tver region',
    city: 'White',
  },
  {
    region: 'Tver region',
    city: 'White Town',
  },
  {
    region: 'Tver region',
    city: 'Berezayka',
  },
  {
    region: 'Tver region',
    city: 'Bologoe',
  },
  {
    region: 'Tver region',
    city: 'Vasilievsky moss',
  },
  {
    region: 'Tver region',
    city: 'Vypolzovo',
  },
  {
    region: 'Tver region',
    city: 'Vyshny Volochek',
  },
  {
    region: 'Tver region',
    city: 'Zharkovsky',
  },
  {
    region: 'Tver region',
    city: 'Western Dvina',
  },
  {
    region: 'Tver region',
    city: 'District',
  },
  {
    region: 'Tver region',
    city: 'Zubtsov',
  },
  {
    region: 'Tver region',
    city: 'Isoplit',
  },
  {
    region: 'Tver region',
    city: 'Kalashnikovo',
  },
  {
    region: 'Tver region',
    city: 'Kalinin',
  },
  {
    region: 'Tver region',
    city: 'Kalyazin',
  },
  {
    region: 'Tver region',
    city: 'Kashin',
  },
  {
    region: 'Tver region',
    city: 'Kesova Gora',
  },
  {
    region: 'Tver region',
    city: 'Kimry',
  },
  {
    region: 'Tver region',
    city: 'Konakovo',
  },
  {
    region: 'Tver region',
    city: 'Red Hill',
  },
  {
    region: 'Tver region',
    city: 'Kuvshinovo',
  },
  {
    region: 'Tver region',
    city: 'Forest',
  },
  {
    region: 'Tver region',
    city: 'Likhoslavl',
  },
  {
    region: 'Tver region',
    city: 'Maksatikha',
  },
  {
    region: 'Tver region',
    city: 'Molokovo',
  },
  {
    region: 'Tver region',
    city: 'Nelidovo',
  },
  {
    region: 'Tver region',
    city: 'Olenino',
  },
  {
    region: 'Tver region',
    city: 'Ostashkov',
  },
  {
    region: 'Tver region',
    city: 'Peno',
  },
  {
    region: 'Tver region',
    city: 'Rameshki',
  },
  {
    region: 'Tver region',
    city: 'Rzhev',
  },
  {
    region: 'Tver region',
    city: 'Sandovo',
  },
  {
    region: 'Tver region',
    city: 'Selizharovo',
  },
  {
    region: 'Tver region',
    city: 'Sonkovo',
  },
  {
    region: 'Tver region',
    city: 'Spirovo',
  },
  {
    region: 'Tver region',
    city: 'Staritsa',
  },
  {
    region: 'Tver region',
    city: 'Tver',
  },
  {
    region: 'Tver region',
    city: 'Torzhok',
  },
  {
    region: 'Tver region',
    city: 'Toropets',
  },
  {
    region: 'Tver region',
    city: 'Udomlya',
  },
  {
    region: 'Tver region',
    city: 'Firovo',
  },
  {
    region: 'Tomsk region',
    city: 'Aleksandrovskoye',
  },
  {
    region: 'Tomsk region',
    city: 'Ashino',
  },
  {
    region: 'Tomsk region',
    city: 'Bakchar',
  },
  {
    region: 'Tomsk region',
    city: 'Baturino',
  },
  {
    region: 'Tomsk region',
    city: 'Bely Yar',
  },
  {
    region: 'Tomsk region',
    city: 'Zyryanskoe',
  },
  {
    region: 'Tomsk region',
    city: 'Itatka',
  },
  {
    region: 'Tomsk region',
    city: 'Kargasok',
  },
  {
    region: 'Tomsk region',
    city: 'Katayga',
  },
  {
    region: 'Tomsk region',
    city: 'Kozhevnikovo',
  },
  {
    region: 'Tomsk region',
    city: 'Kolpashevo',
  },
  {
    region: 'Tomsk region',
    city: 'Krivosheino',
  },
  {
    region: 'Tomsk region',
    city: 'Melnikovo',
  },
  {
    region: 'Tomsk region',
    city: 'Molchanovo',
  },
  {
    region: 'Tomsk region',
    city: 'Parabel',
  },
  {
    region: 'Tomsk region',
    city: 'Pervomaiskoye',
  },
  {
    region: 'Tomsk region',
    city: 'Podgornoye',
  },
  {
    region: 'Tomsk region',
    city: 'Seversk',
  },
  {
    region: 'Tomsk region',
    city: 'Strezhevoy',
  },
  {
    region: 'Tomsk region',
    city: 'Tomsk',
  },
  {
    region: 'Tomsk region',
    city: 'Tymsk',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Ak-Dovurak',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Bai Haak',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Kyzyl',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Samagaltai',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Saryg-Sep',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Essence-Hol',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Toora-Khem',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Turan',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Taeli',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Khovu-Aksy',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Chadan',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Shagonar',
  },
  {
    region: 'Tuva (Tuva Republic)',
    city: 'Erzin',
  },
  {
    region: 'Tula region',
    city: 'Ageevo',
  },
  {
    region: 'Tula region',
    city: 'Aleksin',
  },
  {
    region: 'Tula region',
    city: 'Arsenyevo',
  },
  {
    region: 'Tula region',
    city: 'Badgers',
  },
  {
    region: 'Tula region',
    city: 'Begichevsky',
  },
  {
    region: 'Tula region',
    city: 'Belev',
  },
  {
    region: 'Tula region',
    city: 'Bogoroditsk',
  },
  {
    region: 'Tula region',
    city: 'Bolokhovo',
  },
  {
    region: 'Tula region',
    city: 'Velegozh',
  },
  {
    region: 'Tula region',
    city: 'Venev',
  },
  {
    region: 'Tula region',
    city: 'Volovo',
  },
  {
    region: 'Tula region',
    city: 'Burners',
  },
  {
    region: 'Tula region',
    city: 'Donskoy',
  },
  {
    region: 'Tula region',
    city: 'Dubna',
  },
  {
    region: 'Tula region',
    city: 'Epiphany',
  },
  {
    region: 'Tula region',
    city: 'Efremov',
  },
  {
    region: 'Tula region',
    city: 'Zaoksky',
  },
  {
    region: 'Tula region',
    city: 'Kazanovka',
  },
  {
    region: 'Tula region',
    city: 'Kimovsk',
  },
  {
    region: 'Tula region',
    city: 'Kireevsk',
  },
  {
    region: 'Tula region',
    city: 'Kurkino',
  },
  {
    region: 'Tula region',
    city: 'Leninsky',
  },
  {
    region: 'Tula region',
    city: 'Novomoskovsk',
  },
  {
    region: 'Tula region',
    city: 'Odoev',
  },
  {
    region: 'Tula region',
    city: 'Plavsk',
  },
  {
    region: 'Tula region',
    city: 'Suvorov',
  },
  {
    region: 'Tula region',
    city: 'Tula',
  },
  {
    region: 'Tula region',
    city: 'Node',
  },
  {
    region: 'Tula region',
    city: 'Shchekino',
  },
  {
    region: 'Tula region',
    city: 'Yasnogorsk',
  },
  {
    region: 'Tyumen region',
    city: 'Abatsky',
  },
  {
    region: 'Tyumen region',
    city: 'Aksarka',
  },
  {
    region: 'Tyumen region',
    city: 'Armizonskoye',
  },
  {
    region: 'Tyumen region',
    city: 'Aromashevo',
  },
  {
    region: 'Tyumen region',
    city: 'Beloyarsky',
  },
  {
    region: 'Tyumen region',
    city: 'Berdyuzhye',
  },
  {
    region: 'Tyumen region',
    city: 'Bolshoye Sorokino',
  },
  {
    region: 'Tyumen region',
    city: 'Vagay',
  },
  {
    region: 'Tyumen region',
    city: 'Vikulovo',
  },
  {
    region: 'Tyumen region',
    city: 'Vinzili',
  },
  {
    region: 'Tyumen region',
    city: 'Golyshmanovo',
  },
  {
    region: 'Tyumen region',
    city: 'Gubkinsky',
  },
  {
    region: 'Tyumen region',
    city: 'Zavodopetrovskiy',
  },
  {
    region: 'Tyumen region',
    city: 'Zavodoukovsk',
  },
  {
    region: 'Tyumen region',
    city: 'Izluchinsk',
  },
  {
    region: 'Tyumen region',
    city: 'Isetskoye',
  },
  {
    region: 'Tyumen region',
    city: 'Ishim',
  },
  {
    region: 'Tyumen region',
    city: 'Kazanskoye',
  },
  {
    region: 'Tyumen region',
    city: 'Kazym-Mys',
  },
  {
    region: 'Tyumen region',
    city: 'Kogalym',
  },
  {
    region: 'Tyumen region',
    city: 'Kondinskoye',
  },
  {
    region: 'Tyumen region',
    city: 'Krasnoselkup',
  },
  {
    region: 'Tyumen region',
    city: 'Labytnangi',
  },
  {
    region: 'Tyumen region',
    city: 'Laryak',
  },
  {
    region: 'Tyumen region',
    city: 'Megion',
  },
  {
    region: 'Tyumen region',
    city: 'Men',
  },
  {
    region: 'Tyumen region',
    city: 'Muravlenko',
  },
  {
    region: 'Tyumen region',
    city: 'Nadym',
  },
  {
    region: 'Tyumen region',
    city: 'Nakhodka',
  },
  {
    region: 'Tyumen region',
    city: 'Nefteyugansk',
  },
  {
    region: 'Tyumen region',
    city: 'Nizhnevartovsk',
  },
  {
    region: 'Tyumen region',
    city: 'Nizhnyaya Tavda',
  },
  {
    region: 'Tyumen region',
    city: 'Novy Urengoy',
  },
  {
    region: 'Tyumen region',
    city: 'Noyabrsk',
  },
  {
    region: 'Tyumen region',
    city: 'Nyagan',
  },
  {
    region: 'Tyumen region',
    city: 'Oktyabrskoye',
  },
  {
    region: 'Tyumen region',
    city: 'Omutinsky',
  },
  {
    region: 'Tyumen region',
    city: 'Rainbow',
  },
  {
    region: 'Tyumen region',
    city: 'Salekhard',
  },
  {
    region: 'Tyumen region',
    city: 'Sladkovo',
  },
  {
    region: 'Tyumen region',
    city: 'Soviet',
  },
  {
    region: 'Tyumen region',
    city: 'Surgut',
  },
  {
    region: 'Tyumen region',
    city: 'Tazovsky',
  },
  {
    region: 'Tyumen region',
    city: 'Tarko-Sale',
  },
  {
    region: 'Tyumen region',
    city: 'Tobolsk',
  },
  {
    region: 'Tyumen region',
    city: 'Tyumen',
  },
  {
    region: 'Tyumen region',
    city: 'Uvat',
  },
  {
    region: 'Tyumen region',
    city: 'Unyugan',
  },
  {
    region: 'Tyumen region',
    city: 'Uporovo',
  },
  {
    region: 'Tyumen region',
    city: 'Uray',
  },
  {
    region: 'Tyumen region',
    city: 'Khanty-Mansiysk',
  },
  {
    region: 'Tyumen region',
    city: 'Yuribey',
  },
  {
    region: 'Tyumen region',
    city: 'Yalutorovsk',
  },
  {
    region: 'Tyumen region',
    city: 'Yar-Sale',
  },
  {
    region: 'Tyumen region',
    city: 'Yarkovo',
  },
  {
    region: 'Udmurtia',
    city: 'Alnashi',
  },
  {
    region: 'Udmurtia',
    city: 'Balesino',
  },
  {
    region: 'Udmurtia',
    city: 'Vavozh',
  },
  {
    region: 'Udmurtia',
    city: 'Votkinsk',
  },
  {
    region: 'Udmurtia',
    city: 'Glazov',
  },
  {
    region: 'Udmurtia',
    city: 'Grahovo',
  },
  {
    region: 'Udmurtia',
    city: 'Debesy',
  },
  {
    region: 'Udmurtia',
    city: 'Zavyalovo',
  },
  {
    region: 'Udmurtia',
    city: 'Game',
  },
  {
    region: 'Udmurtia',
    city: 'Izhevsk',
  },
  {
    region: 'Udmurtia',
    city: 'Kama',
  },
  {
    region: 'Udmurtia',
    city: 'Kambarka',
  },
  {
    region: 'Udmurtia',
    city: 'Karakulino',
  },
  {
    region: 'Udmurtia',
    city: 'Kez',
  },
  {
    region: 'Udmurtia',
    city: 'Kizner',
  },
  {
    region: 'Udmurtia',
    city: 'Kiyasovo',
  },
  {
    region: 'Udmurtia',
    city: 'Krasnogorskoye',
  },
  {
    region: 'Udmurtia',
    city: 'Mozhga',
  },
  {
    region: 'Udmurtia',
    city: 'Sarapul',
  },
  {
    region: 'Udmurtia',
    city: 'Celts',
  },
  {
    region: 'Udmurtia',
    city: 'Syumsi',
  },
  {
    region: 'Udmurtia',
    city: 'Uva',
  },
  {
    region: 'Udmurtia',
    city: 'Ustinov',
  },
  {
    region: 'Udmurtia',
    city: 'Sharkan',
  },
  {
    region: 'Udmurtia',
    city: 'Yukamenskoye',
  },
  {
    region: 'Udmurtia',
    city: 'Yakshur-Bodia',
  },
  {
    region: 'Udmurtia',
    city: 'Yar',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Bazaar Syzgan',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Barysh',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Big Nagatkino',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Veshkaima',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Swallowing',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Dimitrovgrad',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Ignatovka',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Izmailovo',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Inza',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Isheevka',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Canadey',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Karsun',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Kuzovatovo',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Maina',
  },
  {
    region: 'Ulyanovsk region',
    city: 'New Malykla',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Novospasskoye',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Pavlovka',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Radishchevo',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Sengiley',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Staraya Kulatka',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Old Lane',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Surskoye',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Terenga',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Ulyanovsk',
  },
  {
    region: 'Ulyanovsk region',
    city: 'Attics',
  },
  {
    region: 'Ural region',
    city: 'Aksai',
  },
  {
    region: 'Ural region',
    city: 'Darinskoye',
  },
  {
    region: 'Ural region',
    city: 'Derkul',
  },
  {
    region: 'Ural region',
    city: 'Jambeits',
  },
  {
    region: 'Ural region',
    city: 'Dzhanybek',
  },
  {
    region: 'Ural region',
    city: 'Kaztalovka',
  },
  {
    region: 'Ural region',
    city: 'Kalmykovo',
  },
  {
    region: 'Ural region',
    city: 'Karatobe',
  },
  {
    region: 'Ural region',
    city: 'Peremetnoe',
  },
  {
    region: 'Ural region',
    city: 'Saihin',
  },
  {
    region: 'Ural region',
    city: 'Uralsk',
  },
  {
    region: 'Ural region',
    city: 'Fedorovka',
  },
  {
    region: 'Ural region',
    city: 'Furmanovo',
  },
  {
    region: 'Ural region',
    city: 'Chapaev',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Amursk',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Ayan',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Birch',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Bikin',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Bira',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Birakan',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Bogorodskoye',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Bologna',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Vanino',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Volochaevka Vtoraya',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Highland',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Vyazemsky',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Mountain',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Gurskoye',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Dormidontovka',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'The Testaments of Ilyich',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Lime',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Innokentyevka',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Komsomolsk-on-Amur',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Leninskoe',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Nelkan',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Nikolaevsk-on-Amur',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Obluchye',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Okhotsk',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Pereyaslavka',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Smidovich',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Sovetskaya Gavan',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Sofiysk',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Troitskoye',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Tugur',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Khabarovsk',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Chegdomyn',
  },
  {
    region: 'Khabarovsk Territory',
    city: 'Chumikan',
  },
  {
    region: 'Khakassia',
    city: 'Abakan',
  },
  {
    region: 'Khakassia',
    city: 'Sayanogorsk',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Agan',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Igrim',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Izluchinsk',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Langepas',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Lyantor',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Megion',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Nefteyugansk',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Nizhnevartovsk',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Nyagan',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Pokachi',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Priobye',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Pyt-Yah',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Rainbow',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Surgut',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Uray',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Khanty-Mansiysk',
  },
  {
    region: 'Khanty-Mansiysk Autonomous Okrug',
    city: 'Yugorsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Agapovka',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Argayash',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Asha',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Bakal',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Breda',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Varna',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Verkhneuralsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Verkhny Ufaley',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Emanzhelinsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Zlatoust',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Karabash',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Kartaly',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Castle',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Katav-Ivanovsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Kopeysk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Korkino',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Kunashak',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Kusa',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Kyshtym',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Magnitogorsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Miass',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Ozersk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Oktyabrskoye',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Plast',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Satka',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Sim',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Snezhinsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Trekhgorny',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Troitsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Uwelsky',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Uyskoe',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Ust-Katav',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Ferchampenoise',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Chebarkul',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Chelyabinsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Chesma',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Yuzhno-Uralsk',
  },
  {
    region: 'Chelyabinsk region',
    city: 'Yuryuzan',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Argun',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Grozny',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Gudermez',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Malgobek',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Nazran',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Naurskaya',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Nozhay-Yurt',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Ordzhonikidze',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Soviet',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Urus-Martan',
  },
  {
    region: 'Chechen-Ingushetia',
    city: 'Shawls',
  },
  {
    region: 'Chita region',
    city: 'Aginskoye',
  },
  {
    region: 'Chita region',
    city: 'Aksenovo-Zilovskoye',
  },
  {
    region: 'Chita region',
    city: 'Aksha',
  },
  {
    region: 'Chita region',
    city: 'Aleksandrovsky Zavod',
  },
  {
    region: 'Chita region',
    city: 'Amazar',
  },
  {
    region: 'Chita region',
    city: 'Arbagar',
  },
  {
    region: 'Chita region',
    city: 'Atamanovka',
  },
  {
    region: 'Chita region',
    city: 'Baley',
  },
  {
    region: 'Chita region',
    city: 'Greyhound',
  },
  {
    region: 'Chita region',
    city: 'Bukachach',
  },
  {
    region: 'Chita region',
    city: 'Gazimursky Zavod',
  },
  {
    region: 'Chita region',
    city: 'Davenda',
  },
  {
    region: 'Chita region',
    city: 'Darasun',
  },
  {
    region: 'Chita region',
    city: 'Wood',
  },
  {
    region: 'Chita region',
    city: 'Duldurga',
  },
  {
    region: 'Chita region',
    city: 'Gindo',
  },
  {
    region: 'Chita region',
    city: 'Zabaykalsk',
  },
  {
    region: 'Chita region',
    city: 'Ithaca',
  },
  {
    region: 'Chita region',
    city: 'Kalga',
  },
  {
    region: 'Chita region',
    city: 'Karymskoye',
  },
  {
    region: 'Chita region',
    city: 'Nickname',
  },
  {
    region: 'Chita region',
    city: 'Klyuchevsky',
  },
  {
    region: 'Chita region',
    city: 'Kokui',
  },
  {
    region: 'Chita region',
    city: 'Krasnokamensk',
  },
  {
    region: 'Chita region',
    city: 'Red Chicoy',
  },
  {
    region: 'Chita region',
    city: 'Kyra',
  },
  {
    region: 'Chita region',
    city: 'Mogotui',
  },
  {
    region: 'Chita region',
    city: 'Mogocha',
  },
  {
    region: 'Chita region',
    city: 'Nerchinsk',
  },
  {
    region: 'Chita region',
    city: 'Nerchinsk Plant',
  },
  {
    region: 'Chita region',
    city: 'Nizhny Chasuchey',
  },
  {
    region: 'Chita region',
    city: 'Tin',
  },
  {
    region: 'Chita region',
    city: 'Pervomaisky',
  },
  {
    region: 'Chita region',
    city: 'Petrovsk-Zabaykalsky',
  },
  {
    region: 'Chita region',
    city: 'Priargunsk',
  },
  {
    region: 'Chita region',
    city: 'Sretensk',
  },
  {
    region: 'Chita region',
    city: 'Dead end',
  },
  {
    region: 'Chita region',
    city: 'Flying away',
  },
  {
    region: 'Chita region',
    city: 'Hilok',
  },
  {
    region: 'Chita region',
    city: 'Chara',
  },
  {
    region: 'Chita region',
    city: 'Chernyshevsk',
  },
  {
    region: 'Chita region',
    city: 'Chita',
  },
  {
    region: 'Chita region',
    city: 'Shelopugino',
  },
  {
    region: 'Chita region',
    city: 'Shilka',
  },
  {
    region: 'Chuvashia',
    city: 'Alatyr',
  },
  {
    region: 'Chuvashia',
    city: 'Alikovo',
  },
  {
    region: 'Chuvashia',
    city: 'Batyreva',
  },
  {
    region: 'Chuvashia',
    city: 'Buinsk',
  },
  {
    region: 'Chuvashia',
    city: 'Vurnary',
  },
  {
    region: 'Chuvashia',
    city: 'Ibresi',
  },
  {
    region: 'Chuvashia',
    city: 'Kanash',
  },
  {
    region: 'Chuvashia',
    city: 'Kira',
  },
  {
    region: 'Chuvashia',
    city: 'Komsomolskoye',
  },
  {
    region: 'Chuvashia',
    city: 'Krasnoarmeyskoye',
  },
  {
    region: 'Chuvashia',
    city: 'Red Chetai',
  },
  {
    region: 'Chuvashia',
    city: 'Kugesi',
  },
  {
    region: 'Chuvashia',
    city: 'Mariinsky Posad',
  },
  {
    region: 'Chuvashia',
    city: 'Morgaushi',
  },
  {
    region: 'Chuvashia',
    city: 'Novocheboksarsk',
  },
  {
    region: 'Chuvashia',
    city: 'Poretskoe',
  },
  {
    region: 'Chuvashia',
    city: 'Urmars',
  },
  {
    region: 'Chuvashia',
    city: 'Tsivilsk',
  },
  {
    region: 'Chuvashia',
    city: 'Cheboksary',
  },
  {
    region: 'Chuvashia',
    city: 'Shemursha',
  },
  {
    region: 'Chuvashia',
    city: 'Sumerlya',
  },
  {
    region: 'Chuvashia',
    city: 'Yadrin',
  },
  {
    region: 'Chuvashia',
    city: 'Yalchiki',
  },
  {
    region: 'Chuvashia',
    city: 'Yantikovo',
  },
  {
    region: 'Chukotka Autonomous Region',
    city: 'Anadyr',
  },
  {
    region: 'Chukotka Autonomous Region',
    city: 'Bilibino',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Gubkinsky',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Zapolyarny',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Muravlenko',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Nadym',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Novy Urengoy',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Noyabrsk',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Purovsk',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Salekhard',
  },
  {
    region: 'Yamalo-Nenets Autonomous Okrug',
    city: 'Tarko',
  },
  {
    region: 'Yaroslavl region',
    city: 'Andropov',
  },
  {
    region: 'Yaroslavl region',
    city: 'Berendeevo',
  },
  {
    region: 'Yaroslavl region',
    city: 'Big Village',
  },
  {
    region: 'Yaroslavl region',
    city: 'Borisoglebsky',
  },
  {
    region: 'Yaroslavl region',
    city: 'Breitovo',
  },
  {
    region: 'Yaroslavl region',
    city: 'Burmakino',
  },
  {
    region: 'Yaroslavl region',
    city: 'Varegovo',
  },
  {
    region: 'Yaroslavl region',
    city: 'Volga',
  },
  {
    region: 'Yaroslavl region',
    city: 'Gavrilov Yam',
  },
  {
    region: 'Yaroslavl region',
    city: 'Danilov',
  },
  {
    region: 'Yaroslavl region',
    city: 'Love',
  },
  {
    region: 'Yaroslavl region',
    city: 'Myshkino',
  },
  {
    region: 'Yaroslavl region',
    city: 'Nekrasovskoye',
  },
  {
    region: 'Yaroslavl region',
    city: 'New Necous',
  },
  {
    region: 'Yaroslavl region',
    city: 'Pereslavl-Zalessky',
  },
  {
    region: 'Yaroslavl region',
    city: 'Poshekhonye-Volodarsk',
  },
  {
    region: 'Yaroslavl region',
    city: 'Rostov',
  },
  {
    region: 'Yaroslavl region',
    city: 'Rybinsk',
  },
  {
    region: 'Yaroslavl region',
    city: 'Tutaev',
  },
  {
    region: 'Yaroslavl region',
    city: 'Uglich',
  },
  {
    region: 'Yaroslavl region',
    city: 'Yaroslavl',
  },
]
export default RegionCityListEn
