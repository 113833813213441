import TitleTypes from "../types/title-types";

export const Title: TitleTypes = {
  mainTitle: {
    ru: "Откликнуться на вакансию",
    en: "Apply for job",
    kz: "Жұмысқа өтініш қалдыру",
  },
  prerequisites: {
    ru: "Обязательные требования",
    en: "Mandatory requirements",
    kz: "Міндетті талаптар",
  },
  prerequisitesDriverLicenses: {
    ru: "для отклика на вакансию необходимы права категории:",
    en: "to apply for a vacancy, category rights are required:",
    kz: "жұмысқа өтініш қалдыру үшін талап етілетін жүргізуші лицензия санаты:",
  },
  personalData: {
    ru: "Анкетные данные",
    en: "Personal data",
    kz: "Жеке деректер",
  },
  contactsTitle: {
    ru: "Контакты",
    en: "Contacts",
    kz: "Байланыс",
  },
  contactsMessengersTitle: {
    ru: "К номеру привязаны:",
    en: "Linked to the number:",
    kz: "Нөмірге байланысты:",
  },
  contactsMessengersDescription: {
    ru: "Укажите хотя бы один мессенджер для связи:",
    en: "Specify at least one messenger for communication:",
    kz: "Байланыс үшін кем дегенде бір мессенджерді көрсетіңіз:",
  },
  messengersTitle: {
    ru: "Мессeнджеры",
    en: "Messengers",
    kz: "Мессeнджеры",
  },
  addMessengerButton: {
    ru: "Добавить мессенджер",
    en: "Add a messenger",
    kz: "Мессенджер қосу",
  },
  socialLinksTitle: {
    ru: "Социальные сети",
    en: "Social networks",
    kz: "Әлеуметтік желілер",
  },
  sexTitle: {
    ru: "Укажите Ваш пол:",
    en: "Please enter your gender:",
    kz: "Жынысыңызды енгізіңіз:",
  },
  familyStatusTitle: {
    ru: "Семейное положение:",
    en: "Family status:",
    kz: "Отбасы жағдайы:",
  },
  driverLicenseTitle: {
    ru: "Водительское удостоверение",
    en: "Driver's license",
    kz: "Жүргізуші куәлігі",
  },
  languagesTitle: {
    ru: "Знание языков",
    en: "Knowledge of languages",
    kz: "Тілдерді білу",
  },
  educationTitle: {
    ru: "Образование",
    en: "Education",
    kz: "Білім",
  },
  experienceTitle: {
    ru: "Опыт работы",
    en: "Experience",
    kz: "Тәжірибе",
  },
  portfolioTitle: {
    ru: "Резюме и портфолио",
    en: "CV and portfolio",
    kz: "CV және портфолио",
  },
  successSubmitHeader: {
    ru: "Спасибо за заполнение анкеты!",
    en: "Thank you for filling out the survey!",
    kz: "Сауалнаманы толтырғаныңыз үшін рахмет!",
  },
  successSubmitMessage: {
    ru: "Ваша заявка отправлена на рассмотрение",
    en: "Your application has been sent for review",
    kz: "Сіздің өтінішіңіз қарауға жіберілді",
  },
  errorSubmitHeader: {
    ru: "Упс, что-то пошло не так",
    en: "Oops, something went wrong",
    kz: "Ой, бірдеңе дұрыс болмады",
  },
  errorSubmitMessage: {
    ru: "Попробуйте заполнить анкету позже",
    en: "Please try filling out the form later.",
    kz: "Пішінді кейінірек толтырып көріңіз.",
  },
  noVacanciesMessageHeader: {
    ru: "Все вакансии занял кот Василий!",
    en: "All vacancies were filled by the cat Vasily!",
    kz: "Барлық бос орындарды мысық Василий толтырды!",
  },
  noVacanciesMessage: {
    ru: "Но у него лапки, поэтому в будущем мы откроем новый набор в штат!",
    en: "But he has paws, so in the future we will open a new recruitment!",
    kz: "Бірақ оның табаны бар, сондықтан болашақта біз жаңа рекрутинг ашамыз!",
  },
  back: {
    ru: "Назад",
    en: "Back",
    kz: "Артқа",
  },
  accept: {
    ru: "Принять",
    en: "Accept",
    kz: "Қабылдау",
  },
};
