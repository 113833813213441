const url = typeof window !== 'undefined' ? window.location.host : ''

export const BACKEND_URL = url.includes('localhost:3000') || url.includes('vacancy-dev') ?
    'https://irenta.hopto.org' :
    url.includes('vacancy-msk') ?
    'https://msk.ka.irenta.online' :
    url.includes('vacancy-spb') ?
    'https://spb.ka.irenta.online' :
    'https://kz.ka.irenta.online'
export const CITY: 'spb' | 'msk' | 'ala' = url.includes('localhost:3000') || url.includes('vacancy-dev') ?
    'spb' :
    url.includes('vacancy-msk') ?
    'msk' :
    url.includes('vacancy-spb') ?
    'spb' :
    'ala'
export const INSTANCE = url.includes('localhost:3000') || url.includes('vacancy-dev') ?
    'spb' :
    url.includes('vacancy-msk') ?
    'msk' :
    url.includes('vacancy-spb') ?
    'spb' :
    url.includes('vacancy-second') ?
    'second' :
    'ala'
export const UPLOAD_ENDPOINT = `${BACKEND_URL}/public/api/Vacancies/upload`;
export const TOKEN_ENDPOINT = `${BACKEND_URL}/public/api/Account/token`;
export const SUBMIT_ENDPOINT = `${BACKEND_URL}/public/api/Vacancies`;
export const GET_VACANCIES = `${BACKEND_URL}/public/api/Vacancies/active`;
