import { validate as emailValidator } from "email-validator";
import Applicant from "src/types/applicant";

interface VlidateDateArgs {
  current: Date | string;
  min?: Date;
  max?: Date;
  required?: boolean;
}
export function fromISOdate(date: string): Date {
  const year = parseInt(date.substr(0, 4), 10);
  const month = parseInt(date.substr(5, 2), 10) - 1;
  const day = parseInt(date.substr(8), 10);
  return new Date(year, month, day);
}

export function parseDate(date: Date | string): Date | null {
  if (typeof date === "string") {
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return fromISOdate(date);
    }
  }
  if (date instanceof Date) {
    return date;
  }
  return null;
}
export function validateDate({ current, min, max, required }: VlidateDateArgs): boolean {
  // By default allow empty strings. Make stronger checking on submitting
  const currentValue = parseDate(current);
  if (!currentValue) {
    return !required;
  }
  if (min && max) {
    return min <= currentValue && currentValue <= max;
  }
  if (min) {
    return min <= currentValue;
  }
  if (max) {
    return currentValue <= max;
  }
  return true;
}

// By default allow empty strings
// Will make stronger validation on submitting
export function validateString(string: string, maxLength: number, required?: boolean, regexp?: RegExp): boolean {
  const minLength = required ? 1 : 0;
  const trimmedString = string?.trim();
  return trimmedString?.length >= minLength && trimmedString?.length <= maxLength && Boolean(trimmedString.match(regexp || ""));
}

export function validateEmail(text: string, required?: boolean): boolean {
  if (!text && !required) return true;
  return validateString(text, 100, required) && emailValidator(text);
}

const URL_REGEXP = /^((http(s){0,1}:\/\/(\w|\d)+){1}|\w+(\w|\d)*\.\w)[^\s]*((\w|\d)\.(\w|\d)[^\s]*)*(\w|\d|\/)$/g;
export function validateUrl(url: string, maxLength = 300, required = false): boolean {
  if (!url && !required) return true;
  if (url) {
    if (typeof url === "string") {
      if (url?.length < maxLength) {
        return Boolean(url.match(URL_REGEXP));
      }
    }
  }
  return false;
}

export function validatePhone(value: string): boolean {
  return validateString(value, 24, true) && value?.length >= 11;
}
export function yearsAgo(years: number): Date {
  const now = new Date();
  now.setFullYear(now.getFullYear() - years);
  return now;
}
export function validate(state: Applicant, showResume: boolean, showPortfolio: boolean, schedule: number) {
  return [
    state.photo.fileName?.length === 0 && "photo",
    !validateString(state.lastname, 200, true) && "lastname",
    !validateString(state.firstname, 200, true) && "firstname",
    !validateDate({
      current: state.birthdate,
      min: yearsAgo(130),
      max: yearsAgo(18),
      required: true,
    }) && "birthdate",
    !validateString(state.citizenship, 200, true) && "citizenship",
    !state.residenceAddress.currentlyInAnotherCityAndReadyForMove && schedule !== 6 && !validateString(state.residenceAddress.district, 200, true) && "district",
    schedule === 6 && !validateString(state.residenceAddress.country, 200, true) && "country",
    schedule === 6 && !validateString(state.residenceAddress.city, 200, true) && "city",
    schedule === 6 && !validateString(state.residenceAddress.region, 200, true) && "addressRegion",
    !validatePhone(state.phone) && "phone",
    !validateEmail(state.email, true) && "email",
    state.messengers?.length === 0 && "messengersType",
    ...state.messengers.map((item, index)=> {
      return item.type === '9' ? 
        item.link.length < 6 && `messengersLink${index}` : 
        !validateString(state.messengers[index]?.link, 200, true) && `messengersLink${index}`
    }),
    state.socialLinks?.length === 0 && "socialLinkType",
    !validateUrl(state.socialLinks[0]?.link, 200, true) && "socialLink",
    !validateString(state.collegeName, 200, true) && "collegeName",
    !state.noWorkExperience && !state.workExperienceYears && 'workExperienceYears',
    !state.noWorkExperience && (!state.workExperienceDescription || state?.workExperienceDescription?.trim()?.length < 10) && "workExperienceDescription",
    !validateString(state.resumeLink, 200, true) && showResume && "resumeLink",
    !validateString(state.portfolioLink, 200, true) && showPortfolio && "portfolioLink",
    (!state.coveringLetter || state.coveringLetter?.trim()?.length < 10) && "coveringLetter",
    !validateString(state.informationSource, 200, true) && "informationSource",
  ].filter((e) => e !== false);
}
