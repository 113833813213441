import React from "react";
import CheckBox from "../inputs/CheckBox";
import LabeledInput from "../inputs/LabeledInput";
import TextArea from "../inputs/TextArea";
import style from "./Experience.module.scss";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import gs from "../../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";
import { Errors } from "../../content/errors";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
}

function Experience({ lang, state, invalidFields, dispatch, disabled }: Props) {
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={style.ExperienceBox}>
          <span className={gs.header}>{Title.experienceTitle[lang]}</span>
          <CheckBox
            disabled={disabled}
            label={Fields.noExperience?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.NO_WORK_EXPERIENCE,
                data: !state.noWorkExperience,
              });
              if (!state.noWorkExperience) dispatch({
                type: actions.WORK_EXPERIENCE_YEARS,
                data: 0,
              });
            }}
            active={state.noWorkExperience}
          />
          {!state.noWorkExperience && (
            <>
              <LabeledInput
                name={'workExperienceYears'}
                disabled={disabled}
                label={Fields.yearExperience?.label[lang]}
                onChange={(e) => {
                  dispatch({
                    type: actions.WORK_EXPERIENCE_YEARS,
                    data: +e.target.value.replace(/[^0-9\.]/g, ""),
                  });
                }}
                maxLength={2}
                value={state.workExperienceYears && +state.workExperienceYears}
                required={!state.noWorkExperience}
                errorBorder={invalidFields.includes("workExperienceYears")}
                error={invalidFields.includes("workExperienceYears") && Errors.workExperienceYears[lang]}
              />
              <TextArea
                name={'workExperienceDescription'}
                disabled={disabled}
                label={Fields.descriptionExperience?.label[lang]}
                onChange={(e) => {
                  dispatch({
                    type: actions.WORK_EXPERIENCE_DESCRIPTION,
                    data: e.target.value,
                  });
                }}
                value={state.workExperienceDescription}
                required={true}
                rows={7}
                errorBorder={invalidFields.includes("workExperienceDescription")}
                error={invalidFields.includes("workExperienceDescription") && Errors.workExperienceDescription[lang]}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Experience;
