import React from "react";
import CheckBox from "../inputs/CheckBox";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import gs from "../../Layout.module.scss";
import style from "./KnowledgeLang.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  knowledgeLang: string[];
  dispatch: Dispatch;
}

function KnowledgeLang({ lang, state, invalidFields, knowledgeLang, dispatch }: Props) {
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={style.languagesButtonBox}>
          {/* <span className={gs.header}>{Title.languagesTitle[lang]}</span> */}
          {knowledgeLang.map((language) => {
            return (
              <CheckBox
                label={Fields[language as "RUS" | "ENG" | "KZ"]?.label[lang]}
                onClick={() => {
                  dispatch({
                    type: actions.LANGUAGES,
                    data: state.languages.indexOf(language) === -1 ? state.languages.concat(language) : state.languages.filter((e) => e !== language),
                  });
                }}
                active={state.languages.indexOf(language) !== -1}
                required={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default KnowledgeLang;
