import VacanciesTypesProps from "../types/vacancies-types";

export const Vacancies: VacanciesTypesProps = {
  vacancy: {
    ru: "Вакансия",
    en: "Vacancy",
    kz: "Бос жұмыс орны",
  },
  salary: {
    ru: "Уровень ЗП",
    en: "Salary level",
    kz: "Жалақы деңгейі",
  },
  from: {
    ru: "от",
    en: "from",
    kz: "бастап",
  },
  to: {
    ru: "до",
    en: "to",
    kz: "бұрын",
  },
  close: {
    ru: "Закрыть",
    en: "Сlose",
    kz: "Жабық",
  },
  experience: {
    ru: "Опыт работы",
    en: "Experience",
    kz: "Жұмыс тәжірибесі",
  },
  schedule: {
    ru: "График",
    en: "Schedule",
    kz: "Кесте",
  },
  affiliate: {
    ru: "Город",
    en: "Сity",
    kz: "Қала",
  },
  spb: {
    ru: "Cанкт-Петербург",
    en: "Saint Petersburg",
    kz: "Санкт-Перербург",
  },
  msk: {
    ru: "Москва",
    en: "Мoscow",
    kz: "Мәскеу",
  },
  ala: {
    ru: "Алматы",
    en: "Almaty",
    kz: "Алматы",
  },
  partTime: {
    ru: "Частичная занятость",
    en: "Part-time employment",
    kz: "Жеке жұмыспен қамту",
  },
  flexible: {
    ru: "Гибкий график",
    en: "Flexible schedule",
    kz: "Икемді кесте",
  },
  shiftwork: {
    ru: "Сменный график",
    en: "Shift work",
    kz: "Ауысымдық жұмыс",
  },
  remotely: {
    ru: "Удаленно",
    en: "Remotely",
    kz: "Қашықтан",
  },
  shiftMethod: {
    ru: "Вахтовый метод",
    en: "Вахтовый метод",
    kz: "Вахтовый метод",
  },
  prerequisites: {
    ru: "Обязательные требования",
    en: "Mandatory requirements",
    kz: "Міндетті талаптар",
  },
  hasACar: {
    ru: "Наличие личного автомобиля",
    en: "Having a personal car",
    kz: "Жеке көлігінің болуы",
  },
  driverLicense: {
    ru: "Водительское удостоверение",
    en: "Driver's license",
    kz: "Жүргізуші куәлігі",
  },
  resume: {
    ru: "Резюме",
    en: "Resume",
    kz: "Түйіндеме",
  },
  portfolio: {
    ru: "Портфолио",
    en: "Portfolio",
    kz: "Портфолио",
  },
  knowledgeLang: {
    ru: "знание языков",
    en: "knowledge of languages",
    kz: "тілдерді білу",
  },
  haveLicense: {
    ru: "права категории",
    en: "сategory license",
    kz: "санатындағы лицензия",
  },
  RUS: {
    ru: "Русский",
    en: "Russian",
    kz: "Орыс",
  },
  ENG: {
    ru: "Английский",
    en: "English",
    kz: "Ағылшын",
  },
  KZ: {
    ru: "Казахский",
    en: "Kazakh",
    kz: "Қазақ",
  },
  description: {
    ru: "Обязанности",
    en: "Responsibilities",
    kz: "Міндеттері",
  },
  requirements: {
    ru: "Требования",
    en: "Requirements",
    kz: "Талаптар",
  },
  additionally: {
    ru: "Дополнительно",
    en: "Additionally",
    kz: "Қосымша",
  },
  conditions: {
    ru: "Условия работы",
    en: "Working conditions",
    kz: "Жұмыс шарттары",
  },
  respond: {
    ru: "Откликнуться",
    en: "Respond",
    kz: "Жауап беру",
  },
};
