import { DistrictProps } from "src/types/common-types"

const MskDistrict: DistrictProps[] = [
    {
        value: {
            ru: 'Академический',
            en: 'Akademichesky',
            kz: 'Академический'
        },
        label: {
            ru: 'Академический',
            en: 'Akademichesky',
            kz: 'Академический'
        },
    },
    {
        value: {
            ru: 'Алексеевский',
            en: 'Alekseyevsky',
            kz: 'Алексеевский'
        },
        label: {
            ru: 'Алексеевский',
            en: 'Alekseyevsky',
            kz: 'Алексеевский'
        },
    },
    {
        value: {
            ru: 'Алтуфьевский',
            en: 'Altufyevsky',
            kz: 'Алтуфьевский'
        },
        label: {
            ru: 'Алтуфьевский',
            en: 'Altufyevsky',
            kz: 'Алтуфьевский'
        },
    },
    {
        value: {
            ru: 'Арбат',
            en: 'Arbat',
            kz: 'Арбат'
        },
        label: {
            ru: 'Арбат',
            en: 'Arbat',
            kz: 'Арбат'
        },
    },
    {
        value: {
            ru: 'Аэропорт',
            en: 'Aeroport',
            kz: 'Аэропорт'
        },
        label: {
            ru: 'Аэропорт',
            en: 'Aeroport',
            kz: 'Аэропорт'
        },
    },
    {
        value: {
            ru: 'Бабушкинский',
            en: 'Babushkinsky',
            kz: 'Бабушкинский'
        },
        label: {
            ru: 'Бабушкинский',
            en: 'Babushkinsky',
            kz: 'Бабушкинский'
        },
    },
    {
        value: {
            ru: 'Басманный',
            en: 'Basmanny',
            kz: 'Басманный'
        },
        label: {
            ru: 'Басманный',
            en: 'Basmanny',
            kz: 'Басманный'
        },
    },
    {
        value: {
            ru: 'Беговой',
            en: 'Begovoy',
            kz: 'Беговой'
        },
        label: {
            ru: 'Беговой',
            en: 'Begovoy',
            kz: 'Беговой'
        },
    },
    {
        value: {
            ru: 'Бескудниковский',
            en: 'Beskudnikovsky',
            kz: 'Бескудниковский'
        },
        label: {
            ru: 'Бескудниковский',
            en: 'Beskudnikovsky',
            kz: 'Бескудниковский'
        },
    },
    {
        value: {
            ru: 'Бибирево',
            en: 'Bibirevo',
            kz: 'Бибирево'
        },
        label: {
            ru: 'Бибирево',
            en: 'Bibirevo',
            kz: 'Бибирево'
        },
    },
    {
        value: {
            ru: 'Бирюлёво Восточное',
            en: 'Biryulyovo Vostochnoye',
            kz: 'Бирюлёво Восточное'
        },
        label: {
            ru: 'Бирюлёво Восточное',
            en: 'Biryulyovo Vostochnoye',
            kz: 'Бирюлёво Восточное'
        },
    },
    {
        value: {
            ru: 'Бирюлёво Западное',
            en: 'Biryulyovo Zapadnoye',
            kz: 'Бирюлёво Западное'
        },
        label: {
            ru: 'Бирюлёво Западное',
            en: 'Biryulyovo Zapadnoye',
            kz: 'Бирюлёво Западное'
        },
    },
    {
        value: {
            ru: 'Богородское',
            en: 'Bogorodskoye',
            kz: 'Богородское'
        },
        label: {
            ru: 'Богородское',
            en: 'Bogorodskoye',
            kz: 'Богородское'
        },
    },
    {
        value: {
            ru: 'Братеево',
            en: 'Brateyevo',
            kz: 'Братеево'
        },
        label: {
            ru: 'Братеево',
            en: 'Brateyevo',
            kz: 'Братеево'
        },
    },
    {
        value: {
            ru: 'Бутово Северное',
            en: 'Butovo Severnoye',
            kz: 'Бутово Северное'
        },
        label: {
            ru: 'Бутово Северное',
            en: 'Butovo Severnoye',
            kz: 'Бутово Северное'
        },
    },
    {
        value: {
            ru: 'Бутово Южное',
            en: 'Butovo Yuzhnoye',
            kz: 'Бутово Южное'
        },
        label: {
            ru: 'Бутово Южное',
            en: 'Butovo Yuzhnoye',
            kz: 'Бутово Южное'
        },
    },
    {
        value: {
            ru: 'Бутырский',
            en: 'Butyrsky',
            kz: 'Бутырский'
        },
        label: {
            ru: 'Бутырский',
            en: 'Butyrsky',
            kz: 'Бутырский'
        },
    },
    {
        value: {
            ru: 'Вешняки',
            en: 'Veshnyaki',
            kz: 'Вешняки'
        },
        label: {
            ru: 'Вешняки',
            en: 'Veshnyaki',
            kz: 'Вешняки'
        },
    },
    {
        value: {
            ru: 'Внуково',
            en: 'Vnukovo',
            kz: 'Внуково'
        },
        label: {
            ru: 'Внуково',
            en: 'Vnukovo',
            kz: 'Внуково'
        },
    },
    {
        value: {
            ru: 'Войковский',
            en: 'Voykovsky',
            kz: 'Войковский'
        },
        label: {
            ru: 'Войковский',
            en: 'Voykovsky',
            kz: 'Войковский'
        },
    },
    {
        value: {
            ru: 'Восточный',
            en: 'Vostochny',
            kz: 'Восточный'
        },
        label: {
            ru: 'Восточный',
            en: 'Vostochny',
            kz: 'Восточный'
        },
    },
    {
        value: {
            ru: 'Выхино-Жулебино',
            en: 'Vykhino-Zhulebino',
            kz: 'Выхино-Жулебино'
        },
        label: {
            ru: 'Выхино-Жулебино',
            en: 'Vykhino-Zhulebino',
            kz: 'Выхино-Жулебино'
        },
    },
    {
        value: {
            ru: 'Гагаринский',
            en: 'Gagarinsky',
            kz: 'Гагаринский'
        },
        label: {
            ru: 'Гагаринский',
            en: 'Gagarinsky',
            kz: 'Гагаринский'
        },
    },
    {
        value: {
            ru: 'Головинский',
            en: 'Golovinsky',
            kz: 'Головинский'
        },
        label: {
            ru: 'Головинский',
            en: 'Golovinsky',
            kz: 'Головинский'
        },
    },
    {
        value: {
            ru: 'Гольяново',
            en: 'Golyanovo',
            kz: 'Гольяново'
        },
        label: {
            ru: 'Гольяново',
            en: 'Golyanovo',
            kz: 'Гольяново'
        },
    },
    {
        value: {
            ru: 'Даниловский',
            en: 'Danilovsky',
            kz: 'Даниловский'
        },
        label: {
            ru: 'Даниловский',
            en: 'Danilovsky',
            kz: 'Даниловский'
        },
    },
    {
        value: {
            ru: 'Дегунино Восточное',
            en: 'Degunino Vostochnoye',
            kz: 'Дегунино Восточное'
        },
        label: {
            ru: 'Дегунино Восточное',
            en: 'Degunino Vostochnoye',
            kz: 'Дегунино Восточное'
        },
    },
    {
        value: {
            ru: 'Дегунино Западное',
            en: 'Degunino Zapadnoye',
            kz: 'Дегунино Западное'
        },
        label: {
            ru: 'Дегунино Западное',
            en: 'Degunino Zapadnoye',
            kz: 'Дегунино Западное'
        },
    },
    {
        value: {
            ru: 'Дмитровский',
            en: 'Dmitrovsky',
            kz: 'Дмитровский'
        },
        label: {
            ru: 'Дмитровский',
            en: 'Dmitrovsky',
            kz: 'Дмитровский'
        },
    },
    {
        value: {
            ru: 'Донской',
            en: 'Donskoy',
            kz: 'Донской'
        },
        label: {
            ru: 'Донской',
            en: 'Donskoy',
            kz: 'Донской'
        },
    },
    {
        value: {
            ru: 'Дорогомилово',
            en: 'Dorogomilovo',
            kz: 'Дорогомилово'
        },
        label: {
            ru: 'Дорогомилово',
            en: 'Dorogomilovo',
            kz: 'Дорогомилово'
        },
    },
    {
        value: {
            ru: 'Замоскворечье',
            en: 'Zamoskvorechye',
            kz: 'Замоскворечье'
        },
        label: {
            ru: 'Замоскворечье',
            en: 'Zamoskvorechye',
            kz: 'Замоскворечье'
        },
    },
    {
        value: {
            ru: 'Зюзино',
            en: 'Zyuzino',
            kz: 'Зюзино'
        },
        label: {
            ru: 'Зюзино',
            en: 'Zyuzino',
            kz: 'Зюзино'
        },
    },
    {
        value: {
            ru: 'Зябликово',
            en: 'Zyablikovo',
            kz: 'Зябликово'
        },
        label: {
            ru: 'Зябликово',
            en: 'Zyablikovo',
            kz: 'Зябликово'
        },
    },
    {
        value: {
            ru: 'Ивановское',
            en: 'Ivanovskoye',
            kz: 'Ивановское'
        },
        label: {
            ru: 'Ивановское',
            en: 'Ivanovskoye',
            kz: 'Ивановское'
        },
    },
    {
        value: {
            ru: 'Измайлово',
            en: 'Izmaylovo',
            kz: 'Измайлово'
        },
        label: {
            ru: 'Измайлово',
            en: 'Izmaylovo',
            kz: 'Измайлово'
        },
    },
    {
        value: {
            ru: 'Измайлово Восточное',
            en: 'Izmaylovo Vostochnoye',
            kz: 'Измайлово Восточное'
        },
        label: {
            ru: 'Измайлово Восточное',
            en: 'Izmaylovo Vostochnoye',
            kz: 'Измайлово Восточное'
        },
    },
    {
        value: {
            ru: 'Измайлово Северное',
            en: 'Izmaylovo Severnoye',
            kz: 'Измайлово Северное'
        },
        label: {
            ru: 'Измайлово Северное',
            en: 'Izmaylovo Severnoye',
            kz: 'Измайлово Северное'
        },
    },
    {
        value: {
            ru: 'Капотня',
            en: 'Kapotnya',
            kz: 'Капотня'
        },
        label: {
            ru: 'Капотня',
            en: 'Kapotnya',
            kz: 'Капотня'
        },
    },
    {
        value: {
            ru: 'Коньково',
            en: 'Konkovo',
            kz: 'Коньково'
        },
        label: {
            ru: 'Коньково',
            en: 'Konkovo',
            kz: 'Коньково'
        },
    },
    {
        value: {
            ru: 'Коптево',
            en: 'Koptevo',
            kz: 'Коптево'
        },
        label: {
            ru: 'Коптево',
            en: 'Koptevo',
            kz: 'Коптево'
        },
    },
    {
        value: {
            ru: 'Косино-Ухтомский',
            en: 'Kosino-Ukhtomsky',
            kz: 'Косино-Ухтомский'
        },
        label: {
            ru: 'Косино-Ухтомский',
            en: 'Kosino-Ukhtomsky',
            kz: 'Косино-Ухтомский'
        },
    },
    {
        value: {
            ru: 'Котловка',
            en: 'Kotlovka',
            kz: 'Котловка'
        },
        label: {
            ru: 'Котловка',
            en: 'Kotlovka',
            kz: 'Котловка'
        },
    },
    {
        value: {
            ru: 'Красносельский',
            en: 'Krasnoselsky',
            kz: 'Красносельский'
        },
        label: {
            ru: 'Красносельский',
            en: 'Krasnoselsky',
            kz: 'Красносельский'
        },
    },
    {
        value: {
            ru: 'Крылатское',
            en: 'Krylatskoye',
            kz: 'Крылатское'
        },
        label: {
            ru: 'Крылатское',
            en: 'Krylatskoye',
            kz: 'Крылатское'
        },
    },
    {
        value: {
            ru: 'Крюково',
            en: 'Kryukovo',
            kz: 'Крюково'
        },
        label: {
            ru: 'Крюково',
            en: 'Kryukovo',
            kz: 'Крюково'
        },
    },
    {
        value: {
            ru: 'Кузьминки',
            en: 'Kuzminki',
            kz: 'Кузьминки'
        },
        label: {
            ru: 'Кузьминки',
            en: 'Kuzminki',
            kz: 'Кузьминки'
        },
    },
    {
        value: {
            ru: 'Кунцево',
            en: 'Kuntsevo',
            kz: 'Кунцево'
        },
        label: {
            ru: 'Кунцево',
            en: 'Kuntsevo',
            kz: 'Кунцево'
        },
    },
    {
        value: {
            ru: 'Куркино',
            en: 'Kurkino',
            kz: 'Куркино'
        },
        label: {
            ru: 'Куркино',
            en: 'Kurkino',
            kz: 'Куркино'
        },
    },
    {
        value: {
            ru: 'Левобережный',
            en: 'Levoberezhny',
            kz: 'Левобережный'
        },
        label: {
            ru: 'Левобережный',
            en: 'Levoberezhny',
            kz: 'Левобережный'
        },
    },
    {
        value: {
            ru: 'Лефортово',
            en: 'Lefortovo',
            kz: 'Лефортово'
        },
        label: {
            ru: 'Лефортово',
            en: 'Lefortovo',
            kz: 'Лефортово'
        },
    },
    {
        value: {
            ru: 'Лианозово',
            en: 'Lianozovo',
            kz: 'Лианозово'
        },
        label: {
            ru: 'Лианозово',
            en: 'Lianozovo',
            kz: 'Лианозово'
        },
    },
    {
        value: {
            ru: 'Ломоносовский',
            en: 'Lomonosovsky',
            kz: 'Ломоносовский'
        },
        label: {
            ru: 'Ломоносовский',
            en: 'Lomonosovsky',
            kz: 'Ломоносовский'
        },
    },
    {
        value: {
            ru: 'Лосиноостровский',
            en: 'Losinoostrovsky',
            kz: 'Лосиноостровский'
        },
        label: {
            ru: 'Лосиноостровский',
            en: 'Losinoostrovsky',
            kz: 'Лосиноостровский'
        },
    },
    {
        value: {
            ru: 'Люблино',
            en: 'Lyublino',
            kz: 'Люблино'
        },
        label: {
            ru: 'Люблино',
            en: 'Lyublino',
            kz: 'Люблино'
        },
    },
    {
        value: {
            ru: 'Марфино',
            en: 'Marfino',
            kz: 'Марфино'
        },
        label: {
            ru: 'Марфино',
            en: 'Marfino',
            kz: 'Марфино'
        },
    },
    {
        value: {
            ru: 'Марьина роща',
            en: 'Maryina Roshcha',
            kz: 'Марьина роща'
        },
        label: {
            ru: 'Марьина роща',
            en: 'Maryina Roshcha',
            kz: 'Марьина роща'
        },
    },
    {
        value: {
            ru: 'Марьино',
            en: 'Maryino',
            kz: 'Марьино'
        },
        label: {
            ru: 'Марьино',
            en: 'Maryino',
            kz: 'Марьино'
        },
    },
    {
        value: {
            ru: 'Матушкино',
            en: 'Matushkino',
            kz: 'Матушкино'
        },
        label: {
            ru: 'Матушкино',
            en: 'Matushkino',
            kz: 'Матушкино'
        },
    },
    {
        value: {
            ru: 'Медведково Северное',
            en: 'Medvedkovo Severnoye',
            kz: 'Медведково Северное'
        },
        label: {
            ru: 'Медведково Северное',
            en: 'Medvedkovo Severnoye',
            kz: 'Медведково Северное'
        },
    },
    {
        value: {
            ru: 'Медведково Южное',
            en: 'Medvedkovo Yuzhnoye',
            kz: 'Медведково Южное'
        },
        label: {
            ru: 'Медведково Южное',
            en: 'Medvedkovo Yuzhnoye',
            kz: 'Медведково Южное'
        },
    },
    {
        value: {
            ru: 'Метрогородок',
            en: 'Metrogorodok',
            kz: 'Метрогородок'
        },
        label: {
            ru: 'Метрогородок',
            en: 'Metrogorodok',
            kz: 'Метрогородок'
        },
    },
    {
        value: {
            ru: 'Мещанский',
            en: 'Meshchansky',
            kz: 'Мещанский'
        },
        label: {
            ru: 'Мещанский',
            en: 'Meshchansky',
            kz: 'Мещанский'
        },
    },
    {
        value: {
            ru: 'Митино',
            en: 'Mitino',
            kz: 'Митино'
        },
        label: {
            ru: 'Митино',
            en: 'Mitino',
            kz: 'Митино'
        },
    },
    {
        value: {
            ru: 'Можайский',
            en: 'Mozhaysky',
            kz: 'Можайский'
        },
        label: {
            ru: 'Можайский',
            en: 'Mozhaysky',
            kz: 'Можайский'
        },
    },
    {
        value: {
            ru: 'Молжаниновский',
            en: 'Molzhaninovsky',
            kz: 'Молжаниновский'
        },
        label: {
            ru: 'Молжаниновский',
            en: 'Molzhaninovsky',
            kz: 'Молжаниновский'
        },
    },
    {
        value: {
            ru: 'Москворечье-Сабурово',
            en: 'Moskvorechye-Saburovo',
            kz: 'Москворечье-Сабурово'
        },
        label: {
            ru: 'Москворечье-Сабурово',
            en: 'Moskvorechye-Saburovo',
            kz: 'Москворечье-Сабурово'
        },
    },
    {
        value: {
            ru: 'Нагатино-Садовники',
            en: 'Nagatino-Sadovniki',
            kz: 'Нагатино-Садовники'
        },
        label: {
            ru: 'Нагатино-Садовники',
            en: 'Nagatino-Sadovniki',
            kz: 'Нагатино-Садовники'
        },
    },
    {
        value: {
            ru: 'Нагатинский затон',
            en: 'Nagatinsky Zaton',
            kz: 'Нагатинский затон'
        },
        label: {
            ru: 'Нагатинский затон',
            en: 'Nagatinsky Zaton',
            kz: 'Нагатинский затон'
        },
    },
    {
        value: {
            ru: 'Нагорный',
            en: 'Nagornoye',
            kz: 'Нагорный'
        },
        label: {
            ru: 'Нагорный',
            en: 'Nagornoye',
            kz: 'Нагорный'
        },
    },
    {
        value: {
            ru: 'Некрасовка',
            en: 'Nekrasovka',
            kz: 'Некрасовка'
        },
        label: {
            ru: 'Некрасовка',
            en: 'Nekrasovka',
            kz: 'Некрасовка'
        },
    },
    {
        value: {
            ru: 'Нижегородский',
            en: 'Nizhegorodsky',
            kz: 'Нижегородский'
        },
        label: {
            ru: 'Нижегородский',
            en: 'Nizhegorodsky',
            kz: 'Нижегородский'
        },
    },
    {
        value: {
            ru: 'Ново-Переделкино',
            en: 'Novo-Peredelkino',
            kz: 'Ново-Переделкино'
        },
        label: {
            ru: 'Ново-Переделкино',
            en: 'Novo-Peredelkino',
            kz: 'Ново-Переделкино'
        },
    },
    {
        value: {
            ru: 'Новогиреево',
            en: 'Novogireyevo',
            kz: 'Новогиреево'
        },
        label: {
            ru: 'Новогиреево',
            en: 'Novogireyevo',
            kz: 'Новогиреево'
        },
    },
    {
        value: {
            ru: 'Новокосино',
            en: 'Novokosino',
            kz: 'Новокосино'
        },
        label: {
            ru: 'Новокосино',
            en: 'Novokosino',
            kz: 'Новокосино'
        },
    },
    {
        value: {
            ru: 'Обручевский',
            en: 'Obruchevsky',
            kz: 'Обручевский'
        },
        label: {
            ru: 'Обручевский',
            en: 'Obruchevsky',
            kz: 'Обручевский'
        },
    },
    {
        value: {
            ru: 'Орехово-Борисово Северное',
            en: 'Orekhovo-Borisovo Severnoye',
            kz: 'Орехово-Борисово Северное'
        },
        label: {
            ru: 'Орехово-Борисово Северное',
            en: 'Orekhovo-Borisovo Severnoye',
            kz: 'Орехово-Борисово Северное'
        },
    },
    {
        value: {
            ru: 'Орехово-Борисово Южное',
            en: 'Orekhovo-Borisovo Yuzhnoye',
            kz: 'Орехово-Борисово Южное'
        },
        label: {
            ru: 'Орехово-Борисово Южное',
            en: 'Orekhovo-Borisovo Yuzhnoye',
            kz: 'Орехово-Борисово Южное'
        },
    },
    {
        value: {
            ru: 'Останкинский',
            en: 'Ostankinsky',
            kz: 'Останкинский'
        },
        label: {
            ru: 'Останкинский',
            en: 'Ostankinsky',
            kz: 'Останкинский'
        },
    },
    {
        value: {
            ru: 'Отрадное',
            en: 'Otradnoye',
            kz: 'Отрадное'
        },
        label: {
            ru: 'Отрадное',
            en: 'Otradnoye',
            kz: 'Отрадное'
        },
    },
    {
        value: {
            ru: 'Очаково-Матвеевское',
            en: 'Ochakovo-Matveyevskoye',
            kz: 'Очаково-Матвеевское'
        },
        label: {
            ru: 'Очаково-Матвеевское',
            en: 'Ochakovo-Matveyevskoye',
            kz: 'Очаково-Матвеевское'
        },
    },
    {
        value: {
            ru: 'Перово',
            en: 'Perovo',
            kz: 'Перово'
        },
        label: {
            ru: 'Перово',
            en: 'Perovo',
            kz: 'Перово'
        },
    },
    {
        value: {
            ru: 'Печатники',
            en: 'Pechatniki',
            kz: 'Печатники'
        },
        label: {
            ru: 'Печатники',
            en: 'Pechatniki',
            kz: 'Печатники'
        },
    },
    {
        value: {
            ru: 'Покровское-Стрешнево',
            en: 'Pokrovskoye-Streshnevo',
            kz: 'Покровское-Стрешнево'
        },
        label: {
            ru: 'Покровское-Стрешнево',
            en: 'Pokrovskoye-Streshnevo',
            kz: 'Покровское-Стрешнево'
        },
    },
    {
        value: {
            ru: 'Преображенское',
            en: 'Preobrazhenskoye',
            kz: 'Преображенское'
        },
        label: {
            ru: 'Преображенское',
            en: 'Preobrazhenskoye',
            kz: 'Преображенское'
        },
    },
    {
        value: {
            ru: 'Пресненский',
            en: 'Presnensky',
            kz: 'Пресненский'
        },
        label: {
            ru: 'Пресненский',
            en: 'Presnensky',
            kz: 'Пресненский'
        },
    },
    {
        value: {
            ru: 'Проспект Вернадского',
            en: 'Prospect Vernadskogo',
            kz: 'Проспект Вернадского'
        },
        label: {
            ru: 'Проспект Вернадского',
            en: 'Prospect Vernadskogo',
            kz: 'Проспект Вернадского'
        },
    },
    {
        value: {
            ru: 'Раменки',
            en: 'Ramenki',
            kz: 'Раменки'
        },
        label: {
            ru: 'Раменки',
            en: 'Ramenki',
            kz: 'Раменки'
        },
    },
    {
        value: {
            ru: 'Ростокино',
            en: 'Rostokino',
            kz: 'Ростокино'
        },
        label: {
            ru: 'Ростокино',
            en: 'Rostokino',
            kz: 'Ростокино'
        },
    },
    {
        value: {
            ru: 'Рязанский',
            en: 'Ryazansky',
            kz: 'Рязанский'
        },
        label: {
            ru: 'Рязанский',
            en: 'Ryazansky',
            kz: 'Рязанский'
        },
    },
    {
        value: {
            ru: 'Савёлки',
            en: 'Savyolki',
            kz: 'Савёлки'
        },
        label: {
            ru: 'Савёлки',
            en: 'Savyolki',
            kz: 'Савёлки'
        },
    },
    {
        value: {
            ru: 'Савёловский',
            en: 'Savyolovsky',
            kz: 'Савёловский'
        },
        label: {
            ru: 'Савёловский',
            en: 'Savyolovsky',
            kz: 'Савёловский'
        },
    },
    {
        value: {
            ru: 'Свиблово',
            en: 'Sviblovo',
            kz: 'Свиблово'
        },
        label: {
            ru: 'Свиблово',
            en: 'Sviblovo',
            kz: 'Свиблово'
        },
    },
    {
        value: {
            ru: 'Северный',
            en: 'Severny',
            kz: 'Северный'
        },
        label: {
            ru: 'Северный',
            en: 'Severny',
            kz: 'Северный'
        },
    },
    {
        value: {
            ru: 'Силино',
            en: 'Silino',
            kz: 'Силино'
        },
        label: {
            ru: 'Силино',
            en: 'Silino',
            kz: 'Силино'
        },
    },
    {
        value: {
            ru: 'Сокол',
            en: 'Sokol',
            kz: 'Сокол'
        },
        label: {
            ru: 'Сокол',
            en: 'Sokol',
            kz: 'Сокол'
        },
    },
    {
        value: {
            ru: 'Соколиная гора',
            en: 'Sokolinaya Gora',
            kz: 'Соколиная гора'
        },
        label: {
            ru: 'Соколиная гора',
            en: 'Sokolinaya Gora',
            kz: 'Соколиная гора'
        },
    },
    {
        value: {
            ru: 'Сокольники',
            en: 'Sokolniki',
            kz: 'Сокольники'
        },
        label: {
            ru: 'Сокольники',
            en: 'Sokolniki',
            kz: 'Сокольники'
        },
    },
    {
        value: {
            ru: 'Солнцево',
            en: 'Solntsevo',
            kz: 'Солнцево'
        },
        label: {
            ru: 'Солнцево',
            en: 'Solntsevo',
            kz: 'Солнцево'
        },
    },
    {
        value: {
            ru: 'Старое Крюково',
            en: 'Staroye Kryukovo',
            kz: 'Старое Крюково'
        },
        label: {
            ru: 'Старое Крюково',
            en: 'Staroye Kryukovo',
            kz: 'Старое Крюково'
        },
    },
    {
        value: {
            ru: 'Строгино',
            en: 'Strogino',
            kz: 'Строгино'
        },
        label: {
            ru: 'Строгино',
            en: 'Strogino',
            kz: 'Строгино'
        },
    },
    {
        value: {
            ru: 'Таганский',
            en: 'Tagansky',
            kz: 'Таганский'
        },
        label: {
            ru: 'Таганский',
            en: 'Tagansky',
            kz: 'Таганский'
        },
    },
    {
        value: {
            ru: 'Тверской',
            en: 'Tverskoy',
            kz: 'Тверской'
        },
        label: {
            ru: 'Тверской',
            en: 'Tverskoy',
            kz: 'Тверской'
        },
    },
    {
        value: {
            ru: 'Текстильщики',
            en: 'Tekstilshchiki',
            kz: 'Текстильщики'
        },
        label: {
            ru: 'Текстильщики',
            en: 'Tekstilshchiki',
            kz: 'Текстильщики'
        },
    },
    {
        value: {
            ru: 'Тёплый Стан',
            en: 'Tyoply Stan',
            kz: 'Тёплый Стан'
        },
        label: {
            ru: 'Тёплый Стан',
            en: 'Tyoply Stan',
            kz: 'Тёплый Стан'
        },
    },
    {
        value: {
            ru: 'Тимирязевский',
            en: 'Timiryazevsky',
            kz: 'Тимирязевский'
        },
        label: {
            ru: 'Тимирязевский',
            en: 'Timiryazevsky',
            kz: 'Тимирязевский'
        },
    },
    {
        value: {
            ru: 'Тропарёво-Никулино',
            en: 'Troparyovo-Nikulino',
            kz: 'Тропарёво-Никулино'
        },
        label: {
            ru: 'Тропарёво-Никулино',
            en: 'Troparyovo-Nikulino',
            kz: 'Тропарёво-Никулино'
        },
    },
    {
        value: {
            ru: 'Тушино Северное',
            en: 'Tushino Severnoye',
            kz: 'Тушино Северное'
        },
        label: {
            ru: 'Тушино Северное',
            en: 'Tushino Severnoye',
            kz: 'Тушино Северное'
        },
    },
    {
        value: {
            ru: 'Тушино Южное',
            en: 'Tushino Yuzhnoye',
            kz: 'Тушино Южное'
        },
        label: {
            ru: 'Тушино Южное',
            en: 'Tushino Yuzhnoye',
            kz: 'Тушино Южное'
        },
    },
    {
        value: {
            ru: 'Филёвский парк',
            en: 'Filyovsky Park',
            kz: 'Филёвский парк'
        },
        label: {
            ru: 'Филёвский парк',
            en: 'Filyovsky Park',
            kz: 'Филёвский парк'
        },
    },
    {
        value: {
            ru: 'Фили-Давыдково',
            en: 'Fili-Davydkovo',
            kz: 'Фили-Давыдково'
        },
        label: {
            ru: 'Фили-Давыдково',
            en: 'Fili-Davydkovo',
            kz: 'Фили-Давыдково'
        },
    },
    {
        value: {
            ru: 'Хамовники',
            en: 'Khamovniki',
            kz: 'Хамовники'
        },
        label: {
            ru: 'Хамовники',
            en: 'Khamovniki',
            kz: 'Хамовники'
        },
    },
    {
        value: {
            ru: 'Ховрино',
            en: 'Khovrino',
            kz: 'Ховрино'
        },
        label: {
            ru: 'Ховрино',
            en: 'Khovrino',
            kz: 'Ховрино'
        },
    },
    {
        value: {
            ru: 'Хорошёво-Мневники',
            en: 'Khoroshevo-Mnevniki',
            kz: 'Хорошёво-Мневники'
        },
        label: {
            ru: 'Хорошёво-Мневники',
            en: 'Khoroshevo-Mnevniki',
            kz: 'Хорошёво-Мневники'
        },
    },
    {
        value: {
            ru: 'Хорошёвский',
            en: 'Khoroshevsky',
            kz: 'Хорошёвский'
        },
        label: {
            ru: 'Хорошёвский',
            en: 'Khoroshevsky',
            kz: 'Хорошёвский'
        },
    },
    {
        value: {
            ru: 'Царицыно',
            en: 'Tsaritsyno',
            kz: 'Царицыно'
        },
        label: {
            ru: 'Царицыно',
            en: 'Tsaritsyno',
            kz: 'Царицыно'
        },
    },
    {
        value: {
            ru: 'Черёмушки',
            en: 'Cheryomushki',
            kz: 'Черёмушки'
        },
        label: {
            ru: 'Черёмушки',
            en: 'Cheryomushki',
            kz: 'Черёмушки'
        },
    },
    {
        value: {
            ru: 'Чертаново Северное',
            en: 'Chertanovo Severnoye',
            kz: 'Чертаново Северное'
        },
        label: {
            ru: 'Чертаново Северное',
            en: 'Chertanovo Severnoye',
            kz: 'Чертаново Северное'
        },
    },
    {
        value: {
            ru: 'Чертаново Центральное',
            en: 'Chertanovo Tsentralnoye',
            kz: 'Чертаново Центральное'
        },
        label: {
            ru: 'Чертаново Центральное',
            en: 'Chertanovo Tsentralnoye',
            kz: 'Чертаново Центральное'
        },
    },
    {
        value: {
            ru: 'Чертаново Южное',
            en: 'Chertanovo Yuzhnoye',
            kz: 'Чертаново Южное'
        },
        label: {
            ru: 'Чертаново Южное',
            en: 'Chertanovo Yuzhnoye',
            kz: 'Чертаново Южное'
        },
    },
    {
        value: {
            ru: 'Щукино',
            en: 'Shchukino',
            kz: 'Щукино'
        },
        label: {
            ru: 'Щукино',
            en: 'Shchukino',
            kz: 'Щукино'
        },
    },
    {
        value: {
            ru: 'Южнопортовый',
            en: 'Yuzhnoportovy',
            kz: 'Южнопортовый'
        },
        label: {
            ru: 'Южнопортовый',
            en: 'Yuzhnoportovy',
            kz: 'Южнопортовый'
        },
    },
    {
        value: {
            ru: 'Якиманка',
            en: 'Yakimanka',
            kz: 'Якиманка'
        },
        label: {
            ru: 'Якиманка',
            en: 'Yakimanka',
            kz: 'Якиманка'
        },
    },
    {
        value: {
            ru: 'Ярославский',
            en: 'Yaroslavsky',
            kz: 'Ярославский'
        },
        label: {
            ru: 'Ярославский',
            en: 'Yaroslavsky',
            kz: 'Ярославский'
        },
    },
    {
        value: {
            ru: 'Ясенево',
            en: 'Yasenevo',
            kz: 'Ясенево'
        },
        label: {
            ru: 'Ясенево',
            en: 'Yasenevo',
            kz: 'Ясенево'
        },
    }
]
export default MskDistrict