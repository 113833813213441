import { L10n } from "src/types/common-types"

export const allCountriesList: {value: string, label:L10n}[] = [
    {
      value: '612',
      label: {
        ru: 'Питкерн',
        en: 'Pitcairn',
        kz: 'Питкерн',
      },
    },
    {
      value: '334',
      label: {
        ru: 'Остров Херд и острова Макдональд',
        en: 'Heard Island and McDonald Islands',
        kz: 'Херд аралы және Макдональд аралдары',
      },
    },
    {
      value: '600',
      label: {
        ru: 'Парагвай',
        en: 'Paraguay',
        kz: 'Парагвай',
      },
    },
    {
      value: '634',
      label: {
        ru: 'Катар',
        en: 'Qatar',
        kz: 'Катар',
      },
    },
    {
      value: '162',
      label: {
        ru: 'Остров Рождества',
        en: 'Christmas Island',
        kz: 'Рождество аралы',
      },
    },
    {
      value: '222',
      label: {
        ru: 'Эль-Сальвадор',
        en: 'El Salvador',
        kz: 'Эль-Сальвадор',
      },
    },
    {
      value: '574',
      label: {
        ru: 'Остров Норфолк',
        en: 'Norfolk Island',
        kz: 'Норфолк аралы',
      },
    },
    {
      value: '583',
      label: {
        ru: 'Микронезия, Федеративные Штаты',
        en: 'Micronesia, Federated States of',
        kz: 'Микронезия, Федеративті Штаттар',
      },
    },
    {
      value: '705',
      label: {
        ru: 'Словения',
        en: 'Slovenia',
        kz: 'Словения',
      },
    },
    {
      value: '578',
      label: {
        ru: 'Норвегия',
        en: 'Norway',
        kz: 'Норвегия',
      },
    },
    {
      value: '570',
      label: {
        ru: 'Ниуэ',
        en: 'Niue',
        kz: 'Ниуэ',
      },
    },
    {
      value: '520',
      label: {
        ru: 'Науру',
        en: 'Nauru',
        kz: 'Науру',
      },
    },
    {
      value: '104',
      label: {
        ru: 'Мьянма',
        en: 'Burma',
        kz: 'Мьянма',
      },
    },
    {
      value: '584',
      label: {
        ru: 'Маршалловы острова',
        en: 'Marshall Islands',
        kz: 'Маршалл аралдары',
      },
    },
    {
      value: '376',
      label: {
        ru: 'Израиль',
        en: 'Israel',
        kz: 'Израиль',
      },
    },
    {
      value: '174',
      label: {
        ru: 'Коморы',
        en: 'Comoros',
        kz: 'Коморлар',
      },
    },
    {
      value: '446',
      label: {
        ru: 'Макао',
        en: 'Macao',
        kz: 'Макао',
      },
    },
    {
      value: '764',
      label: {
        ru: 'Таиланд',
        en: 'Thailand',
        kz: 'Тайланд',
      },
    },
    {
      value: '175',
      label: {
        ru: 'Майотта',
        en: 'Mayotte',
        kz: 'Майотта',
      },
    },
    {
      value: '450',
      label: {
        ru: 'Мадагаскар',
        en: 'Madagascar',
        kz: 'Мадагаскар',
      },
    },
    {
      value: '492',
      label: {
        ru: 'Монако',
        en: 'Monaco',
        kz: 'Монако',
      },
    },
    {
      value: '462',
      label: {
        ru: 'Мальдивы',
        en: 'Maldives',
        kz: 'Мальдив аралдары',
      },
    },
    {
      value: '442',
      label: {
        ru: 'Люксембург',
        en: 'Luxembourg',
        kz: 'Люксембург',
      },
    },
    {
      value: '180',
      label: {
        ru: 'Конго, Демократическая Республика',
        en: 'Congo, Democratic Republic of the',
        kz: 'Конго, Демократиялық Республика',
      },
    },
    {
      value: '558',
      label: {
        ru: 'Никарагуа',
        en: 'Nicaragua',
        kz: 'Никарагуа',
      },
    },
    {
      value: '818',
      label: {
        ru: 'Египет',
        en: 'Egypt',
        kz: 'Египет',
      },
    },
    {
      value: '426',
      label: {
        ru: 'Лесото',
        en: 'Lesotho',
        kz: 'Лесото',
      },
    },
    {
      value: '428',
      label: {
        ru: 'Латвия',
        en: 'Latvia',
        kz: 'Латвия',
      },
    },
    {
      value: '074',
      label: {
        ru: 'Остров Буве',
        en: 'Bouvet Island',
        kz: 'Буве аралы',
      },
    },
    {
      value: '414',
      label: {
        ru: 'Кувейт',
        en: 'Kuwait',
        kz: 'Кувейт',
      },
    },
    {
      value: '300',
      label: {
        ru: 'Греция',
        en: 'Greece',
        kz: 'Греция',
      },
    },
    {
      value: '064',
      label: {
        ru: 'Бутан',
        en: 'Bhutan',
        kz: 'Бутан',
      },
    },
    {
      value: '474',
      label: {
        ru: 'Мартиника',
        en: 'Martinique',
        kz: 'Мартиника',
      },
    },
    {
      value: '356',
      label: {
        ru: 'Индия',
        en: 'India',
        kz: 'Үндістан',
      },
    },
    {
      value: '646',
      label: {
        ru: 'Руанда',
        en: 'Rwanda',
        kz: 'Руанда',
      },
    },
    {
      value: '404',
      label: {
        ru: 'Кения',
        en: 'Kenya',
        kz: 'Кения',
      },
    },
    {
      value: '630',
      label: {
        ru: 'Пуэрто-Рико',
        en: 'Puerto Rico',
        kz: 'Пуэрто-Рико',
      },
    },
    {
      value: '144',
      label: {
        ru: 'Шри-Ланка',
        en: 'Sri Lanka',
        kz: 'Шри-Ланка',
      },
    },
    {
      value: '320',
      label: {
        ru: 'Гватемала',
        en: 'Guatemala',
        kz: 'Гватемала',
      },
    },
    {
      value: '148',
      label: {
        ru: 'Чад',
        en: 'Chad',
        kz: 'Чад',
      },
    },
    {
      value: '784',
      label: {
        ru: 'Объединенные Арабские Эмираты',
        en: 'United Arab Emirates',
        kz: 'Біріккен Араб Әмірліктері',
      },
    },
    {
      value: '232',
      label: {
        ru: 'Эритрея',
        en: 'Eritrea',
        kz: 'Эритрея',
      },
    },
    {
      value: '288',
      label: {
        ru: 'Гана',
        en: 'Ghana',
        kz: 'Гана',
      },
    },
    {
      value: '533',
      label: {
        ru: 'Аруба',
        en: 'Aruba',
        kz: 'Аруба',
      },
    },
    {
      value: '581',
      label: {
        ru: 'Малые Тихоокеанские отдаленные острова Соединенных Штатов',
        en: 'United States Minor Outlying Islands',
        kz: 'Америка Құрама Штаттарының Кіші Тынық мұхиты шалғай аралдары',
      },
    },
    {
      value: '368',
      label: {
        ru: 'Ирак',
        en: 'Iraq',
        kz: 'Ирак',
      },
    },
    {
      value: '800',
      label: {
        ru: 'Уганда',
        en: 'Uganda',
        kz: 'Уганда',
      },
    },
    {
      value: '392',
      label: {
        ru: 'Япония',
        en: 'Japan',
        kz: 'Жапония',
      },
    },
    {
      value: '031',
      label: {
        ru: 'Азербайджан',
        en: 'Azerbaijan',
        kz: 'Әзірбайжан',
      },
    },
    {
      value: '440',
      label: {
        ru: 'Литва',
        en: 'Lithuania',
        kz: 'Литва',
      },
    },
    {
      value: '262',
      label: {
        ru: 'Джибути',
        en: 'Djibouti',
        kz: 'Джибути',
      },
    },
    {
      value: '554',
      label: {
        ru: 'Новая Зеландия',
        en: 'New Zealand',
        kz: 'Жаңа Зеландия',
      },
    },
    {
      value: '887',
      label: {
        ru: 'Йемен',
        en: 'Yemen',
        kz: 'Йемен',
      },
    },
    {
      value: '422',
      label: {
        ru: 'Ливан',
        en: 'Lebanon',
        kz: 'Ливан',
      },
    },
    {
      value: '454',
      label: {
        ru: 'Малави',
        en: 'Malawi',
        kz: 'Малави',
      },
    },
    {
      value: '051',
      label: {
        ru: 'Армения',
        en: 'Armenia',
        kz: 'Армения',
      },
    },
    {
      value: '792',
      label: {
        ru: 'Турция',
        en: 'Turkey',
        kz: 'Түркия',
      },
    },
    {
      value: '716',
      label: {
        ru: 'Зимбабве',
        en: 'Zimbabwe',
        kz: 'Зимбабве',
      },
    },
    {
      value: '528',
      label: {
        ru: 'Нидерланды',
        en: 'Netherlands',
        kz: 'Нидерланды',
      },
    },
    {
      value: '430',
      label: {
        ru: 'Либерия',
        en: 'Liberia',
        kz: 'Либерия',
      },
    },
    {
      value: '218',
      label: {
        ru: 'Эквадор',
        en: 'Ecuador',
        kz: 'Эквадор',
      },
    },
    {
      value: '626',
      label: {
        ru: 'Тимор-Лесте',
        en: 'Timor-Leste',
        kz: 'Тимор-Лесте',
      },
    },
    {
      value: '096',
      label: {
        ru: 'Бруней-Даруссалам',
        en: 'Brunei Darussalam',
        kz: 'Бруней-Даруссалам',
      },
    },
    {
      value: '400',
      label: {
        ru: 'Иордания',
        en: 'Jordan',
        kz: 'Иордания',
      },
    },
    {
      value: '895',
      label: {
        ru: 'Абхазия',
        en: 'Abkhazia',
        kz: 'Абхазия',
      },
    },
    {
      value: '807',
      label: {
        ru: 'Республика Македония',
        en: 'Macedonia, The Former Yugoslav Republic Of',
        kz: 'Македония Республикасы',
      },
    },
    {
      value: '732',
      label: {
        ru: 'Западная Сахара',
        en: 'Western Sahara',
        kz: 'Батыс Сахара',
      },
    },
    {
      value: '226',
      label: {
        ru: 'Экваториальная Гвинея',
        en: 'Equatorial Guinea',
        kz: 'Экваторлық Гвинея',
      },
    },
    {
      value: '548',
      label: {
        ru: 'Вануату',
        en: 'Vanuatu',
        kz: 'Вануату',
      },
    },
    {
      value: '352',
      label: {
        ru: 'Исландия',
        en: 'Iceland',
        kz: 'Исландия',
      },
    },
    {
      value: '203',
      label: {
        ru: 'Чешская Республика',
        en: 'Czech Republic',
        kz: 'Чехия',
      },
    },
    {
      value: '652',
      label: {
        ru: 'Сен-Бартельми',
        en: 'Saint Barthélemy',
        kz: 'Сент-Бартелми',
      },
    },
    {
      value: '840',
      label: {
        ru: 'Соединенные Штаты',
        en: 'United States',
        kz: 'Америка Құрама Штаттары',
      },
    },
    {
      value: '834',
      label: {
        ru: 'Танзания, Объединенная Республика',
        en: 'Tanzania, United Republic Of',
        kz: 'Танзания, Біріккен Республика',
      },
    },
    {
      value: '036',
      label: {
        ru: 'Австралия',
        en: 'Australia',
        kz: 'Австралия',
      },
    },
    {
      value: '239',
      label: {
        ru: 'Южная Джорджия и Южные Сандвичевы острова',
        en: 'South Georgia and the South Sandwich Islands',
        kz: 'Оңтүстік Джорджия және Оңтүстік Сэндвич аралдары',
      },
    },
    {
      value: '585',
      label: {
        ru: 'Палау',
        en: 'Palau',
        kz: 'Палау',
      },
    },
    {
      value: '498',
      label: {
        ru: 'Молдова, Республика',
        en: 'Moldova',
        kz: 'Молдова, Республика',
      },
    },
    {
      value: '124',
      label: {
        ru: 'Канада',
        en: 'Canada',
        kz: 'Канада',
      },
    },
    {
      value: '756',
      label: {
        ru: 'Швейцария',
        en: 'Switzerland',
        kz: 'Швейцария',
      },
    },
    {
      value: '040',
      label: {
        ru: 'Австрия',
        en: 'Austria',
        kz: 'Австрия',
      },
    },
    {
      value: '663',
      label: {
        ru: 'Сен-Мартен',
        en: 'Saint Martin (French Part)',
        kz: 'Сент-Мартин',
      },
    },
    {
      value: '254',
      label: {
        ru: 'Французская Гвиана',
        en: 'French Guiana',
        kz: 'Француз Гвианасы',
      },
    },
    {
      value: '690',
      label: {
        ru: 'Сейшелы',
        en: 'Seychelles',
        kz: 'Сейшел аралдары',
      },
    },
    {
      value: '760',
      label: {
        ru: 'Сирийская Арабская Республика',
        en: 'Syrian Arab Republic',
        kz: 'Сирия Араб Республикасы',
      },
    },
    {
      value: '068',
      label: {
        ru: 'Боливия, Многонациональное Государство',
        en: 'Bolivia, plurinational state of',
        kz: 'Боливия, Көпұлтты Мемлекет',
      },
    },
    {
      value: '010',
      label: {
        ru: 'Антарктида',
        en: 'Antarctica',
        kz: 'Антарктида',
      },
    },
    {
      value: '660',
      label: {
        ru: 'Ангилья',
        en: 'Anguilla',
        kz: 'Ангилья',
      },
    },
    {
      value: '728',
      label: {
        ru: 'Южный Судан',
        en: 'South Sudan',
        kz: 'Оңтүстік Судан',
      },
    },
    {
      value: '710',
      label: {
        ru: 'Южная Африка',
        en: 'South Africa',
        kz: 'Оңтүстік Африка',
      },
    },
    {
      value: '268',
      label: {
        ru: 'Грузия',
        en: 'Georgia',
        kz: 'Грузия',
      },
    },
    {
      value: '184',
      label: {
        ru: 'Острова Кука',
        en: 'Cook Islands',
        kz: 'Кук аралдары',
      },
    },
    {
      value: '566',
      label: {
        ru: 'Нигерия',
        en: 'Nigeria',
        kz: 'Нигерия',
      },
    },
    {
      value: '768',
      label: {
        ru: 'Того',
        en: 'Togo',
        kz: 'Того',
      },
    },
    {
      value: '662',
      label: {
        ru: 'Сент-Люсия',
        en: 'Saint Lucia',
        kz: 'Сент-Люсия',
      },
    },
    {
      value: '642',
      label: {
        ru: 'Румыния',
        en: 'Romania',
        kz: 'Румыния',
      },
    },
    {
      value: '086',
      label: {
        ru: 'Британская территория в Индийском океане',
        en: 'British Indian Ocean Territory',
        kz: 'Үнді Мұхитындағы Британдық территория',
      },
    },
    {
      value: '670',
      label: {
        ru: 'Сент-Винсент и Гренадины',
        en: 'Saint Vincent and the Grenadines',
        kz: 'Сент-Винсент және Гренадиндер',
      },
    },
    {
      value: '056',
      label: {
        ru: 'Бельгия',
        en: 'Belgium',
        kz: 'Бельгия',
      },
    },
    {
      value: '678',
      label: {
        ru: 'Сан-Томе и Принсипи',
        en: 'Sao Tome and Principe',
        kz: 'Сан-Томе және Принсипи',
      },
    },
    {
      value: '795',
      label: {
        ru: 'Туркмения',
        en: 'Turkmenistan',
        kz: 'Түрікменстан',
      },
    },
    {
      value: '052',
      label: {
        ru: 'Барбадос',
        en: 'Barbados',
        kz: 'Барбадос',
      },
    },
    {
      value: '876',
      label: {
        ru: 'Уоллис и Футуна',
        en: 'Wallis and Futuna',
        kz: 'Уоллис Иутуна',
      },
    },
    {
      value: '688',
      label: {
        ru: 'Сербия',
        en: 'Serbia',
        kz: 'Сербия',
      },
    },
    {
      value: '242',
      label: {
        ru: 'Фиджи',
        en: 'Fiji',
        kz: 'Фидджи',
      },
    },
    {
      value: '762',
      label: {
        ru: 'Таджикистан',
        en: 'Tajikistan',
        kz: 'Тәжік-Стан',
      },
    },
    {
      value: '562',
      label: {
        ru: 'Нигер',
        en: 'Niger',
        kz: 'Нигер',
      },
    },
    {
      value: '729',
      label: {
        ru: 'Судан',
        en: 'Sudan',
        kz: 'Судан',
      },
    },
    {
      value: '858',
      label: {
        ru: 'Уругвай',
        en: 'Uruguay',
        kz: 'Уругвай',
      },
    },
    {
      value: '140',
      label: {
        ru: 'Центрально-Африканская Республика',
        en: 'Central African Republic',
        kz: 'Орталық Африка Республикасы',
      },
    },
    {
      value: '694',
      label: {
        ru: 'Сьерра-Леоне',
        en: 'Sierra Leone',
        kz: 'Серра-Леоне',
      },
    },
    {
      value: '504',
      label: {
        ru: 'Марокко',
        en: 'Morocco',
        kz: 'Марокко',
      },
    },
    {
      value: '654',
      label: {
        ru: 'Святая Елена, Остров вознесения, Тристан-да-Кунья',
        en: 'Saint Helena, Ascension And Tristan Da Cunha',
        kz: 'Әулие Елена, хабардарлық аралы, Тристан да Кунья',
      },
    },
    {
      value: '238',
      label: {
        ru: 'Фолклендские острова (Мальвинские)',
        en: 'Falkland Islands (Malvinas)',
        kz: 'Олкленд аралдары (Малвинский)',
      },
    },
    {
      value: '706',
      label: {
        ru: 'Сомали',
        en: 'Somalia',
        kz: 'Сомали',
      },
    },
    {
      value: '048',
      label: {
        ru: 'Бахрейн',
        en: 'Bahrain',
        kz: 'Барейн',
      },
    },
    {
      value: '772',
      label: {
        ru: 'Токелау',
        en: 'Tokelau',
        kz: 'Токелау',
      },
    },
    {
      value: '604',
      label: {
        ru: 'Перу',
        en: 'Peru',
        kz: 'Перу',
      },
    },
    {
      value: '744',
      label: {
        ru: 'Шпицберген и Ян Майен',
        en: 'Svalbard and Jan Mayen',
        kz: 'Пипберген және Ян Майен',
      },
    },
    {
      value: '826',
      label: {
        ru: 'Соединенное Королевство',
        en: 'United Kingdom',
        kz: 'Ұлыбритания',
      },
    },
    {
      value: '234',
      label: {
        ru: 'Фарерские острова',
        en: 'Faroe Islands',
        kz: 'Фарер аралдары',
      },
    },
    {
      value: '158',
      label: {
        ru: 'Тайвань (Қытай)',
        en: 'Taiwan, Province of China',
        kz: 'Тайвань (Қытай)',
      },
    },
    {
      value: '246',
      label: {
        ru: 'Финляндия',
        en: 'Finland',
        kz: 'Финляндия',
      },
    },
    {
      value: '292',
      label: {
        ru: 'Гибралтар',
        en: 'Gibraltar',
        kz: 'Гибралтар',
      },
    },
    {
      value: '191',
      label: {
        ru: 'Хорватия',
        en: 'Croatia',
        kz: 'Хорватия',
      },
    },
    {
      value: '534',
      label: {
        ru: 'Синт-Мартен',
        en: 'Sint Maarten',
        kz: 'Синт-Мартен',
      },
    },
    {
      value: '012',
      label: {
        ru: 'Алжир',
        en: 'Algeria',
        kz: 'Алжир',
      },
    },
    {
      value: '512',
      label: {
        ru: 'Оман',
        en: 'Oman',
        kz: 'Оман',
      },
    },
    {
      value: '580',
      label: {
        ru: 'Северные Марианские острова',
        en: 'Northern Mariana Islands',
        kz: 'Солтүстік Мариана аралдары',
      },
    },
    {
      value: '100',
      label: {
        ru: 'Болгария',
        en: 'Bulgaria',
        kz: 'Болгария',
      },
    },
    {
      value: '776',
      label: {
        ru: 'Тонга',
        en: 'Tonga',
        kz: 'Тонга',
      },
    },
    {
      value: '499',
      label: {
        ru: 'Черногория',
        en: 'Montenegro',
        kz: 'Черногория',
      },
    },
    {
      value: '624',
      label: {
        ru: 'Гвинея-Бисау',
        en: 'Guinea-Bissau',
        kz: 'Гвинея-Бисау',
      },
    },
    {
      value: '360',
      label: {
        ru: 'Индонезия',
        en: 'Indonesia',
        kz: 'Индонезия',
      },
    },
    {
      value: '703',
      label: {
        ru: 'Словакия',
        en: 'Slovakia',
        kz: 'Словакия',
      },
    },
    {
      value: '170',
      label: {
        ru: 'Колумбия',
        en: 'Colombia',
        kz: 'Колумбия',
      },
    },
    {
      value: '466',
      label: {
        ru: 'Мали',
        en: 'Mali',
        kz: 'Мали',
      },
    },
    {
      value: '620',
      label: {
        ru: 'Португалия',
        en: 'Portugal',
        kz: 'Португалия',
      },
    },
    {
      value: '780',
      label: {
        ru: 'Тринидад и Тобаго',
        en: 'Trinidad and Tobago',
        kz: 'Тринидад және Тобаго',
      },
    },
    {
      value: '702',
      label: {
        ru: 'Сингапур',
        en: 'Singapore',
        kz: 'Сингапур',
      },
    },
    {
      value: '531',
      label: {
        ru: 'Кюрасао',
        en: 'Curaçao',
        kz: 'Кюрасао',
      },
    },
    {
      value: '850',
      label: {
        ru: 'Виргинские острова, США',
        en: 'Virgin Islands, U.S.',
        kz: 'Виргин аралдары, АҚШ',
      },
    },
    {
      value: '260',
      label: {
        ru: 'Французские Южные территории',
        en: 'French Southern Territories',
        kz: 'Францияның оңтүстік территориялары',
      },
    },
    {
      value: '388',
      label: {
        ru: 'Ямайка',
        en: 'Jamaica',
        kz: 'Ямайка',
      },
    },
    {
      value: '659',
      label: {
        ru: 'Сент-Китс и Невис',
        en: 'Saint Kitts and Nevis',
        kz: 'Сент-Китс және Невис',
      },
    },
    {
      value: '340',
      label: {
        ru: 'Гондурас',
        en: 'Honduras',
        kz: 'Гондурас',
      },
    },
    {
      value: '348',
      label: {
        ru: 'Венгрия',
        en: 'Hungary',
        kz: 'Венгрия',
      },
    },
    {
      value: '008',
      label: {
        ru: 'Албания',
        en: 'Albania',
        kz: 'Албания',
      },
    },
    {
      value: '804',
      label: {
        ru: 'Украина',
        en: 'Ukraine',
        kz: 'Украина',
      },
    },
    {
      value: '796',
      label: {
        ru: 'Острова Теркс и Кайкос',
        en: 'Turks and Caicos Islands',
        kz: 'Түріктер мен Кайкос аралдары',
      },
    },
    {
      value: '032',
      label: {
        ru: 'Аргентина',
        en: 'Argentina',
        kz: 'Аргентина',
      },
    },
    {
      value: '050',
      label: {
        ru: 'Бангладеш',
        en: 'Bangladesh',
        kz: 'Бангладеш',
      },
    },
    {
      value: '833',
      label: {
        ru: 'Остров Мэн',
        en: 'Isle of Man',
        kz: 'Мэн аралы',
      },
    },
    {
      value: '028',
      label: {
        ru: 'Антигуа и Барбуда',
        en: 'Antigua and Barbuda',
        kz: 'Антигуа және Барбуда',
      },
    },
    {
      value: '212',
      label: {
        ru: 'Доминика',
        en: 'Dominica',
        kz: 'Доминика',
      },
    },
    {
      value: '070',
      label: {
        ru: 'Босния и Герцеговина',
        en: 'Bosnia and Herzegovina',
        kz: 'Босния және Герцеговина',
      },
    },
    {
      value: '500',
      label: {
        ru: 'Монтсеррат',
        en: 'Montserrat',
        kz: 'Монтсеррат',
      },
    },
    {
      value: '248',
      label: {
        ru: 'Эландские острова',
        en: 'Åland Islands',
        kz: 'Аланд аралдары',
      },
    },
    {
      value: '270',
      label: {
        ru: 'Гамбия',
        en: 'Gambia',
        kz: 'Гамбия',
      },
    },
    {
      value: '591',
      label: {
        ru: 'Панама',
        en: 'Panama',
        kz: 'Панама',
      },
    },
    {
      value: '156',
      label: {
        ru: 'Китай',
        en: 'China',
        kz: 'Қытай',
      },
    },
    {
      value: '204',
      label: {
        ru: 'Бенин',
        en: 'Benin',
        kz: 'Бенин',
      },
    },
    {
      value: '616',
      label: {
        ru: 'Польша',
        en: 'Poland',
        kz: 'Польша',
      },
    },
    {
      value: '788',
      label: {
        ru: 'Тунис',
        en: 'Tunisia',
        kz: 'Тунис',
      },
    },
    {
      value: '508',
      label: {
        ru: 'Мозамбик',
        en: 'Mozambique',
        kz: 'Мозамбик',
      },
    },
    {
      value: '132',
      label: {
        ru: 'Кабо-Верде',
        en: 'Cape Verde',
        kz: 'Кабо-Верде',
      },
    },
    {
      value: '470',
      label: {
        ru: 'Мальта',
        en: 'Malta',
        kz: 'Мальта',
      },
    },
    {
      value: '598',
      label: {
        ru: 'Папуа-Новая Гвинея',
        en: 'Papua New Guinea',
        kz: 'Папуа-Жаңа Гвинея',
      },
    },
    {
      value: '748',
      label: {
        ru: 'Свазиленд',
        en: 'Swaziland',
        kz: 'Свазиленд',
      },
    },
    {
      value: '120',
      label: {
        ru: 'Камерун',
        en: 'Cameroon',
        kz: 'Камерун',
      },
    },
    {
      value: '084',
      label: {
        ru: 'Белиз',
        en: 'Belize',
        kz: 'Белиз',
      },
    },
    {
      value: '090',
      label: {
        ru: 'Соломоновы острова',
        en: 'Solomon Islands',
        kz: 'Соломон аралдары',
      },
    },
    {
      value: '016',
      label: {
        ru: 'Американское Самоа',
        en: 'American Samoa',
        kz: 'Американдық Самоа',
      },
    },
    {
      value: '384',
      label: {
        ru: "Кот д'Ивуар",
        en: "Cote d'Ivoire",
        kz: "Кот-д 'Ивуар",
      },
    },
    {
      value: '540',
      label: {
        ru: 'Новая Каледония',
        en: 'New Caledonia',
        kz: 'Жаңа Каледония',
      },
    },
    {
      value: '304',
      label: {
        ru: 'Гренландия',
        en: 'Greenland',
        kz: 'Гренландия',
      },
    },
    {
      value: '372',
      label: {
        ru: 'Ирландия',
        en: 'Ireland',
        kz: 'Ирландия',
      },
    },
    {
      value: '214',
      label: {
        ru: 'Доминиканская Республика',
        en: 'Dominican Republic',
        kz: 'Доминикан Республикасы',
      },
    },
    {
      value: '024',
      label: {
        ru: 'Ангола',
        en: 'Angola',
        kz: 'Ангола',
      },
    },
    {
      value: '276',
      label: {
        ru: 'Германия',
        en: 'Germany',
        kz: 'Германия',
      },
    },
    {
      value: '178',
      label: {
        ru: 'Конго',
        en: 'Congo',
        kz: 'Конго',
      },
    },
    {
      value: '296',
      label: {
        ru: 'Кирибати',
        en: 'Kiribati',
        kz: 'Кирибати',
      },
    },
    {
      value: '682',
      label: {
        ru: 'Саудовская Аравия',
        en: 'Saudi Arabia',
        kz: 'Сауд Арабиясы',
      },
    },
    {
      value: '831',
      label: {
        ru: 'Гернси',
        en: 'Guernsey',
        kz: 'Гернси',
      },
    },
    {
      value: '328',
      label: {
        ru: 'Гайана',
        en: 'Guyana',
        kz: 'Гайана',
      },
    },
    {
      value: '275',
      label: {
        ru: 'Палестинская территория, оккупированная',
        en: 'Palestinian Territory, Occupied',
        kz: 'Палестина территориясы, тоқтатылды',
      },
    },
    {
      value: '060',
      label: {
        ru: 'Бермуды',
        en: 'Bermuda',
        kz: 'Бермуд аралдары',
      },
    },
    {
      value: '116',
      label: {
        ru: 'Камбоджа',
        en: 'Cambodia',
        kz: 'Камбоджа',
      },
    },
    {
      value: '666',
      label: {
        ru: 'Сент-Пьер и Микелон',
        en: 'Saint Pierre and Miquelon',
        kz: 'Сент-Пьер және Микелон',
      },
    },
    {
      value: '308',
      label: {
        ru: 'Гренада',
        en: 'Grenada',
        kz: 'Гренада',
      },
    },
    {
      value: '408',
      label: {
        ru: 'Корея, Народно-Демократическая Республика',
        en: "Korea, Democratic People's republic of",
        kz: 'Корея, Халықтық Демократиялық республика',
      },
    },
    {
      value: '854',
      label: {
        ru: 'Буркина-Фасо',
        en: 'Burkina Faso',
        kz: 'Буркина-Фасо',
      },
    },
    {
      value: '410',
      label: {
        ru: 'Корея, Республика',
        en: 'Korea, Republic of',
        kz: 'Корея, Республика',
      },
    },
    {
      value: '332',
      label: {
        ru: 'Гаити',
        en: 'Haiti',
        kz: 'Гаити',
      },
    },
    {
      value: '535',
      label: {
        ru: 'Бонайре, Саба и Синт-Эстатиус',
        en: 'Bonaire, Sint Eustatius and Saba',
        kz: 'Бонайре, Синт Эстатиус және Саба',
      },
    },
    {
      value: '418',
      label: {
        ru: 'Лаос',
        en: "Lao People's Democratic Republic",
        kz: 'Лаос Халықтық Демократиялық Республикасы',
      },
    },
    {
      value: '458',
      label: {
        ru: 'Малайзия',
        en: 'Malaysia',
        kz: 'Малайзия',
      },
    },
    {
      value: '196',
      label: {
        ru: 'Кипр',
        en: 'Cyprus',
        kz: 'Кипр',
      },
    },
    {
      value: '316',
      label: {
        ru: 'Гуам',
        en: 'Guam',
        kz: 'Гуам',
      },
    },
    {
      value: '092',
      label: {
        ru: 'Виргинские острова, Британские',
        en: 'Virgin Islands, British',
        kz: 'Виргин аралдары, Ұлыбритания',
      },
    },
    {
      value: '336',
      label: {
        ru: 'Папский Престол (Государство &mdash; город Ватикан)',
        en: 'Holy See (Vatican City State)',
        kz: 'Қасиетті Тақ (Ватикан қаласы)',
      },
    },
    {
      value: '434',
      label: {
        ru: 'Ливийская Арабская Джамахирия',
        en: 'Libyan Arab Jamahiriya',
        kz: 'Ливия Араб Жамахириясы',
      },
    },
    {
      value: '192',
      label: {
        ru: 'Куба',
        en: 'Cuba',
        kz: 'Куба',
      },
    },
    {
      value: '324',
      label: {
        ru: 'Гвинея',
        en: 'Guinea',
        kz: 'Гвинея',
      },
    },
    {
      value: '638',
      label: {
        ru: 'Реюньон',
        en: 'Reunion',
        kz: 'Реюньон',
      },
    },
    {
      value: '344',
      label: {
        ru: 'Гонконг',
        en: 'Hong Kong',
        kz: 'Гонконг',
      },
    },
    {
      value: '686',
      label: {
        ru: 'Сенегал',
        en: 'Senegal',
        kz: 'Сенегал',
      },
    },
    {
      value: '020',
      label: {
        ru: 'Андорра',
        en: 'Andorra',
        kz: 'Андорра',
      },
    },
    {
      value: '438',
      label: {
        ru: 'Лихтенштейн',
        en: 'Liechtenstein',
        kz: 'Литтен-Тейн',
      },
    },
    {
      value: '496',
      label: {
        ru: 'Монголия',
        en: 'Mongolia',
        kz: 'Моңғолия',
      },
    },
    {
      value: '258',
      label: {
        ru: 'Французская Полинезия',
        en: 'French Polynesia',
        kz: 'Француз Полинезиясы',
      },
    },
    {
      value: '674',
      label: {
        ru: 'Сан-Марино',
        en: 'San Marino',
        kz: 'Сан-Марино',
      },
    },
    {
      value: '152',
      label: {
        ru: 'Чили',
        en: 'Chile',
        kz: 'Чили',
      },
    },
    {
      value: '740',
      label: {
        ru: 'Суринам',
        en: 'Suriname',
        kz: 'Суринам',
      },
    },
    {
      value: '250',
      label: {
        ru: 'Франция',
        en: 'France',
        kz: 'Франция',
      },
    },
    {
      value: '233',
      label: {
        ru: 'Эстония',
        en: 'Estonia',
        kz: 'Эстония',
      },
    },
    {
      value: '516',
      label: {
        ru: 'Намибия',
        en: 'Namibia',
        kz: 'Намибия',
      },
    },
    {
      value: '798',
      label: {
        ru: 'Тувалу',
        en: 'Tuvalu',
        kz: 'Тувалу',
      },
    },
    {
      value: '896',
      label: {
        ru: 'Южная Осетия',
        en: 'South Ossetia',
        kz: 'Оңтүстік Осетия',
      },
    },
    {
      value: '862',
      label: {
        ru: 'Венесуэла Боливарианская Республика',
        en: 'Venezuela',
        kz: 'Венесуэла',
      },
    },
    {
      value: '752',
      label: {
        ru: 'Швеция',
        en: 'Sweden',
        kz: 'Швеция',
      },
    },
    {
      value: '586',
      label: {
        ru: 'Пакистан',
        en: 'Pakistan',
        kz: 'Пәкістан',
      },
    },
    {
      value: '524',
      label: {
        ru: 'Непал',
        en: 'Nepal',
        kz: 'Непал',
      },
    },
    {
      value: '312',
      label: {
        ru: 'Гваделупа',
        en: 'Guadeloupe',
        kz: 'Гвадалупа',
      },
    },
    {
      value: '882',
      label: {
        ru: 'Самоа',
        en: 'Samoa',
        kz: 'Самоа',
      },
    },
    {
      value: '380',
      label: {
        ru: 'Италия',
        en: 'Italy',
        kz: 'Италия',
      },
    },
    {
      value: '072',
      label: {
        ru: 'Ботсвана',
        en: 'Botswana',
        kz: 'Ботсвана',
      },
    },
    {
      value: '724',
      label: {
        ru: 'Испания',
        en: 'Spain',
        kz: 'Испания',
      },
    },
    {
      value: '480',
      label: {
        ru: 'Маврикий',
        en: 'Mauritius',
        kz: 'Маврикий',
      },
    },
    {
      value: '478',
      label: {
        ru: 'Мавритания',
        en: 'Mauritania',
        kz: 'Мавритания',
      },
    },
    {
      value: '860',
      label: {
        ru: 'Узбекистан',
        en: 'Uzbekistan',
        kz: 'Өзбекстан',
      },
    },
    {
      value: '004',
      label: {
        ru: 'Афганистан',
        en: 'Afghanistan',
        kz: 'Ауғанстан',
      },
    },
    {
      value: '704',
      label: {
        ru: 'Вьетнам',
        en: 'Vietnam',
        kz: 'Вьетнам',
      },
    },
    {
      value: '484',
      label: {
        ru: 'Мексика',
        en: 'Mexico',
        kz: 'Мексика',
      },
    },
    {
      value: '231',
      label: {
        ru: 'Эфиопия',
        en: 'Ethiopia',
        kz: 'Эфиопия',
      },
    },
    {
      value: '266',
      label: {
        ru: 'Габон',
        en: 'Gabon',
        kz: 'Габон',
      },
    },
    {
      value: '076',
      label: {
        ru: 'Бразилия',
        en: 'Brazil',
        kz: 'Бразилия',
      },
    },
    {
      value: '188',
      label: {
        ru: 'Коста-Рика',
        en: 'Costa Rica',
        kz: 'Коста-Рика',
      },
    },
    {
      value: '044',
      label: {
        ru: 'Багамы',
        en: 'Bahamas',
        kz: 'Багам аралдары',
      },
    },
    {
      value: '136',
      label: {
        ru: 'Острова Кайман',
        en: 'Cayman Islands',
        kz: 'Кайман аралдары',
      },
    },
    {
      value: '364',
      label: {
        ru: 'Иран, Исламская Республика',
        en: 'Iran, Islamic Republic of',
        kz: 'Иран, Ислам Республикасы',
      },
    },
    {
      value: '208',
      label: {
        ru: 'Дания',
        en: 'Denmark',
        kz: 'Дания',
      },
    },
    {
      value: '608',
      label: {
        ru: 'Филиппины',
        en: 'Philippines',
        kz: 'Филиппиндер',
      },
    },
    {
      value: '108',
      label: {
        ru: 'Бурунди',
        en: 'Burundi',
        kz: 'Бурунди',
      },
    },
    {
      value: '894',
      label: {
        ru: 'Замбия',
        en: 'Zambia',
        kz: 'Замбия',
      },
    },
    {
      value: '112',
      label: {
        ru: 'Беларусь',
        en: 'Belarus',
        kz: 'Беларусь',
      },
    },
    {
      value: '398',
      label: {
        ru: 'Казахстан',
        en: 'Kazakhstan',
        kz: 'Қазақстан',
      },
    },
    {
      value: '832',
      label: {
        ru: 'Джерси',
        en: 'Jersey',
        kz: 'Джерси',
      },
    },
    {
      value: '643',
      label: {
        ru: 'Россия',
        en: 'Russian Federation',
        kz: 'Ресей Федерациясы',
      },
    },
    {
      value: '417',
      label: {
        ru: 'Кыргызстан',
        en: 'Kyrgyzstan',
        kz: 'Қырғызстан',
      },
    },
    {
      value: '166',
      label: {
        ru: 'Кокосовые (Килинг) острова',
        en: 'Cocos (Keeling) Islands',
        kz: 'Кокос (Килинг) аралдары',
      },
    },
  ]