import React, { useState } from 'react'
import { Calendar } from 'react-date-range'
import { useOutsideClick } from '../../utils/useOutsideClick'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import ru from 'date-fns/locale/ru'
import kk from "date-fns/locale/kk";
import en from "date-fns/locale/en-US"
import dayjs from 'dayjs'
import cn from 'classnames'
import {IMask, IMaskInput} from "react-imask"
import moment, {Moment} from "moment"
import styles from './DateInput.module.scss'

export interface LabeledInputProps {
    label?: string | null;
    children?: React.ReactNode;
    className?: string;
    errorBorder?: boolean;
    propRegister?: any;
    error?: string | false;
    mask?: string;
    disabled?: boolean;
    required?: boolean;
    value: string;
    onChange: (e: string) => void
    curr?: Date;
    min?: Date;
    max?: Date;
    lang: string;
    name: string;
    [prop: string]: any;
}
function DateInput({ label, errorBorder, propRegister, error, mask, disabled, required, value, onChange, curr, min, max, lang, name, ...props }: LabeledInputProps) {
    const [CalendarValue, setCalendarValue] = useState(
        value
            ? value?.slice(8, 10) +
            '.' +
            value?.slice(5, 7) +
            '.' +
            value?.slice(0, 4)
            : ''
    )
    const [OpenCalendar, setOpenCalendar] = useState(false)

    const ref = useOutsideClick(() => {
        setOpenCalendar(false)
    })

    // On change handler
    const handleChange = (newValue: Date) => {
        if (newValue) {
            let result = dayjs(newValue).format('YYYY-MM-DD')
            setCalendarValue(dayjs(newValue).format('DD.MM.YYYY'))
            onChange(result)
        }
    }
    const langs: Record<string, {format: string; inputFormat: string; pickerLocal: any; chars: {D:string,M:string,Y:string}}> = {
        ru: {
          format: "DD.MM.YYYY",
          inputFormat: "99.99.9999",
          pickerLocal: ru,
          chars: {
            D: "д",
            M: "м",
            Y: "г",
          },
        },
        en: {
          format: "MM-DD-YYYY",
          inputFormat: "99-99-9999",
          pickerLocal: en,
          chars: {
            D: "d",
            M: "m",
            Y: "y",
          },
        },
        kz: {
          format: "DD.MM.YYYY",
          inputFormat: "99.99.9999",
          pickerLocal: kk,
          chars: {
            D: "д",
            M: "м",
            Y: "г",
          },
        },
      }
    const blocks = {
        date: {
          mask: IMask.MaskedRange,
          placeholderChar: langs?.[lang]?.chars?.D,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        month: {
          mask: IMask.MaskedRange,
          placeholderChar: langs?.[lang]?.chars?.M,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        year: {
          mask: IMask.MaskedRange,
          placeholderChar: langs?.[lang]?.chars?.Y,
          from: min?.getFullYear()??1900,
          to: max?.getFullYear()??2010
        },
      }
    
      const imaskOptions = {
        mask: Date,
        pattern: langs[lang]?.format,
        lazy: false,
        autofix: false,
        overwrite: false,
        blocks: {
          DD: blocks.date,
          MM: blocks.month,
          YYYY: blocks.year,
        },
        format: function (date: any) {
          return moment(date).format(langs[lang].format)
        },
        parse: function (string: string) {
          return moment(string, langs[lang].format).toDate()
        },
      }
    return (
        <div className={styles.DateInput}>
            <label>{label}{required && <span className={styles.required}>*</span>}</label>
            <div>
                <IMaskInput
                    name={name}
                    data-test-id={props.testName}
                    className={cn(styles.input, errorBorder ? styles.errorBorder : "", disabled ? styles.disabled : "")}
                    onClick={() => {
                        setOpenCalendar(true)
                    }}
                    onAccept={(e) => {
                      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
                      handleChange(new Date(e.replace(pattern,'$3-$2-$1')))
                      setCalendarValue(e)
                    }}
                    value={CalendarValue}
                    placeholder={props.placeholder}
                    disabled={disabled}
                    {...imaskOptions} 
                />
                {OpenCalendar && (
                    <div ref={ref}>
                        <Calendar
                            data-test-id={props.testName}
                            className={styles.Calendar}
                            locale={lang === 'en' ? en : lang === 'kz' ? kk : ru}
                            onChange={(date: Date) => {
                                handleChange(date)
                                setOpenCalendar(false)
                            }}
                            shownDate={curr}
                            minDate={min}
                            maxDate={max}
                        ></Calendar>
                    </div>
                )}
            </div>
            {error && <span className={styles.red_color_text}>{error && error.toString()}</span>}
        </div>
    )
}

export default DateInput
