import React, { TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import styles from "./TextArea.module.scss";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import cn from "classnames";

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string | null;
  errorBorder?: boolean;
  propRegister?: any;
  error?: string | false;
  mask?: string;
  disabled?: boolean;
  required?: boolean;
  name: string
  [prop: string]: any;
}

function TextArea({ label, errorBorder, propRegister, error, mask, disabled, required, name, ...props }: TextAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  return (
    <div>
      {label && <label>{label}{required && <span className={styles.required}>*</span>}</label>}
      <div
        className={cn(styles.labeled_textarea, errorBorder ? styles.errorBorder : "", disabled ? styles.disabled : "")}
      >
        {!propRegister && (
          <textarea
            {...props}
            ref={textareaRef}
            disabled={disabled}
            name={name}
          />
        )}
        {propRegister && (
          <textarea
            {...propRegister}
            {...props}
            ref={textareaRef}
            disabled={disabled}
            name={name}
          />
        )}
      </div>
      {error && <span className={styles.red_color_text}>{error && error.toString()}</span>}
    </div>
  );
}

export default TextArea;
