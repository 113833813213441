import React from "react";
import styles from "./SubmitMessage.module.scss";
import greenCheckMark from "../../static/green-check-mark.svg";
import errorIcon from "../../static/error-img.svg";
import { Title } from "../../content/title";

export interface SubmitMessageProps {
  status: string;
  lang: string;
}

function SubmitMessage({ status, lang }: SubmitMessageProps) {
  return (
    <div className="row">
      <div className="col12">
        {status === "success" ? (
          <div className={styles.SubmitMessage}>
            <div className={styles.ConfirmRemember__imageBox}>
              <img src={greenCheckMark} alt="success" />
            </div>
            <h2 className={styles.ConfirmRemember__h2}>{Title.successSubmitHeader[lang]}</h2>
            <p className={styles.ConfirmRemember__text}>{Title.successSubmitMessage[lang]}</p>
          </div>
        ) : (
          <div className={styles.SubmitMessage}>
            <div className={styles.ConfirmRemember__imageBox}>
              <img src={errorIcon} alt="error" />
            </div>
            <h2 className={styles.ConfirmRemember__h2}>{Title.errorSubmitHeader[lang]}</h2>
            <p className={styles.ConfirmRemember__text}>{Title.errorSubmitMessage[lang]}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubmitMessage;
