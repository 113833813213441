import React, { InputHTMLAttributes } from "react";
import styles from "./CheckBox.module.scss";
import cn from 'classnames'

export interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null;
  active: boolean;
  onClick: any;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  name?: string;
}

function CheckBox({ label, active, onClick, disabled, required, className, name }: LabeledInputProps) {
  return (
    <>
      <button name={name} disabled={disabled} type="button" className={cn(styles.button, className)} onClick={onClick}>
        {active ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1372_15007)">
              <rect width="24" height="24" rx="8" fill="#5795FD" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7304 7.31701C18.1076 7.72041 18.0864 8.35322 17.683 8.73043L11.058 16.7304C10.6736 17.0898 10.0765 17.0899 9.69209 16.7305L6.31709 13.6405C5.91365 13.2633 5.89234 12.6305 6.2695 12.2271C6.64667 11.8236 7.27947 11.8023 7.68292 12.1795L10.3749 14.631L16.317 7.26958C16.7204 6.89237 17.3532 6.91361 17.7304 7.31701Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1372_15007">
                <rect width="24" height="24" rx="8" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="white" />
            <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" stroke="#D4D4D4" />
          </svg>
        )}
        <span>{label}{required && <span className={styles.required}>*</span>}</span>
      </button>
    </>
  );
}

export default CheckBox;
