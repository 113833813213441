import ErrorsTypes from "../types/errors-type";

export const Errors: ErrorsTypes = {
  error: {
    ru: "Ошибка",
    en: "Error",
    kz: "Ошибка",
  },
  photo: {
    ru: "Загрузите фото",
    en: "Upload photo",
    kz: "Фото",
  },
  photoUploadTooLarge: {
    ru: "Файл слишком большой, максимум 20 Мб",
    en: "The file is too large, maximum 20 MB",
    kz: "Файл слишком большой, максимум 20 Мб",
  },
  lastName: { ru: "Укажите фамилию", en: "Please enter your last name", kz: "Фамилияңызды енгізіңіз" },
  firstName: { ru: "Укажите имя", en: "Please enter your first name", kz: "Атын енгізіңіз" },
  patronymic: { ru: "Укажите отчество", en: "Please enter your patronymic", kz: "Әкесінің атын енгізіңіз" },
  birthDate: { ru: "Укажите дату рождения", en: "Please enter your date of birth", kz: "Туған күнін енгізіңіз" },
  phoneNumber: { ru: "Укажите номер телефона", en: "Please enter your phone number", kz: "Телефон нөміріңізді енгізіңіз" },
  citizenship: { ru: "Укажите гражданство", en: "Please enter your country", kz: "Азаматтығын көрсетіңіз" },
  country: { ru: "Укажите страну", en: "Specify the country", kz: "Елді көрсетіңіз" },
  city: { ru: "Укажите город проживания", en: "Please enter your city of residence", kz: "Тұрғылықты қалаңызды енгізіңіз" },
  region: { ru: "Укажите регион проживания", en: "Specify the region of residence", kz: "Тұрғылықты жеріңізді көрсетіңіз" },
  district: { ru: "Укажите район", en: "Please enter your district", kz: "Ауданды көрсетіңіз" },
  email: { ru: "Некорректный адрес электронной почты", en: "Incorrect email address", kz: "Қате электрондық пошта мекенжайы" },
  socialLinkType: { ru: "Выберите социальную сесть", en: "Choose a social network", kz: "Әлеуметтік желіні таңдаңыз" },
  socialLink: { ru: "Некорректная ссылка", en: "Incorrect link", kz: "Қате сілтеме" },
  messengersType: { ru: "Выберите мессенджер", en: "Choose a messenger", kz: "Мессенджерді таңдаңыз" },
  messengersLink: { ru: "Некорректная ссылка", en: "Incorrect link", kz: "Қате сілтеме" },
  collegeName: { ru: "Заполните поле (не более 200 символов)", en: "Fill in the field (no more than 200 characters)", kz: "Өрісті толтырыңыз (200 таңбадан аспайды)" },
  qualification: { ru: "Заполните поле (не более 200 символов)", en: "Fill in the field (no more than 200 characters)", kz: "Өрісті толтырыңыз (200 таңбадан аспайды)" },
  workExperienceDescription: { ru: "Поле должно содержать не менее 10 символов", en: "The field must contain at least 10 characters", kz: "Өрісте кемінде 10 таңба болуы керек" },
  descriptionExperience: { ru: "Заполните поле", en: "Fill in the field", kz: "Өрісті толтырыңыз" },
  workExperienceYears: {ru: "Заполните поле", en: "Fill in the field", kz: "Өрісті толтырыңыз"},
  resumeLink: { ru: "Некорректная ссылка", en: "Incorrect link", kz: "Қате сілтеме" },
  portfolioLink: { ru: "Некорректная ссылка", en: "Incorrect link", kz: "Қате сілтеме" },
  coveringLetter: { ru: "Поле должно содержать не менее 10 символов", en: "The field must contain at least 10 characters", kz: "Өрісте кемінде 10 таңба болуы керек" },
  informationSource: { ru: "Заполните поле", en: "Fill in the field", kz: "Өрісті толтырыңыз" },
};
