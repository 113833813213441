import React from "react";
import RadioButton from "../inputs/RadioButton";
import TextArea from "../inputs/TextArea";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import styles from "./Education.module.scss";
import gs from "../../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";
import { Errors } from "../../content/errors";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
}

function Education({ lang, state, invalidFields, dispatch, disabled }: Props) {
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={styles.educationTypeButtonBox}>
          <span className={gs.header}>{Title.educationTitle[lang]}</span>
          <RadioButton
            disabled={disabled}
            label={Fields.higherEducation?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.EDUCATION_TYPE,
                data: 1,
              });
            }}
            active={state.educationType === 1}
          />
          <RadioButton
            disabled={disabled}
            label={Fields.vocationalEducation?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.EDUCATION_TYPE,
                data: 3,
              });
            }}
            active={state.educationType === 3}
          />
          <RadioButton
            disabled={disabled}
            label={Fields.secondaryEducation?.label[lang]}
            onClick={() => {
              dispatch({
                type: actions.EDUCATION_TYPE,
                data: 2,
              });
            }}
            active={state.educationType === 2}
          />
        </div>
      </div>
      <div className={gs.col12}>
        <TextArea
          name={"collegeName"}
          disabled={disabled}
          label={Fields.educationName?.label[lang]}
          onChange={(e) => {
            dispatch({
              type: actions.COLLEGE_NAME,
              data: e.target.value,
            });
          }}
          value={state.collegeName}
          required={true}
          rows={5}
          errorBorder={invalidFields.includes("collegeName")}
          error={invalidFields.includes("collegeName") && Errors.collegeName[lang]}
        />
      </div>
      <div className={gs.col12}>
        <TextArea
          name={"qualification"}
          disabled={disabled}
          label={Fields.qualification?.label[lang]}
          onChange={(e) => {
            dispatch({
              type: actions.QUALIFICATION,
              data: e.target.value,
            });
          }}
          value={state.qualification}
          rows={3}
          errorBorder={invalidFields.includes("qualification")}
          error={invalidFields.includes("qualification") && Errors.qualification[lang]}
        />
      </div>
    </div>
  );
}

export default Education;
