import FieldsTypes from "../types/fields-types";

export const Fields: FieldsTypes = {
  photo: {
    label: { ru: "Загрузить фото", en: "Upload a photo", kz: "Фотосуретті жүктеу" },
    placeholder: { ru: "JPG, PNG до 20 Мб", en: "JPG, PNG under 20 MB", kz: "JPG, PNG до 20 Мб" },
  },
  lastName: {
    label: { ru: "Фамилия", en: "Lastname", kz: "Тегі" },
  },
  firstName: {
    label: { ru: "Имя", en: "Firstname", kz: "Аты" },
  },
  patronymic: {
    label: { ru: "Отчество", en: "Patronymic", kz: "Әкесінің аты" },
  },
  birthDate: {
    label: { ru: "Дата рождения", en: "Date of Birth", kz: "Туған күні" },
  },
  phoneNumber: {
    label: { ru: "Телефон", en: "Phone", kz: "Телефон" },
  },
  phoneSecond: {
    label: { ru: "Дополнительный телефон", en: "Additional phone number", kz: "Қосымша телефон" },
  },
  citizenship: {
    label: { ru: "Гражданство", en: "Citizenship", kz: "Азаматтық" },
  },
  country: {
    label: { ru: "Страна", en: "Country", kz: "Ел" },
  },
  region: {
    label: { ru: "Регион", en: "Region", kz: "Аймақ" },
  },
  city: {
    label: { ru: "Город проживания", en: "City of residence", kz: "Тұрғылықты қала" },
  },
  district: {
    label: { ru: "Район проживания", en: "District of residence", kz: "Тұрғылықты жері" },
  },
  email: {
    label: { ru: "Email", en: "Email", kz: "Email" },
  },
  messengerType: {
    label: { ru: "Мессенджер", en: "Messenger", kz: "Мессенджер" },
  },
  socialLinkType: {
    label: { ru: "Соц сеть", en: "Social network", kz: "Желіге сілтеме" },
  },
  socialLink: {
    label: { ru: "Ссылка на социальную сеть", en: "Link to social network", kz: "Әлеуметтік желіге сілтеме" },
  },
  vk: {
    value: "1",
    label: { ru: "VK", en: "VK", kz: "VK" },
  },
  fb: {
    value: "2",
    label: { ru: "Facebook", en: "Facebook", kz: "Facebook" },
  },
  youtube: {
    value: "3",
    label: { ru: "Youtube", en: "Youtube", kz: "Youtube" },
  },
  vimeo: {
    value: "4",
    label: { ru: "Vimeo", en: "Vimeo", kz: "Vimeo" },
  },
  instagram: {
    value: "5",
    label: { ru: "Instagram", en: "Instagram", kz: "Instagram" },
  },
  worksite: {
    value: "6",
    label: { ru: "Рабочий сайт", en: "Work site", kz: "Work site" },
  },
  website: {
    value: "7",
    label: { ru: "Веб-сайт", en: "Web site", kz: "Web site" },
  },
  another: {
    value: "8",
    label: { ru: "Другое", en: "Another", kz: "Басқа" },
  },
  telegram: {
    value: "9",
    label: { ru: "Telegram", en: "Telegram", kz: "Telegram" },
  },
  whatsapp: {
    value: "10",
    label: { ru: "Whatsapp", en: "Whatsapp", kz: "Whatsapp" },
  },
  viber: {
    value: "11",
    label: { ru: "Viber", en: "Viber", kz: "Viber" },
  },
  ok: {
    value: "12",
    label: { ru: "Одноклассники", en: "OK", kz: "OK" },
  },
  twitter: {
    value: "13",
    label: { ru: "X (Twitter)", en: "X (Twitter)", kz: "X (Twitter)" },
  },
  skype: {
    value: "14",
    label: { ru: "Skype", en: "Skype", kz: "Skype" },
  },
  notChosen: {
    value: "0",
    label: { ru: "Не выбрано", en: "Not chosen", kz: "Таңдалмаған" },
  },
  preferredCommunicationType: {
    label: { ru: "Удобный способ связи", en: "A convenient way of communication", kz: "Байланыстың ыңғайлы тәсілі" },
  },
  preferredCommunicationTime: {
    label: { ru: "Удобное время для разговора", en: "A convenient time to talk", kz: "Сөйлесуге ыңғайлы уақыт" },
  },
  voiceAndText: {
    value: '0',
    label: { ru: "Удобно говорить и писать", en: "It is convenient to speak and write", kz: "Сөйлеуге және жазуға ыңғайлы" },
  },
  voice: {
    value: '1',
    label: { ru: "Удобно говорить", en: "It's convenient to talk", kz: "Сөйлеуге ыңғайлы" },
  },
  other: {
    value: '0',
    label: { ru: "Другое", en: "Other", kz: "Басқа" },
  },
  fromFriend: {
    value: '1',
    label: { ru: "От друга", en: "From friend", kz: "Досынан" },
  },
  fromSocialNetworks: {
    value: '2',
    label: { ru: "Из соцсетей", en: "From social networks", kz: "Әлеуметтік желілерден" },
  },
  fromSite: {
    value: '3',
    label: { ru: "Сайт поиска работы", en: "From site", kz: "Жұмыс іздеу сайты" },
  },
  text: {
    value: '2',
    label: { ru: "Удобно писать", en: "It is convenient to write", kz: "Жазуға ыңғайлы" },
  },
  morning: {
    value: '1',
    label: { ru: "Утром с 8:00 до 12:00", en: "In the morning from 8:00 to 12:00", kz: "Таңертең 8:00-ден 12:00-ге дейін" },
  },
  day: {
    value: '2',
    label: { ru: "Днем с 12:00 до 18:00", en: "In the afternoon from 12:00 to 18:00", kz: "Түстен кейін 12:00-ден 18:00-ге дейін" },
  },
  evening: {
    value: '3',
    label: { ru: "Вечером с 18:00 до 23:00", en: "In the evening from 18:00 to 23:00", kz: "Кешке 18:00-ден 23:00-ге дейін" },
  },
  night: {
    value: '4',
    label: { ru: "Ночью с 23:00 до 07:00", en: "At night from 23:00 to 07:00", kz: "Түнде 23:00-ден 07:00-ге дейін" },
  },
  male: {
    label: { ru: "Мужской", en: "Male", kz: "Еркек" },
  },
  female: {
    label: { ru: "Женский", en: "Female", kz: "Әйел" },
  },
  unmarriedM: {
    label: { ru: "Не женат", en: "Single", kz: "Бойдақ, Күйеуге шықпаған" },
  },
  marriedM: {
    label: { ru: "Женат", en: "Married", kz: "Үйленген, Күйеуге шыққан" },
  },
  unmarriedF: {
    label: { ru: "Не замужем", en: "Single", kz: "Бойдақ, Күйеуге шықпаған" },
  },
  marriedF: {
    label: { ru: "Замужем", en: "Married", kz: "Үйленген, Күйеуге шыққан" },
  },
  noExpirience: {
    label: { ru: "Без опыта", en: "Without experience", kz: "Тәжірибе жоқ" },
  },
  hasACar: {
    label: { ru: "У меня есть личный автомобиль", en: "I have a personal car", kz: "Менің жеке көлігім бар" },
  },
  anotherCity: {
    label: { ru: "Живу в другом городе, готов к переезду", en: "I live in another city, ready to move", kz: "Мен басқа қалада тұрамын, көшуге дайынмын" },
  },
  driverA: {
    label: {
      ru: "Водительское удостоверение кат. А",
      en: "Driver’s license cat. A",
      kz: "Жүргізуші куәлігі A санаты",
    },
  },
  driverB: {
    label: {
      ru: "Водительское удостоверение кат. B",
      en: "Driver’s license cat. B",
      kz: "Жүргізуші куәлігі B санаты",
    },
  },
  driverC: {
    label: {
      ru: "Водительское удостоверение кат. C",
      en: "Driver’s license cat. C",
      kz: "Жүргізуші куәлігі C санаты",
    },
  },
  driverD: {
    label: {
      ru: "Водительское удостоверение кат. D",
      en: "Driver’s license cat. D",
      kz: "Жүргізуші куәлігі D санаты",
    },
  },
  RUS: {
    label: {
      ru: "Русский язык",
      en: "Russian language",
      kz: "Орыс тілі",
    },
  },
  ENG: {
    label: {
      ru: "Английский язык",
      en: "English language",
      kz: "Ағылшын тілі",
    },
  },
  KZ: {
    label: {
      ru: "Казахский язык",
      en: "Kazakh language",
      kz: "Қазақ тілі",
    },
  },
  higherEducation: {
    label: { ru: "Высшее", en: "Higher", kz: "Жоғарғы білім" },
  },
  vocationalEducation: {
    label: { ru: "Среднее профессиональное", en: "Secondary vocational", kz: "Орта кәсіптік" },
  },
  secondaryEducation: {
    label: { ru: "Среднее общее", en: "Secondary", kz: "Орта білім" },
  },
  educationName: {
    label: { ru: "Название учебного заведения и специальность", en: "Name of the educational institution and specialty", kz: "Оқу орнының атауы және мамандығы" },
  },
  qualification: {
    label: { ru: "Повышение квалификации", en: "Professional development", kz: "Біліктілікті арттыру" },
  },
  noExperience: {
    label: { ru: "Без опыта", en: "Without experience", kz: "Тәжірибесіз" },
  },
  yearExperience: {
    label: { ru: "Опыт работы (сколько лет)", en: "Work experience (how many years)", kz: "Жұмыс тәжірибесі (қанша жыл)" },
  },
  descriptionExperience: {
    label: { ru: "Опишите кратко Ваше последнее место работы", en: "Briefly describe your last job", kz: "Соңғы жұмысыңызды қысқаша сипаттаңыз" },
  },
  resumeLink: {
    label: { ru: "Ссылка на резюме", en: "Link to resume", kz: "Жалғастыру үшін сілтеме" },
  },
  portfolioLink: {
    label: { ru: "Ссылка на порфтолио", en: "Portfolio Link", kz: "Портфолио сілтемесі" },
  },
  coveringLetter: {
    label: { ru: "Почему вы хотите у нас работать", en: "Why do you want to work with us", kz: "Неліктен бізбен жұмыс істегіңіз келеді?" },
  },
  informationSource: {
    label: { ru: "Откуда узнали о вакансии", en: "How did you find out about the vacancy", kz: "Жұмыс туралы қайдан білдіңіз" },
  },
  informationSourceDetails: {
    label: { ru: "Укажите откуда узнали", en: "Indicate where you learned it from", kz: "Қайдан білгеніңізді көрсетіңіз" },
  },
  agreeMessage: {
    label: { ru: "Я согласен на использование персональных данных", en: "I agree to the use of personal data", kz: "Мен жеке деректерді пайдалануға келісемін" },
  },
  submit: {
    label: { ru: "Отправить", en: "Submit", kz: "Жіберу" },
  },
};
