import { L10n } from "src/types/common-types"

const citizenships:{value: string, label:L10n}[] = [{
    value: '643',
    label: {
      ru: 'Россия',
      en: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  {
    value: '398',
    label: {
      ru: 'Казахстан',
      en: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  {
    value: '112',
    label: {
      ru: 'Беларусь',
      en: 'Belarus',
      kz: 'Беларусь',
    },
  }]

export default citizenships