import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import styles from "./Contacts.module.scss";
import gs from "../../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";
import { Errors } from "../../content/errors";
import LabeledInput from "../inputs/LabeledInput";
import { validateEmail, validatePhone } from "../../validate/validator";
import CheckBox from "../inputs/CheckBox";
import PhoneInput from "../inputs/PhoneInput";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
}

function Contacts({ lang, state, invalidFields, dispatch, disabled }: Props) {
  return (<>
    <div className={gs.row}>
        <div className={gs.col12}>
            <span className={gs.header}>{Title.contactsTitle[lang]}</span>
        </div>
        <div className={gs.col6}>
            <PhoneInput
                name={"phone"}
                disabled={disabled}
                label={Fields.phoneNumber?.label[lang]}
                onChange={(e) => {
                    dispatch({
                    type: actions.PHONE,
                    data: e,
                    });
                    dispatch({
                        type: actions.MESSENGERS,
                        data: state.messengers.map((item, i) => {
                            return (state.telegramCheck && item.type === Fields.telegram.value ||
                                state.whatsappCheck && item.type === Fields.whatsapp.value ||
                                state.viberCheck && item.type === Fields.viber.value) ? {
                                    type: item.type,
                                    description: item.description,
                                    link: state.telegramCheck ?
                                        `https://t.me/+${e.replace(/[\D]/gim, '')}` :
                                        state.whatsappCheck ? 
                                        `https://wa.me/${e.replace(/[\D]/gim, '')}` :
                                        state.viberCheck ?
                                        `viber://chat?number=%2B${e.replace(/[\D]/gim, '')}` : ''
                            } : item
                        })
                    })
                    if (e.length === 0) {
                        dispatch({
                            type: actions.TELEGRAM_CHECK,
                            data: false
                        })
                        dispatch({
                            type: actions.WHATSAPP_CHECK,
                            data: false
                        })
                        dispatch({
                            type: actions.VIBER_CHECK,
                            data: false
                        });
                        dispatch({
                            type: actions.MESSENGERS,
                            data: state.messengers.map((item, i) => {
                                return (state.telegramCheck && item.type === Fields.telegram.value ||
                                    state.whatsappCheck && item.type === Fields.whatsapp.value ||
                                    state.viberCheck && item.type === Fields.viber.value) ? {
                                        type: item.type,
                                        description: item.description,
                                        link: ''
                                } : item
                            })
                        })
                    }
                }}
                value={state.phone}
                required={true}
                errorBorder={invalidFields.includes("phone")}
                error={invalidFields.includes("phone") && Errors.phoneNumber[lang]}
            />
        </div>
    </div>
    { validatePhone(state.phone.replace(/[^0-9,\s]/g,"")) && <div className={gs.row}>
        <div className={gs.col6}>
            <span className={styles.title}>{Title.contactsMessengersTitle[lang]}</span>
            <div className={styles.messengersCheck}>
                <CheckBox
                    label={Fields.telegram?.label[lang]}
                    onClick={() => {
                        dispatch({
                            type: actions.TELEGRAM_CHECK,
                            data: !state.telegramCheck
                        });
                        !state.telegramCheck ? 
                            dispatch({
                                type: actions.MESSENGERS,
                                data: state.messengers.find((e)=> { return e.type === Fields.telegram.value})
                                    ? state.messengers.map((item) => {
                                        return (item.type === Fields.telegram.value) ? {
                                            type: Fields.telegram.value,
                                            description: Fields.telegram?.label[lang],
                                            link: `https://t.me/+${state.phone.replace(/[\D]/gim, '')}`,
                                        } : item
                                    }) : state.messengers.concat(
                                        {
                                            type: Fields.telegram.value,
                                            description: Fields.telegram?.label[lang],
                                            link: `https://t.me/+${state.phone.replace(/[\D]/gim, '')}`,
                                        },
                                    ),
                            })
                        : dispatch({
                            type: actions.MESSENGERS,
                            data: state.messengers.filter((item)=> {
                                return item.type !== Fields.telegram.value
                            }),
                        })
                    }}
                    active={state.telegramCheck}
                />
                <CheckBox
                    label={Fields.whatsapp?.label[lang]}
                    onClick={() => {
                        dispatch({
                            type: actions.WHATSAPP_CHECK,
                            data: !state.whatsappCheck
                        });
                        !state.whatsappCheck ? 
                            dispatch({
                                type: actions.MESSENGERS,
                                data: state.messengers.find((e)=> { return e.type === Fields.whatsapp.value})
                                    ? state.messengers.map((item) => {
                                        return (item.type === Fields.whatsapp.value) ? {
                                            type: Fields.whatsapp.value,
                                            description: Fields.whatsapp?.label[lang],
                                            link: `https://wa.me/${state.phone.replace(/[\D]/gim, '')}`,
                                        } : item
                                    }) : state.messengers.concat(
                                        {
                                            type: Fields.whatsapp.value,
                                            description: Fields.whatsapp?.label[lang],
                                            link: `https://wa.me/${state.phone.replace(/[\D]/gim, '')}`,
                                        },
                                    ),
                            })
                        : dispatch({
                            type: actions.MESSENGERS,
                            data: state.messengers.filter((item)=> {
                                return item.type !== Fields.whatsapp.value
                            }),
                        })
                    }}
                    active={state.whatsappCheck}
                />
                <CheckBox
                    label={Fields.viber?.label[lang]}
                    onClick={() => {
                        dispatch({
                            type: actions.VIBER_CHECK,
                            data: !state.viberCheck
                        });
                        !state.viberCheck ? 
                            dispatch({
                                type: actions.MESSENGERS,
                                data: state.messengers.find((e)=> { return e.type === Fields.viber.value})
                                    ? state.messengers.map((item) => {
                                        return (item.type === Fields.viber.value) ? {
                                            type: Fields.viber.value,
                                            description: Fields.viber?.label[lang],
                                            link: `viber://chat?number=%2B${state.phone.replace(/[\D]/gim, '')}`,
                                        } : item
                                    }) : state.messengers.concat(
                                        {
                                            type: Fields.viber.value,
                                            description: Fields.viber?.label[lang],
                                            link: `viber://chat?number=%2B${state.phone.replace(/[\D]/gim, '')}`,
                                        },
                                    ),
                            })
                        : dispatch({
                            type: actions.MESSENGERS,
                            data: state.messengers.filter((item)=> {
                                return item.type !== Fields.viber.value
                            }),
                        })
                    }}
                    active={state.viberCheck}
                />
            </div>
        </div>
    </div>}
    <div className={gs.row}>
        <div className={gs.col6}>
            <PhoneInput
                name={'phoneSecond'}
                disabled={disabled}
                label={Fields.phoneSecond?.label[lang]}
                onChange={(e) => {
                    dispatch({
                    type: actions.PHONE_SECOND,
                    data: e,
                    });
                }}
                value={state.phoneSecond}
                mask={"+7(999)999-99-99"}
                // errorBorder={invalidFields.includes("phone")}
                // error={invalidFields.includes("phone") && Errors.phoneNumber[lang]}
            />
        </div>
    </div>
    <div className={gs.row}>
        <div className={gs.col6}>
            <LabeledInput
            name={'email'}
            disabled={disabled}
            label={Fields.email?.label[lang]}
            onChange={(e) => {
                dispatch({
                type: actions.EMAIL,
                data: e.target.value,
                });
            }}
            value={state.email}
            required={true}
            errorBorder={invalidFields.includes("email") || (state.email.length > 0 && !validateEmail(state.email, true))}
            error={(invalidFields.includes("email") || (state.email.length > 0 && !validateEmail(state.email, true))) && Errors.email[lang]}
            />
        </div>
    </div>
    </>);
}

export default Contacts;
