import React, { useMemo, useState } from "react";
import LabeledInput from "../inputs/LabeledInput";
import SelectInput from "../inputs/SelectInput";
import "./AddressInputs.scss";
import { RegionCity } from "../../types/common-types";
import { Lang } from "src/types/common-types";
import Applicant from "src/types/applicant";
import { Dispatch } from "src/types/store-types";
import { actions } from "../../store/store";
import gs from "../../Layout.module.scss";
import { Fields } from "../../content/fields";
import { Errors } from "../../content/errors";
import { L10n } from "../../types/common-types";
import { CITY, INSTANCE } from "../../service/irenta-server-parameters";
import { allCountriesList } from "../../content/coutry";
import KzRegionCityListKz from "../../content/RegionCity/kz_region-city_kz";
import KzRegionCityListEn from "../../content/RegionCity/kz_region-city_eng";
import KzRegionCityList from "../../content/RegionCity/kz_region-city";
import RegionCityListKz from "../../content/RegionCity/region-city_kz";
import RegionCityListEn from "../../content/RegionCity/region-city_en";
import RegionCityList from "../../content/RegionCity/region-city";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  disabled: boolean;
}

export default function AddressInputs({ lang, state, invalidFields, dispatch, disabled }: Props) {
  const regionCityRegistered = useMemo(() => {
    return state.residenceAddress.country === '398' ? 
      lang === "kz" ? KzRegionCityListKz : lang === "en" ? KzRegionCityListEn : KzRegionCityList
    : lang === "kz" ? RegionCityListKz : lang === "en" ? RegionCityListEn : RegionCityList
  }, [lang, state.residenceAddress.country]);
  const countryOptions = 
    INSTANCE === 'ala' ? 
      allCountriesList.sort((a: any, b: any) => {
        if (a?.label[lang]?.toLowerCase() < b?.label[lang]?.toLowerCase()) {
          return -1;
        }
        if (a?.label[lang]?.toLowerCase() > b?.label[lang]?.toLowerCase()) {
          return 1;
        }
        return 0;
      }).map((item) => {
        return {
          value: item.value,
          label: item?.label[lang]
        }
      }).sort((x: any, y: any) =>
        ['398', '643', '112'].includes(x.value.toString()) ? -1 : 1
      )
      .sort((x: any, y: any) => {
        return x.value.toString() === '398'
          ? -1
          : y.value.toString() == '398'
          ? 1
          : 0
      }) : allCountriesList.sort((a: any, b: any) => {
        if (a?.label[lang]?.toLowerCase() < b?.label[lang]?.toLowerCase()) {
          return -1;
        }
        if (a?.label[lang]?.toLowerCase() > b?.label[lang]?.toLowerCase()) {
          return 1;
        }
        return 0;
      }).map((item) => {
        return {
          value: item.value,
          label: item?.label[lang]
        }
      }).sort((x: any, y: any) =>
        ['643', '398', '112'].includes(x.value.toString()) ? -1 : 1
      )
      .sort((x: any, y: any) => {
        return x.value.toString() === '643'
          ? -1
          : y.value.toString() == '643'
          ? 1
          : 0
      })
  const regionRegisteredListUnigue = [
    ...new Set(
      regionCityRegistered
        .map((item: RegionCity) => {
          return item.region;
        })
        .sort((a: string, b: string) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .sort((x: any, y: any) =>
          ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(x)
            ? -1
            : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(x)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region'].includes(x)
          ? -1
          : 1
        )
    ),
  ];
  const cityRegisteredListForRegion = regionCityRegistered.filter((item: RegionCity) => {
    if (item.region === state.residenceAddress.region) return item;
  });
  const regionRegisteredList = regionRegisteredListUnigue.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const cityRegisteredList = cityRegisteredListForRegion
    ? cityRegisteredListForRegion
        .map((item: RegionCity) => {
          return {
            value: item.city,
            label: item.city,
          };
        })
        .sort((a: { value: string; label: string }, b: { value: string; label: string }) => {
          if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1;
          }
          if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
          }
          return 0;
        }).sort((x: any, y: any) =>
        ['Москва', 'Мәскеу', 'Moscow'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург', 'Санкт-Петербург', 'Saint-Petersburg'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Алматы', 'Алматы', 'Almaty'].includes(x.value)
          ? -1
          : 1
        )
    : [];

  return (
    <>
      <div className={gs.col6}>
        <SelectInput
          name={"country"}
          className="country"
          disabled={disabled}
          options={countryOptions}
          changeHandler={(e: { value: string; label: string }) => {
            state.residenceAddress.country = e.value;
            state.residenceAddress.region = '';
            state.residenceAddress.city = '';
            dispatch({
              type: actions.RESIDENCE_ADDRESS,
              data: state.residenceAddress,
            });
          }}
          placeholder={Fields.country?.label[lang]}
          value={countryOptions.find((e) => {
            return e.value === state.residenceAddress.country;
          })}
          required={true}
          errorBorder={invalidFields.includes("country")}
          error={invalidFields.includes("country") && Errors.country[lang]}
          invalidFields={invalidFields}
        />
      </div>
      <div className={gs.col6}></div>
      <div className={gs.col6}>
        {/* REGISTRATION ADDRESS REGION */}
        {(state.residenceAddress.country === '398' || state.residenceAddress.country === '643') && regionRegisteredList.length > 0 ? (
          <SelectInput
            name={"region"}
            className={state.residenceAddress.country === '398' ? 'kz_region' : 'region'}
            disabled={disabled}
            options={regionRegisteredList}
            changeHandler={(e: { value: string; label: L10n }) => {
              state.residenceAddress.region = e.value;
              dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress,
              });
            }}
            placeholder={Fields.region?.label[lang]}
            value={regionRegisteredList.find((e) => {
              return e.value === state.residenceAddress.region;
            })}
            errorBorder={invalidFields.includes("addressRegion")}
            error={invalidFields.includes("addressRegion") && Errors.region[lang]}
            required={true}
            invalidFields={invalidFields}
          />
        ) : (
          <LabeledInput
            name={"region"}
            className={'region'}
            disabled={disabled}
            label={Fields.region?.label[lang]}
            onChange={(e) => {
              state.residenceAddress.region = e.target.value;
              dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress,
              });
            }}
            value={state.residenceAddress.region}
            errorBorder={invalidFields.includes("region")}
            error={invalidFields.includes("region") && Errors.region[lang]}
            required={true}
          />
        )}
      </div>
      <div className={gs.col6}></div>
      <div className={gs.col6}>
        {/* REGISTRATION ADDRESS CITY */}
        {cityRegisteredList.length > 0 ? (
          <SelectInput
            name={"city"}
            className={'city'}
            disabled={disabled}
            options={cityRegisteredList}
            placeholder={Fields.city?.label[lang]}
            changeHandler={(e) => {
              state.residenceAddress.city = e.value;
              dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress,
              });
            }}
            value={cityRegisteredList.find((e) => {
              return e.value === state.residenceAddress.city;
            })}
            errorBorder={invalidFields.includes("city")}
            error={invalidFields.includes("city") && Errors.city[lang]}
            required={true}
            invalidFields={invalidFields}
          />
        ) : (
          <LabeledInput
            name={"city"}
            className={'city'}
            disabled={disabled}
            label={Fields.city?.label[lang]}
            onChange={(e) => {
              state.residenceAddress.city = e.target.value;
              dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress,
              });
            }}
            value={state.residenceAddress.city}
            errorBorder={invalidFields.includes("city")}
            error={invalidFields.includes("city") && Errors.city[lang]}
            required={true}
          />
        )}
      </div>
    </>
  );
}
