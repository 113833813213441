import styles from './AddButton.module.scss'
import addIcon from '../../static/add_button.svg'
import { Title } from '../../content/title'
import { Lang } from "../../types/common-types";

interface AddButtonProps {
    lang: Lang
    onClick: () => void
}
export default function AddButton({lang, onClick}:AddButtonProps) {
    return <div className={styles.addButton} onClick={onClick}>
        <img src={addIcon} width={10} height={10} alt="addButton" />
        <span>{Title.addMessengerButton[lang]}</span>
    </div>
}