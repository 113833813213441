import React from "react";
import LabeledInput from "../inputs/LabeledInput";
import { Fields } from "../../content/fields";
import { Title } from "../../content/title";
import { Lang } from "src/types/common-types";
import style from "./Portfolio.module.scss";
import gs from "../../Layout.module.scss";
import { Dispatch } from "src/types/store-types";
import Applicant from "src/types/applicant";
import { actions } from "../../store/store";
import { validateUrl } from "../../validate/validator";
import { Errors } from "../../content/errors";

interface Props {
  lang: Lang;
  state: Applicant;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  showPortfolio: boolean;
  showResume: boolean;
}

function Portfolio({ lang, state, invalidFields, showPortfolio, showResume, dispatch }: Props) {
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={style.portfolioBox}>
          {/* <span className={gs.header}>{Title.portfolioTitle[lang]}</span> */}
          {showResume ? (
            <LabeledInput
              name={'resumeLink'}
              label={Fields.resumeLink?.label[lang]}
              onChange={(e) => {
                dispatch({
                  type: actions.RESUME_LINK,
                  data: e.target.value,
                });
              }}
              value={state.resumeLink}
              required={true}
              errorBorder={invalidFields.includes("resumeLink") || (state.resumeLink.length > 0 && !validateUrl(state.resumeLink, 200, true))}
              error={(invalidFields.includes("resumeLink") || (state.resumeLink.length > 0 && !validateUrl(state.resumeLink, 200, true))) && Errors.resumeLink[lang]}
            />
          ) : null}
          {showPortfolio ? (
            <LabeledInput
              name={'portfolioLink'}
              label={Fields.portfolioLink?.label[lang]}
              onChange={(e) => {
                dispatch({
                  type: actions.PORTFOLIO_LINK,
                  data: e.target.value,
                });
              }}
              value={state.portfolioLink}
              errorBorder={invalidFields.includes("portfolioLink") || (state.portfolioLink.length > 0 && !validateUrl(state.portfolioLink, 200, true))}
              error={(invalidFields.includes("portfolioLink") || (state.portfolioLink.length > 0 && !validateUrl(state.portfolioLink, 200, true))) && Errors.portfolioLink[lang]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
