import React, { useState, useEffect } from "react";
import styles from "./App.module.scss";
import { iframeResizer } from "iframe-resizer";
import { BrowserRouter, Routes, Route, useSearchParams } from 'react-router-dom';
import Main from "./pages/Main/Main";
import { LangContext } from "./store/LangContext";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import VacancyPage from "./pages/VacanciePage/VacancyPage";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lang, setLang] = useState("");
  useEffect(() => {
    setSearchParams(new URLSearchParams(document?.location?.search));
  }, []);
  
  useEffect(() => {
    setLang(searchParams.get("lang")??'ru')
  }, [searchParams]);
  useEffect(() => {
    window.iFrameResizer = {};
  }, [iframeResizer]);

  return (
    <div className={styles.app}>
      <LangContext.Provider value={lang}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/vacancie" element={<VacancyPage />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
      </LangContext.Provider>
    </div>
  );
}

export default App;
