import { RegionCity } from '../../types/common-types'

const KzRegionCityListEn: RegionCity[] = [
  {
    region: 'North Kazakhstan',
    city: 'Bogolyubovo',
  },
  {
    region: 'North Kazakhstan',
    city: 'Bulaevo',
  },
  {
    region: 'North Kazakhstan',
    city: 'Dubrovnoe',
  },
  {
    region: 'North Kazakhstan',
    city: 'Boar',
  },
  {
    region: 'North Kazakhstan',
    city: 'Kara-Kuga',
  },
  {
    region: 'North Kazakhstan',
    city: 'Mamlutka',
  },
  {
    region: 'North Kazakhstan',
    city: 'Nalobino',
  },
  {
    region: 'North Kazakhstan',
    city: 'Novonikolskoye',
  },
  {
    region: 'North Kazakhstan',
    city: 'Novopokrovka',
  },
  {
    region: 'North Kazakhstan',
    city: 'Petropavlovsk',
  },
  {
    region: 'North Kazakhstan',
    city: 'Poludino',
  },
  {
    region: 'North Kazakhstan',
    city: 'Presnovka',
  },
  {
    region: 'North Kazakhstan',
    city: 'Sokolovka',
  },
  {
    region: 'North Kazakhstan',
    city: 'Ekatrinivka',
  },
  {
    region: 'North Kazakhstan',
    city: 'Kyzylagash',
  },
  {
    region: 'Akmola',
    city: 'Astana',
  },
  {
    region: 'Akmola',
    city: 'Atbasar',
  },
  {
    region: 'Akmola',
    city: 'Novorybinka',
  },
  {
    region: 'Akmola',
    city: 'Kokchetav',
  },
  {
    region: 'Akmola',
    city: 'Yesil',
  },
  {
    region: 'Akmola',
    city: 'Akkul',
  },
  {
    region: 'Akmola',
    city: 'Youth',
  },
  {
    region: 'Akmola',
    city: 'Kyzyltan',
  },
  {
    region: 'Akmola',
    city: 'Alekseevka',
  },
  {
    region: 'Akmola',
    city: 'Ozen (Razdolnoe)',
  },
  {
    region: 'Akmola',
    city: 'Azat',
  },
  {
    region: 'Akmola',
    city: 'Orken (Kuropatkino)',
  },
  {
    region: 'Akmola',
    city: 'Terekti (Lineevka)',
  },
  {
    region: 'Akmola',
    city: 'Uyaly (Chaglinsky S.O.)',
  },
  {
    region: 'Akmola',
    city: 'Kulet',
  },
  {
    region: 'Akmola',
    city: 'Zhamantuz',
  },
  {
    region: 'Akmola',
    city: 'Shchuchinsk',
  },
  {
    region: 'Akmola',
    city: 'Akylbay',
  },
  {
    region: 'Akmola',
    city: 'Ortak',
  },
  {
    region: 'Akmola',
    city: 'Current',
  },
  {
    region: 'Akmola',
    city: 'Bidayik (Bidaik)',
  },
  {
    region: 'Akmola',
    city: 'Cascat',
  },
  {
    region: 'Akmola',
    city: 'Zhanalyk (Akmola region)',
  },
  {
    region: 'Akmola',
    city: 'Distant',
  },
  {
    region: 'Akmola',
    city: 'Baurkaragay',
  },
  {
    region: 'Akmola',
    city: 'Ahlbeck',
  },

  {
    region: 'Aqtobe',
    city: 'Akshimrau',
  },
  {
    region: 'Aqtobe',
    city: 'Beyneu',
  },
  {
    region: 'Aqtobe',
    city: 'Akkabak',
  },
  {
    region: 'Aqtobe',
    city: 'Aktobe',
  },
  {
    region: 'Aqtobe',
    city: 'Alga',
  },
  {
    region: 'Aqtobe',
    city: 'Berchogur',
  },
  {
    region: 'Aqtobe',
    city: 'Bestamak',
  },
  {
    region: 'Aqtobe',
    city: 'Ulpan',
  },
  {
    region: 'Aqtobe',
    city: 'Shoshkakol',
  },
  {
    region: 'Aqtobe',
    city: 'Irgiz',
  },
  {
    region: 'Aqtobe',
    city: 'Kalinovka',
  },
  {
    region: 'Aqtobe',
    city: 'Karatogay',
  },
  {
    region: 'Aqtobe',
    city: 'Karaulkeldy',
  },
  {
    region: 'Aqtobe',
    city: 'Cossack',
  },
  {
    region: 'Aqtobe',
    city: 'Khromtau',
  },
  {
    region: 'Aqtobe',
    city: 'Fists',
  },
  {
    region: 'Aqtobe',
    city: 'Nogaits',
  },
  {
    region: 'Aqtobe',
    city: 'Novorossiysk',
  },
  {
    region: 'Aqtobe',
    city: 'Garden',
  },
  {
    region: 'Aqtobe',
    city: 'Saryoba',
  },
  {
    region: 'Aqtobe',
    city: 'Skol',
  },
  {
    region: 'Aqtobe',
    city: 'Sokyrbulak',
  },
  {
    region: 'Aqtobe',
    city: 'Karabutak',
  },
  {
    region: 'Aqtobe',
    city: 'Kurmansay (Stepanovka)',
  },
  {
    region: 'Aqtobe',
    city: 'Temir',
  },
  {
    region: 'Aqtobe',
    city: 'Togyz',
  },
  {
    region: 'Aqtobe',
    city: 'Oyil',
  },
  {
    region: 'Aqtobe',
    city: 'Dawn of October',
  },
  {
    region: 'Aqtobe',
    city: 'Zharkamys',
  },
  {
    region: 'Aqtobe',
    city: 'Shalkar',
  },
  {
    region: 'Aqtobe',
    city: 'Marzhanbulak (Progress)',
  },
  {
    region: 'Almaty',
    city: 'Almaty',
  },
  {
    region: 'Almaty',
    city: 'Bakanas',
  },
  {
    region: 'Almaty',
    city: 'Birlik',
  },
  {
    region: 'Almaty',
    city: 'Shalkydysu',
  },
  {
    region: 'Almaty',
    city: 'Tokzhailau',
  },
  {
    region: 'Almaty',
    city: 'Konaev',
  },
  {
    region: 'Almaty',
    city: 'Karaoi',
  },
  {
    region: 'Almaty',
    city: 'Coke',
  },
  {
    region: 'Almaty',
    city: 'Koktuma',
  },
  {
    region: 'Almaty',
    city: 'Kuygan',
  },
  {
    region: 'Almaty',
    city: 'Lepsy',
  },
  {
    region: 'Almaty',
    city: 'Matai',
  },
  {
    region: 'Almaty',
    city: 'Zharkent',
  },
  {
    region: 'Almaty',
    city: 'Sarkand',
  },
  {
    region: 'Almaty',
    city: 'Saryozek',
  },
  {
    region: 'Almaty',
    city: 'Taldy-Kurgan',
  },
  {
    region: 'Almaty',
    city: 'Tegermen',
  },
  {
    region: 'Almaty',
    city: 'Tekeli',
  },
  {
    region: 'Almaty',
    city: 'Ushtobe',
  },
  {
    region: 'Almaty',
    city: 'Talgar',
  },
  {
    region: 'Almaty',
    city: 'Tomar',
  },
  {
    region: 'Almaty',
    city: 'Narynkol',
  },
  {
    region: 'Almaty',
    city: 'Chunja',
  },
  {
    region: 'Almaty',
    city: 'Kegen',
  },
  {
    region: 'Almaty',
    city: 'Zharkent',
  },
  {
    region: 'Almaty',
    city: 'Esik',
  },
  {
    region: 'Almaty',
    city: 'Costobe',
  },
  {
    region: 'Almaty',
    city: 'Boyauly',
  },
  {
    region: 'Almaty',
    city: 'Baribayeva',
  },
  {
    region: 'Almaty',
    city: 'Akkol (Almaty region)',
  },
  {
    region: 'Almaty',
    city: 'Bakbakty',
  },

  {
    region: 'Atyrau',
    city: 'Atyrau',
  },
  {
    region: 'Atyrau',
    city: 'Aybas',
  },
  {
    region: 'Atyrau',
    city: 'Baišūnas',
  },
  {
    region: 'Atyrau',
    city: 'Dossor',
  },
  {
    region: 'Atyrau',
    city: 'Inderbor',
  },
  {
    region: 'Atyrau',
    city: 'Karaton',
  },
  {
    region: 'Atyrau',
    city: 'Komsomolsky',
  },
  {
    region: 'Atyrau',
    city: 'Kulsary',
  },
  {
    region: 'Atyrau',
    city: 'Makat',
  },
  {
    region: 'Atyrau',
    city: 'Miyaly',
  },
  {
    region: 'Atyrau',
    city: 'Mukur',
  },
  {
    region: 'Atyrau',
    city: 'Munaily',
  },
  {
    region: 'Atyrau',
    city: 'Prorva',
  },
  {
    region: 'Atyrau',
    city: 'Saraishyk',
  },
  {
    region: 'Atyrau',
    city: 'Zaburunye',
  },
  {
    region: 'Atyrau',
    city: 'Zhamansor',
  },
  {
    region: 'Atyrau',
    city: 'Zhangaly',
  },
  {
    region: 'Atyrau',
    city: 'Akis',
  },
  {
    region: 'Atyrau',
    city: 'Akkiztogay',
  },
  {
    region: 'Atyrau',
    city: 'Akkistau',
  },
  {
    region: 'Atyrau',
    city: 'Atekb',
  },
  {
    region: 'Atyrau',
    city: 'Ayrtam',
  },
  {
    region: 'Atyrau',
    city: 'Azgir',
  },
  {
    region: 'Atyrau',
    city: 'Balkuduk',
  },
  {
    region: 'Atyrau',
    city: 'Birlik',
  },
  {
    region: 'Atyrau',
    city: 'Dam',
  },
  {
    region: 'Atyrau',
    city: 'Kamynin',
  },
  {
    region: 'Atyrau',
    city: 'Karasyr',
  },
  {
    region: 'Atyrau',
    city: 'Kazbek',
  },
  {
    region: 'Atyrau',
    city: 'Koschagyl',
  },
  {
    region: 'Atyrau',
    city: 'Makhambet',
  },
  {
    region: 'Atyrau',
    city: 'Novobogatinskoye',
  },
  {
    region: 'Atyrau',
    city: 'New Ushtagan',
  },
  {
    region: 'Atyrau',
    city: 'Orpa',
  },
  {
    region: 'Atyrau',
    city: 'On foot',
  },
  {
    region: 'Atyrau',
    city: 'Sagiz',
  },
  {
    region: 'Atyrau',
    city: 'Shokpartogai',
  },
  {
    region: 'Atyrau',
    city: 'Tandau',
  },
  {
    region: 'Atyrau',
    city: 'Tas',
  },
  {
    region: 'Atyrau',
    city: 'Tolegen',
  },
  {
    region: 'Atyrau',
    city: 'Zhanbai',
  },
  {
    region: 'Atyrau',
    city: 'Zhalgyzapan',
  },
  {
    region: 'Atyrau',
    city: 'Zhaskairat',
  },
  {
    region: 'Atyrau',
    city: 'Kurmangazy',
  },

  {
    region: 'East Kazakhstan',
    city: 'Aksuat',
  },
  {
    region: 'East Kazakhstan',
    city: 'Akzhal',
  },
  {
    region: 'East Kazakhstan',
    city: 'Ayagoz',
  },
  {
    region: 'East Kazakhstan',
    city: 'Bakhty',
  },
  {
    region: 'East Kazakhstan',
    city: 'Belagash',
  },
  {
    region: 'East Kazakhstan',
    city: 'Boko',
  },
  {
    region: 'East Kazakhstan',
    city: 'Cherdoyak',
  },
  {
    region: 'East Kazakhstan',
    city: 'Chubartau',
  },
  {
    region: 'East Kazakhstan',
    city: 'Donenbai',
  },
  {
    region: 'East Kazakhstan',
    city: 'Karaaul ',
  },
  {
    region: 'East Kazakhstan',
    city: 'Karabulak',
  },
  {
    region: 'East Kazakhstan',
    city: 'Karaguzhikha',
  },
  {
    region: 'East Kazakhstan',
    city: 'Karatan',
  },
  {
    region: 'East Kazakhstan',
    city: 'Kyzylogiz',
  },
  {
    region: 'East Kazakhstan',
    city: 'Leninogorsk',
  },
  {
    region: 'East Kazakhstan',
    city: 'Rakhmanovskoye',
  },
  {
    region: 'East Kazakhstan',
    city: 'Semey',
  },
  {
    region: 'East Kazakhstan',
    city: 'Shemonaiha',
  },
  {
    region: 'East Kazakhstan',
    city: 'Social',
  },
  {
    region: 'East Kazakhstan',
    city: 'Taskesken',
  },
  {
    region: 'East Kazakhstan',
    city: 'Urjar',
  },
  {
    region: 'East Kazakhstan',
    city: 'Uryl',
  },
  {
    region: 'East Kazakhstan',
    city: 'Ust-Kamenogorsk',
  },
  {
    region: 'East Kazakhstan',
    city: 'Zaisan',
  },
  {
    region: 'East Kazakhstan',
    city: 'Jarma',
  },
  {
    region: 'East Kazakhstan',
    city: 'Znamenka',
  },
  {
    region: 'East Kazakhstan',
    city: 'Zyryanovsk',
  },
  {
    region: 'East Kazakhstan',
    city: 'Sergeevka',
  },
  {
    region: 'East Kazakhstan',
    city: 'Praporshchikovo',
  },
  {
    region: 'East Kazakhstan',
    city: 'Ukrainka',
  },
  {
    region: 'East Kazakhstan',
    city: 'Uvarovo',
  },
  {
    region: 'East Kazakhstan',
    city: 'Deep',
  },
  {
    region: 'East Kazakhstan',
    city: 'Belousovka',
  },
  {
    region: 'East Kazakhstan',
    city: 'Bobrovka (Glubokovsky district)',
  },
  {
    region: 'East Kazakhstan',
    city: 'Wine',
  },
  {
    region: 'East Kazakhstan',
    city: 'Sekisovka',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Akzhar',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Algatart',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Choo',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Taraz',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Moiynkum',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Kamkaly',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Karatau',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Mynaral',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Otar',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Uyuk',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Zhanatas',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Merke',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Chaldovar ',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Zhambul (Merke r. m)',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'International',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Oytal',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Tatty',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Kazakh',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Meadow',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Kulan',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Kogershin',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Akyrtobe',
  },
  {
    region: 'Dzhambul (Zhambyl)',
    city: 'Ornek (Ryskulov R. m)',
  },

  {
    region: 'West Kazakhstan',
    city: 'Aksu',
  },
  {
    region: 'West Kazakhstan',
    city: 'Kaiyndy',
  },
  {
    region: 'West Kazakhstan',
    city: 'Bitik',
  },
  {
    region: 'West Kazakhstan',
    city: 'Bolashak (Kaztalov)',
  },
  {
    region: 'West Kazakhstan',
    city: 'Boget',
  },
  {
    region: 'West Kazakhstan',
    city: 'Bostandyk',
  },
  {
    region: 'West Kazakhstan',
    city: 'Buldyrty',
  },
  {
    region: 'West Kazakhstan',
    city: 'Chapaev',
  },
  {
    region: 'West Kazakhstan',
    city: 'Chizha-2',
  },
  {
    region: 'West Kazakhstan',
    city: 'Jambaits ',
  },
  {
    region: 'West Kazakhstan',
    city: 'Sarykol',
  },
  {
    region: 'West Kazakhstan',
    city: 'Zhalpaktal',
  },
  {
    region: 'West Kazakhstan',
    city: 'Taipak',
  },
  {
    region: 'West Kazakhstan',
    city: 'Kaztalovka',
  },
  {
    region: 'West Kazakhstan',
    city: 'Akshat',
  },
  {
    region: 'West Kazakhstan',
    city: 'Masteksay',
  },
  {
    region: 'West Kazakhstan',
    city: 'Mergenevo ',
  },
  {
    region: 'West Kazakhstan',
    city: 'Zhanakazan',
  },
  {
    region: 'West Kazakhstan',
    city: 'Uralsk',
  },
  {
    region: 'West Kazakhstan',
    city: 'Ashysay',
  },
  {
    region: 'West Kazakhstan',
    city: 'Sergey Aula',
  },
  {
    region: 'West Kazakhstan',
    city: 'Shalkar',
  },
  {
    region: 'West Kazakhstan',
    city: 'Akkurai',
  },
  {
    region: 'West Kazakhstan',
    city: 'Tau',
  },
  {
    region: 'West Kazakhstan',
    city: 'Zhetybai',
  },

  {
    region: 'Karaganda',
    city: 'Abai',
  },
  {
    region: 'Karaganda',
    city: 'Agadyr',
  },
  {
    region: 'Karaganda',
    city: 'Actas',
  },
  {
    region: 'Karaganda',
    city: 'Aktogay',
  },
  {
    region: 'Karaganda',
    city: 'Actuma',
  },
  {
    region: 'Karaganda',
    city: 'Ayshyrak',
  },
  {
    region: 'Karaganda',
    city: 'Balkhash',
  },
  {
    region: 'Karaganda',
    city: 'Dzhezkazgan',
  },
  {
    region: 'Karaganda',
    city: 'Karaganda',
  },
  {
    region: 'Karaganda',
    city: 'Karazhal',
  },
  {
    region: 'Karaganda',
    city: 'Karazhyngyl',
  },
  {
    region: 'Karaganda',
    city: 'Karkaralinsk',
  },
  {
    region: 'Karaganda',
    city: 'Karsakbay',
  },
  {
    region: 'Karaganda',
    city: 'Kense',
  },
  {
    region: 'Karaganda',
    city: 'Kievka',
  },
  {
    region: 'Karaganda',
    city: 'Kurgassyn',
  },
  {
    region: 'Karaganda',
    city: 'Kyzyldikan',
  },
  {
    region: 'Karaganda',
    city: 'Kyzyl-Jar',
  },
  {
    region: 'Karaganda',
    city: 'Kyzyl commune',
  },
  {
    region: 'Karaganda',
    city: 'Kyzyltau',
  },
  {
    region: 'Karaganda',
    city: 'Kyzylui',
  },
  {
    region: 'Karaganda',
    city: 'Mointy',
  },
  {
    region: 'Karaganda',
    city: 'Satpayev',
  },
  {
    region: 'Karaganda',
    city: 'Nura',
  },
  {
    region: 'Karaganda',
    city: 'Saran',
  },
  {
    region: 'Karaganda',
    city: 'Sary-Shagan',
  },
  {
    region: 'Karaganda',
    city: 'Sayak',
  },
  {
    region: 'Karaganda',
    city: 'Shakhtinsk ',
  },
  {
    region: 'Karaganda',
    city: 'Shalgia',
  },
  {
    region: 'Karaganda',
    city: 'Sonals',
  },
  {
    region: 'Karaganda',
    city: 'Sorolen',
  },
  {
    region: 'Karaganda',
    city: 'Tan',
  },
  {
    region: 'Karaganda',
    city: 'Tasaral',
  },
  {
    region: 'Karaganda',
    city: 'Temirtau',
  },
  {
    region: 'Karaganda',
    city: 'Torabai',
  },
  {
    region: 'Karaganda',
    city: 'Uspensky',
  },
  {
    region: 'Karaganda',
    city: 'Egindybulak',
  },
  {
    region: 'Karaganda',
    city: 'Janteli',
  },
  {
    region: 'Karaganda',
    city: 'Shahan',
  },
  {
    region: 'Karaganda',
    city: 'Karazhar',
  },

  {
    region: 'Qostanay',
    city: 'Tamkamys',
  },
  {
    region: 'Qostanay',
    city: 'Turgai',
  },
  {
    region: 'Qostanay',
    city: 'Akshi',
  },
  {
    region: 'Qostanay',
    city: 'Amangeldy',
  },
  {
    region: 'Qostanay',
    city: 'Arkalyk',
  },
  {
    region: 'Qostanay',
    city: 'Birali',
  },
  {
    region: 'Qostanay',
    city: 'Dzhetygara',
  },
  {
    region: 'Qostanay',
    city: 'Cocalat',
  },
  {
    region: 'Qostanay',
    city: 'Kostanay',
  },
  {
    region: 'Qostanay',
    city: 'Kyzylzhar ',
  },
  {
    region: 'Qostanay',
    city: 'Presnogorkovka',
  },
  {
    region: 'Qostanay',
    city: 'Saga',
  },
  {
    region: 'Qostanay',
    city: 'Schönber',
  },
  {
    region: 'Qostanay',
    city: 'Frunzenskoye',
  },
  {
    region: 'Qostanay',
    city: 'Rudny',
  },
  {
    region: 'Qostanay',
    city: 'Ayatskoe',
  },
  {
    region: 'Kyzylorda',
    city: 'Uzynkayyr',
  },
  {
    region: 'Kyzylorda',
    city: 'Baikonur',
  },
  {
    region: 'Kyzylorda',
    city: 'Accespe    ',
  },
  {
    region: 'Kyzylorda',
    city: 'Aralsk',
  },
  {
    region: 'Kyzylorda',
    city: 'Zhaksykylysh',
  },
  {
    region: 'Kyzylorda',
    city: 'Auan',
  },
  {
    region: 'Kyzylorda',
    city: 'Aidarly',
  },
  {
    region: 'Kyzylorda',
    city: 'Baigekum',
  },
  {
    region: 'Kyzylorda',
    city: 'Chiili',
  },
  {
    region: 'Kyzylorda',
    city: 'Diermen tobe',
  },
  {
    region: 'Kyzylorda',
    city: 'Dzhusaly',
  },
  {
    region: 'Kyzylorda',
    city: 'Yerimbet',
  },
  {
    region: 'Kyzylorda',
    city: 'Kaskakulan',
  },
  {
    region: 'Kyzylorda',
    city: 'Kazalinsk',
  },
  {
    region: 'Kyzylorda',
    city: 'Kulandy',
  },
  {
    region: 'Kyzylorda',
    city: 'Kyzylorda',
  },
  {
    region: 'Kyzylorda',
    city: 'Aiteke-Bi',
  },
  {
    region: 'Kyzylorda',
    city: 'Saxaul',
  },
  {
    region: 'Kyzylorda',
    city: 'Sulutobe',
  },
  {
    region: 'Kyzylorda',
    city: 'Teren-Ozek',
  },
  {
    region: 'Kyzylorda',
    city: 'Jean-Corgan',
  },
  {
    region: 'Kyzylorda',
    city: 'Aidarly',
  },
  {
    region: 'Kyzylorda',
    city: 'Besaryk',
  },
  {
    region: 'Kyzylorda',
    city: 'Talap',
  },

  {
    region: 'Mangystau',
    city: 'Fort Shevchenko',
  },
  {
    region: 'Mangystau',
    city: 'Kultau',
  },
  {
    region: 'Mangystau',
    city: 'Kuyushe',
  },
  {
    region: 'Mangystau',
    city: 'Mangyshlak',
  },
  {
    region: 'Mangystau',
    city: 'Zhanaozen',
  },
  {
    region: 'Mangystau',
    city: 'Borankul (Art.  Support)',
  },
  {
    region: 'Mangystau',
    city: 'Saura',
  },
  {
    region: 'Mangystau',
    city: 'Sai Otes',
  },
  {
    region: 'Mangystau',
    city: 'Saina Shapagatova',
  },
  {
    region: 'Mangystau',
    city: 'Shetpe',
  },
  {
    region: 'Mangystau',
    city: 'Aktau',
  },
  {
    region: 'Mangystau',
    city: 'Tauchik',
  },
  {
    region: 'Mangystau',
    city: 'Ushtagan',
  },
  {
    region: 'Mangystau',
    city: 'Imeni Kalinin',
  },
  {
    region: 'Mangystau',
    city: 'Bautino',
  },
  {
    region: 'Mangystau',
    city: 'Kyzyk',
  },
  {
    region: 'Mangystau',
    city: 'Kuryk',
  },
  {
    region: 'Mangystau',
    city: 'Eraliyeva',
  },
  {
    region: 'Mangystau',
    city: 'Zhyngyldy (Kuibyshevo)',
  },
  {
    region: 'Pavlodar',
    city: 'Alekseevka',
  },
  {
    region: 'Pavlodar',
    city: 'Pavlodar',
  },
  {
    region: 'Pavlodar',
    city: 'Ekibastuz',
  },

  {
    region: 'South Kazakhstan',
    city: 'Axumbe',
  },
  {
    region: 'South Kazakhstan',
    city: 'Arys',
  },
  {
    region: 'South Kazakhstan',
    city: 'Bagara',
  },
  {
    region: 'South Kazakhstan',
    city: 'Bairkum',
  },
  {
    region: 'South Kazakhstan',
    city: 'Baizhansay',
  },
  {
    region: 'South Kazakhstan',
    city: 'Chardara',
  },
  {
    region: 'South Kazakhstan',
    city: 'Shymkent (Shymkent)',
  },
  {
    region: 'South Kazakhstan',
    city: 'Chulakkurgan',
  },
  {
    region: 'South Kazakhstan',
    city: 'Fogolevo',
  },
  {
    region: 'South Kazakhstan',
    city: 'Kentau',
  },
  {
    region: 'South Kazakhstan',
    city: 'Koksaray',
  },
  {
    region: 'South Kazakhstan',
    city: 'Saryagash',
  },
  {
    region: 'South Kazakhstan',
    city: 'Suzak',
  },
  {
    region: 'South Kazakhstan',
    city: 'Tasty',
  },
  {
    region: 'South Kazakhstan',
    city: 'Turkestan',
  },
  {
    region: 'South Kazakhstan',
    city: 'Zhetysai',
  },
  {
    region: 'South Kazakhstan',
    city: 'Otrar',
  },
  {
    region: 'South Kazakhstan',
    city: 'Karashik',
  },
  {
    region: 'South Kazakhstan',
    city: 'Kushata',
  },
  {
    region: 'South Kazakhstan',
    city: 'Bayaldır',
  },
  {
    region: 'South Kazakhstan',
    city: 'Bostandyk (Urangay S. A)',
  },
  {
    region: 'South Kazakhstan',
    city: 'Urangay',
  },
  {
    region: 'South Kazakhstan',
    city: 'Shipan',
  },
  {
    region: 'South Kazakhstan',
    city: 'Commune',
  },
  {
    region: 'South Kazakhstan',
    city: 'Kyzylzhol',
  },
  {
    region: 'South Kazakhstan',
    city: 'Karnak',
  },
  {
    region: 'South Kazakhstan',
    city: 'Shashtobe',
  },
  {
    region: 'South Kazakhstan',
    city: 'Kumailıkas',
  },
  {
    region: 'South Kazakhstan',
    city: 'Sert',
  },
  {
    region: 'South Kazakhstan',
    city: 'Barisovka',
  },
  {
    region: 'South Kazakhstan',
    city: 'Shubanak',
  },
  {
    region: 'South Kazakhstan',
    city: 'Staroikan',
  },
  {
    region: 'South Kazakhstan',
    city: 'Ittifaq',
  },
  {
    region: 'South Kazakhstan',
    city: 'Teke',
  },
  {
    region: 'South Kazakhstan',
    city: '30 years of  Kazakhstan',
  },
  {
    region: 'South Kazakhstan',
    city: 'Ibata',
  },
  {
    region: 'South Kazakhstan',
    city: 'Sauran',
  },
  {
    region: 'South Kazakhstan',
    city: 'Zhana Sauran',
  },
  {
    region: 'South Kazakhstan',
    city: 'Babaykurgan',
  },
  {
    region: 'South Kazakhstan',
    city: 'Lenger',
  },
]
export default KzRegionCityListEn
