import { TranslationLanguageEnum } from "src/enums/TranslationLanguageEnums";
import Applicant from "src/types/applicant";
import { Action, Photo, SocialLink, ResidenceAddress } from "src/types/store-types";

export const INITIAL_STATE: Applicant = {
  vacancyId: 0,
  citizenship: "",
  lastname: "",
  firstname: "",
  patronymic: "",
  sex: 2,
  birthdate: "",
  phone: "",
  phoneSecond: "",
  telegramCheck: false,
  whatsappCheck: false,
  viberCheck: false,
  skypeCheck: false,
  email: "",
  additionalSocialLink: "",
  socialLinks: [],
  messengers: [],
  preferredCommunicationType: undefined,
  preferredCommunicationTime: '0',
  photo: {
    id: 0,
    fileName: "",
  },
  photoUrl: "",
  familyStatus: 1,
  driverLicenses: [],
  languages: [],
  hasACar: false,
  educationType: 2,
  collegeName: "",
  qualification: "",
  residenceAddress: {
    country: "",
    region: "",
    city: "",
    district: "",
    street: "",
    building: "",
    apartments: "",
    noApartments: false,
    postCode: "",
    currentlyInAnotherCityAndReadyForMove: false
  },
  positionCandidate: "",
  resumeLink: "",
  portfolioLink: "",
  coveringLetter: "",
  workExperienceYears: undefined,
  noWorkExperience: false,
  workExperienceDescription: "",
  informationSource: "",
  informationSourceDetails: "",
  locale: 0
};

export const actions = {
  VACANCY_ID: Symbol("VACANCY_ID"),
  CITIZENSHIP: Symbol("CITIZENSHIP"),
  LASTNAME: Symbol("LASTNAME"),
  FIRSTNAME: Symbol("FIRSTNAME"),
  PATRONYMIC: Symbol("PATRONYMIC"),
  SEX: Symbol("SEX"),
  BIRTHDATE: Symbol("BIRTHDATE"),
  PHONE: Symbol("PHONE"),
  PHONE_SECOND: Symbol("PHONE_SECOND"),
  EMAIL: Symbol("EMAIL"),
  ADDITIONAL_LINKS: Symbol("ADDITIONAL_LINKS"),
  SOCIAL_LINKS: Symbol("SOCIAL_LINKS"),
  MESSENGERS: Symbol("MESSENGERS"),
  COMMUNICATION_TYPE: Symbol("COMMUNICATION_TYPE"),
  COMMUNICATION_TIME: Symbol("COMMUNICATION_TIME"),
  TELEGRAM_CHECK: Symbol("TELEGRAM_CHECK"),
  WHATSAPP_CHECK: Symbol("WHATSAPP_CHECK"),
  VIBER_CHECK: Symbol("VIBER_CHECK"),
  SKYPE_CHECK: Symbol("SKYPE_CHECK"),
  PHOTO: Symbol("PHOTO"),
  PHOTO_URL: Symbol("PHOTO_URL"),
  FAMILY_STATUS: Symbol("FAMILY_STATUS"),
  DRIVER_LICENSE: Symbol("DRIVER_LICENSE"),
  LANGUAGES: Symbol("LANGUAGES"),
  HAS_A_CAR: Symbol("HAS_A_CAR"),
  EDUCATION_TYPE: Symbol("EDUCATION_TYPE"),
  COLLEGE_NAME: Symbol("COLLEGE_NAME"),
  QUALIFICATION: Symbol("QUALIFICATION"),
  RESIDENCE_ADDRESS: Symbol("RESIDENCE_ADDRESS"),
  POSITION_CANDIDATE: Symbol("POSITION_CANDIDATE"),
  RESUME_LINK: Symbol("RESUME_LINK"),
  PORTFOLIO_LINK: Symbol("PORTFOLIO_LINK"),
  COVERING_LETTER: Symbol("COVERING_LETTER"),
  WORK_EXPERIENCE_YEARS: Symbol("WORK_EXPERIENCE_YEARS"),
  NO_WORK_EXPERIENCE: Symbol("NO_WORK_EXPERIENCE"),
  WORK_EXPERIENCE_DESCRIPTION: Symbol("WORK_EXPERIENCE_DESCRIPTION"),
  INFORMATION_SOURCE: Symbol("INFORMATION_SOURCE"),
  INFORMATION_SOURCE_DETAILS: Symbol("INFORMATION_SOURCE_DETAILS"),
  RESET: Symbol("RESET"),
  LOCALE: Symbol("LOCALE")
};

export function reducer(state = INITIAL_STATE, action: Action): Applicant {
  switch (action.type) {
    case actions.VACANCY_ID:
      return { ...state, vacancyId: action.data as number }
    case actions.CITIZENSHIP:
      return { ...state, citizenship: action.data as string }
    case actions.LASTNAME:
      return { ...state, lastname: action.data as string };
    case actions.FIRSTNAME:
      return { ...state, firstname: action.data as string };
    case actions.PATRONYMIC:
      return { ...state, patronymic: action.data as string };
    case actions.SEX:
      return { ...state, sex: action.data as number };
    case actions.BIRTHDATE:
      return { ...state, birthdate: action.data as string };
    case actions.PHONE:
      return { ...state, phone: action.data as string };
    case actions.PHONE_SECOND:
      return { ...state, phoneSecond: action.data as string };
    case actions.EMAIL:
      return { ...state, email: action.data as string };
    case actions.ADDITIONAL_LINKS:
      return { ...state, additionalSocialLink: action.data as string };
    case actions.TELEGRAM_CHECK:
      return { ...state, telegramCheck: action.data as boolean };
    case actions.WHATSAPP_CHECK:
      return { ...state, whatsappCheck: action.data as boolean };
    case actions.VIBER_CHECK:
      return { ...state, viberCheck: action.data as boolean };
    case actions.SKYPE_CHECK:
      return { ...state, skypeCheck: action.data as boolean };
    case actions.SOCIAL_LINKS:
      return { ...state, socialLinks: action.data as SocialLink[] };
    case actions.MESSENGERS:
      return { ...state, messengers: action.data as SocialLink[] };
    case actions.COMMUNICATION_TYPE:
      return { ...state, preferredCommunicationType: action.data as string };
    case actions.COMMUNICATION_TIME:
      return { ...state, preferredCommunicationTime: action.data as string };
    case actions.PHOTO:
      return { ...state, photo: action.data as Photo };
    case actions.PHOTO_URL:
      return { ...state, photoUrl: action.data as string };
    case actions.FAMILY_STATUS:
      return { ...state, familyStatus: action.data as number };
    case actions.DRIVER_LICENSE:
      return { ...state, driverLicenses: action.data as string[] };
    case actions.LANGUAGES:
      return { ...state, languages: action.data as string[] };
    case actions.HAS_A_CAR:
      return { ...state, hasACar: action.data as boolean };
    case actions.EDUCATION_TYPE:
      return { ...state, educationType: action.data as number };
    case actions.COLLEGE_NAME:
      return { ...state, collegeName: action.data as string };
    case actions.QUALIFICATION:
      return { ...state, qualification: action.data as string };
    case actions.RESIDENCE_ADDRESS:
      return { ...state, residenceAddress: action.data as ResidenceAddress };
    case actions.POSITION_CANDIDATE:
      return { ...state, positionCandidate: action.data as string };
    case actions.RESUME_LINK:
      return { ...state, resumeLink: action.data as string };
    case actions.PORTFOLIO_LINK:
      return { ...state, portfolioLink: action.data as string };
    case actions.COVERING_LETTER:
      return { ...state, coveringLetter: action.data as string };
    case actions.WORK_EXPERIENCE_YEARS:
      return { ...state, workExperienceYears: action.data as number };
    case actions.NO_WORK_EXPERIENCE:
      return { ...state, noWorkExperience: action.data as boolean };
    case actions.WORK_EXPERIENCE_DESCRIPTION:
      return { ...state, workExperienceDescription: action.data as string };
    case actions.INFORMATION_SOURCE:
      return { ...state, informationSource: action.data as string };
    case actions.INFORMATION_SOURCE_DETAILS:
      return { ...state, informationSourceDetails: action.data as string };
    case actions.LOCALE:
      return { ...state, locale: action.data as TranslationLanguageEnum };
    case actions.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
