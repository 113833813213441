import React, { InputHTMLAttributes } from "react";
import styles from "./RadioButton.module.scss";

export interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null;
  active: boolean;
  onClick: any;
  disabled: boolean
}

function RadioButton({ label, active, onClick, disabled }: LabeledInputProps) {
  return (
    <>
      <button disabled={disabled} type="button" className={styles.button} onClick={onClick}>
        {active ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
            <rect x="6" y="6" width="12" height="12" rx="6" fill="#5795FD" />
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D4D4D4" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D4D4D4" />
          </svg>
        )}
        <span>{label}</span>
      </button>
    </>
  );
}

export default RadioButton;
