import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import styles from "./PhoneInput.module.scss";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import cn from "classnames";

export interface PhoneInputProps {
  label?: string | null;
  children?: React.ReactNode;
  className?: string;
  errorBorder?: boolean;
  propRegister?: any;
  error?: string | false;
  mask?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (e:string) => void;
  name: string;
  [prop: string]: any;
}

function PhoneInput({ label, errorBorder, propRegister, error, mask, disabled, required, name, onChange, ...props }: PhoneInputProps) {
  return (
    <div>
      <label>{label}{required && <span className={styles.required}>*</span>}</label>
        <ReactPhoneInput
            inputProps={{
              name: name
            }}
            inputClass={cn(styles.labeled_input, errorBorder ? styles.errorBorder : "", disabled ? styles.disabled : "")}
            country={"ru"}
            specialLabel=""
            buttonStyle={{
                display: "none",
            }}
            value={props.value}
            disableDropdown
            enableAreaCodeStretch
            autoFormat={true}
            placeholder="+7 (999) 123-45-67"
            onChange={(value, country, e, formattedValue) => {
                onChange(value)
                if (value.length === 1) e.target.selectionStart = e.target.value.length;
            }}
            disabled={disabled}
        />
      {error && <span className={styles.red_color_text}>{error && error.toString()}</span>}
    </div>
  );
}

export default PhoneInput;
