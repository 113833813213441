import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import styles from "./LabeledInput.module.scss";
import InputMask from "react-input-mask";
import calendarIcon from "./static/calendar.svg";
import cn from "classnames";

export interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null;
  children?: React.ReactNode;
  className?: string;
  errorBorder?: boolean;
  propRegister?: any;
  error?: string | false;
  mask?: string;
  disabled?: boolean;
  required?: boolean;
  name: string;
  [prop: string]: any;
}

function LabeledInput({ label, errorBorder, propRegister, error, mask, disabled, required, name, ...props }: LabeledInputProps) {
  return (
    <div>
      <label>{label}{required && <span className={styles.required}>*</span>}</label>
      <div
        className={cn(styles.labeled_input, errorBorder ? styles.errorBorder : "", disabled ? styles.disabled : "")}
      >
        {props.type === "date" && (
          <img
            src={calendarIcon}
            width="20px"
            height="20px"
            onClick={(e) => {
              e.stopPropagation();
            }}
            alt="📅"
          ></img>
        )}

        {mask ? (
          <>
            {!propRegister && (
              <InputMask
                {...props}
                mask={mask}
                disabled={disabled}
                name={name}
              />
            )}
            {propRegister && (
              <InputMask
                {...propRegister}
                {...props}
                mask={mask}
                disabled={disabled}
                name={name}
              />
            )}
          </>
        ) : (
          <>
            {!propRegister && (
              <input
                {...props}
                disabled={disabled}
                name={name}
              />
            )}
            {propRegister && (
              <input
                {...propRegister}
                {...props}
                disabled={disabled}
                name={name}
              />
            )}
          </>
        )}
      </div>
      {error && <span className={styles.red_color_text}>{error && error.toString()}</span>}
    </div>
  );
}

export default LabeledInput;
