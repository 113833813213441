import React from "react";
import { Fields } from "../../content/fields";
import { Lang } from "src/types/common-types";
import style from "./AgreeBox.module.scss";
import infoIcon from "../../static/info-circle.svg";

import gs from "../../Layout.module.scss";

interface Props {
  lang: Lang;
  agree: boolean;
  clickHandler: (e: any) => void;
  clickIcon: (e: any) => void;
  disabled: boolean;
}

function AgreeBox({ lang, agree, clickHandler, clickIcon, disabled }: Props) {
  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={style.agreeBox}>
          <button disabled={disabled} type="button" onClick={clickHandler}>
            {agree ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="23" viewBox="0 0 48 23" fill="none">
                <rect y="1" width="48" height="23" rx="11.5" fill="#5795FD" />
                <circle cx="35.5" cy="12.5" r="8.5" fill="white" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="23" viewBox="0 0 48 23" fill="none">
                <rect width="48" height="23" rx="11.5" fill="#D9D9D9" />
                <circle cx="11.5" cy="11.5" r="8.5" fill="white" />
              </svg>
            )}
            <span>{Fields?.agreeMessage?.label[lang]}</span>
          </button>
          <img src={infoIcon} alt="&#8505;" onClick={clickIcon} />
        </div>
      </div>
    </div>
  );
}

export default AgreeBox;
