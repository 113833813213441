import React, { useState, useReducer, useMemo, useEffect, useRef, useContext } from "react";
import styles from "./VacancyPage.module.scss";
import gs from "../../Layout.module.scss";
import loadImage from "../../static/load-img.svg";
import Avatar from "../../components/Avatar/Avatar";
import MainInfo from "../../components/MainInfo";
import SocialLinks from "../../components/SocialLinks";
import AgreementAla from "../../components/Agreement/AgreementAla";
import AgreementMsk from "../../components/Agreement/AgreementMsk";
import Sex from "../../components/Sex/Sex";
import FamilyStatus from "../../components/FamilyStatus/FamilyStatus";
import DriverLicense from "../../components/DriverLicense/DriverLicense";
import KnowledgeLang from "../../components/KnowledgeLang/KnowledgeLang";
import Education from "../../components/Education/Education";
import Experience from "../../components/Experience/Experience";
import Portfolio from "../../components/Portfolio/Portfolio";
import AgreeBox from "../../components/AgreeBox/AgreeBox";
import { Lang } from "../../types/common-types";
import { Title } from "../../content/title";
import { reducer, INITIAL_STATE } from "../../store/store";
import { Fields } from "../../content/fields";
import TextArea from "../../components/inputs/TextArea";
import { validate } from "../../validate/validator";
import { submitForm } from "../../service/service";
import { actions } from "../../store/store";
import { Errors } from "../../content/errors";
import Contacts from "../../components/Contacts/Contacts";
import Messengers from "../../components/Messengers/Messengers";
import SelectInput from "../../components/inputs/SelectInput";
import LabeledInput from "../../components/inputs/LabeledInput";
import { CITY } from "../../service/irenta-server-parameters";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { LangContext } from "../../store/LangContext";
import closeIcon from "../../static/close_button.svg";
import { TranslationLanguageEnum } from "../../enums/TranslationLanguageEnums";

function VacancyPage() {
    const navigate = useNavigate();
    const submitRef = useRef<HTMLInputElement>(null)
    const lang = useContext(LangContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const vacancyId = JSON.parse(searchParams.get('vacancyId')??'')
    const showCar:boolean = JSON.parse(searchParams.get('showCar')??'false')
    const showPortfolio:boolean = JSON.parse(searchParams.get('showPortfolio')??'false')
    const showResume:boolean = JSON.parse(searchParams.get('showResume')??'false')
    const positionCandidate = JSON.parse(searchParams.get('positionCandidate')??'')
    const knowledgeLang = !!searchParams?.get('knowledgeLang') ? JSON.parse(searchParams?.get('knowledgeLang')??'') : []
    const driverLicenses = !!searchParams?.get('driverLicenses') ? JSON.parse(searchParams?.get('driverLicenses')??'') : []
    const schedule = +JSON.parse(searchParams.get('schedule')??'0')
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [validForm, setValidForm] = useState(false);
    const [clickSubmit, setClickSubmit] = useState(0)
    const invalidFields = useMemo(() => {
        return validForm ? validate(state, showResume, showPortfolio, schedule) : [];
    }, [state, validForm, showPortfolio, showResume, clickSubmit]);
    const [submitStatus, setSubmitStatus] = useState("");
    const [agree, setAgree] = useState(false);
    const onSubmit = async () => {
        setSubmitStatus("load");
        state.socialLinks = state.socialLinks.concat(...state.messengers)
        const status = await submitForm(state);
        console.log('status', status);
        if ((status as unknown | number) === 200) {
            setSubmitStatus("success")
        } else {
            setSubmitStatus("error");
        }
    };
    useEffect(() => {
        dispatch({
            type: actions.POSITION_CANDIDATE,
            data: positionCandidate,
        });
        dispatch({
            type: actions.VACANCY_ID,
            data: vacancyId,
        });
    }, [positionCandidate, vacancyId]);

    useEffect(() => {
        if (submitStatus === 'success') {
            dispatch({
                type: actions.RESET,
                data: '',
            });
            state.residenceAddress.country = ''
            state.residenceAddress.region = ''
            state.residenceAddress.city = ''
            state.residenceAddress.district = ''
            state.residenceAddress.street = ''
            state.residenceAddress.building = ''
            state.residenceAddress.apartments = ''
            state.residenceAddress.noApartments = false
            state.residenceAddress.postCode = ''
            state.residenceAddress.currentlyInAnotherCityAndReadyForMove = false
            dispatch({
                type: actions.RESIDENCE_ADDRESS,
                data: state.residenceAddress
            });
            navigate({
                pathname: '/success',
                search: createSearchParams({
                    lang: lang
                }).toString()
            })
        } 
        if (submitStatus === 'error') {
            navigate({
                pathname: '/error',
                search: createSearchParams({
                    lang: lang
                }).toString()
            })
        }
    }, [submitStatus])

    console.log('state', state)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        window.parentIFrame && window.parentIFrame.sendMessage('scrollTop')
    }, []);

    useEffect(() => {
        if (agree) {
            submitRef?.current?.scrollIntoView()
            window.parentIFrame && window.parentIFrame.sendMessage('scrollBottom')
        }
    }, [agree]);

    const [prerequisitesDisabled, setPrerequisitesDisabled] = useState(showPortfolio || showResume || driverLicenses?.length > 0 || knowledgeLang.length > 0);
    useEffect(() => {
        const prerequisitesProps =
            [
                showPortfolio && state.portfolioLink.length === 0,
                showResume && state.resumeLink.length === 0,
                driverLicenses.filter((item:any)=> item.length > 0)?.filter((e:any) => state?.driverLicenses?.includes(e))?.length !== driverLicenses?.length,
                knowledgeLang.filter((item:any)=> item.length > 0)?.filter((e:any) => state.languages.includes(e)).length !== knowledgeLang.length,
            ].find((e) => {
                return e === true;
            }) ?? false;
        setPrerequisitesDisabled(prerequisitesProps);
    }, [state.portfolioLink, state.resumeLink, state.driverLicenses, state.languages]);

    const informationSourceOptions = [
        { value: Fields.fromFriend.value, label: Fields.fromFriend?.label[lang] },
        { value: Fields.fromSocialNetworks.value, label: Fields.fromSocialNetworks?.label[lang] },
        { value: Fields.fromSite.value, label: Fields.fromSite?.label[lang] },
        { value: Fields.other.value, label: Fields.other?.label[lang] },
    ];
    const [agreementShow, setAgreementShow] = useState(false)
    useEffect(()=> {
        document.querySelector<HTMLElement>(`[name=${invalidFields[0]}]`)?.focus();
    },[validForm])
    useEffect(()=> {
        lang.length > 0 && dispatch({
            type: actions.LOCALE,
            data: TranslationLanguageEnum[lang as 'ru' | 'en' | 'kz']
        });
    }, [lang])
    return (
        <div>
            {!agreementShow &&<div className={styles.closeModalBtnBox}>
              <img
                src={closeIcon}
                alt="x"
                onClick={() => {
                    navigate({
                        pathname: '/',
                        search: createSearchParams({
                            lang: lang
                        }).toString()
                    })
                }}
              />
            </div>}
            {submitStatus === "load" ? (
                <div className={styles.loadImageBox}>
                    <img src={loadImage} alt="load" />
                </div>
            ) : agreementShow ? (
                CITY === 'ala' ?
                    <AgreementAla lang={lang} handleCloseModal={() => setAgreementShow(false)} handleAccept={() => setAgree(true)} /> :
                    <AgreementMsk lang={lang} handleCloseModal={() => setAgreementShow(false)} handleAccept={() => setAgree(true)} />
            ) : (
                <form className={styles.form}>
                    <div className={styles.form_title}>
                        <h1>{Title.mainTitle[lang]}</h1>
                        <span>{positionCandidate}</span>
                    </div>
                    <Avatar state={state} lang={lang as Lang} invalidFields={invalidFields} dispatch={dispatch} />
                    {/* {showPortfolio || showResume || driverLicenses?.length > 0 || knowledgeLang.length > 0 ? (
                        <div className={gs.row}>
                            <div className={gs.col12}>
                                <h2>{Title.prerequisites[lang]}<span className={styles.required}>*</span></h2>
                            </div>
                        </div>
                    ) : null} */}
                    {driverLicenses.filter((item:any)=> item.length > 0)?.length > 0 ? <DriverLicense lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} showCar={showCar} driverLicenses={driverLicenses} /> : null}
                    {knowledgeLang.filter((item:any)=> item.length > 0)?.length > 0 ? <KnowledgeLang knowledgeLang={knowledgeLang} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} /> : null}
                    {showResume || showPortfolio ? <Portfolio lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} showPortfolio={showPortfolio} showResume={showResume} /> : null}
                    <div className={gs.row}>
                        <div className={gs.col12}>
                            <h3>{Title.personalData[lang]}</h3>
                        </div>
                    </div>
                    <MainInfo disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} schedule={schedule} />
                    <Contacts disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                    <Messengers disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                    <SocialLinks disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                    <div className={gs.row}>
                        <div className={gs.col6}>
                            <Sex disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                        </div>
                        <div className={gs.col6}>
                            <FamilyStatus disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                        </div>
                    </div>
                    <Education disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                    <Experience disabled={prerequisitesDisabled} lang={lang as Lang} state={state} dispatch={dispatch} invalidFields={invalidFields} />
                    <div className={gs.row}>
                        <div className={gs.col12}>
                            <TextArea
                                name={"coveringLetter"}
                                className={prerequisitesDisabled ? styles.disabled : ""}
                                disabled={prerequisitesDisabled}
                                label={Fields.coveringLetter?.label[lang]}
                                onChange={(e) => {
                                    dispatch({
                                        type: actions.COVERING_LETTER,
                                        data: e.target.value,
                                    });
                                }}
                                value={state.coveringLetter}
                                required={true}
                                rows={7}
                                errorBorder={invalidFields.includes("coveringLetter")}
                                error={invalidFields.includes("coveringLetter") && Errors.coveringLetter[lang]}
                            />
                        </div>
                    </div>
                    <div className={gs.row}>
                        <div className={gs.col12}>
                            <SelectInput
                                name={'informationSource'}
                                disabled={prerequisitesDisabled}
                                options={informationSourceOptions}
                                changeHandler={(e: { value: string; label: string }) => {
                                    dispatch({
                                        type: actions.INFORMATION_SOURCE,
                                        data: e.value,
                                    });
                                }}
                                placeholder={Fields.informationSource?.label[lang]}
                                value={informationSourceOptions.find((e) => {
                                    return e.value === state.informationSource;
                                })}
                                required={true}
                                invalidFields={invalidFields}
                                errorBorder={invalidFields.includes("informationSource")}
                                error={invalidFields.includes("informationSource") && Errors.informationSource[lang]}
                            />
                        </div>
                    </div>
                    {state.informationSource === '0' && <div className={gs.row}>
                        <div className={gs.col12}>
                            <LabeledInput
                                name={'informationSourceDetails'}
                                disabled={prerequisitesDisabled}
                                label={Fields.informationSourceDetails?.label[lang]}
                                onChange={(e) => {
                                    dispatch({
                                        type: actions.INFORMATION_SOURCE_DETAILS,
                                        data: e.target.value,
                                    });
                                }}
                                value={state.informationSourceDetails}
                                required={false}
                            />
                        </div>
                    </div>}

                    <AgreeBox
                        disabled={prerequisitesDisabled}
                        lang={lang as Lang}
                        agree={agree}
                        clickHandler={(e) => {
                            setAgree(!agree);
                        }}
                        clickIcon={(e) => {
                            if (!prerequisitesDisabled) setAgreementShow(true);
                        }}
                    />
                    <div className={gs.row} ref={submitRef}>
                        <div className={gs.col12}>
                            <button
                                className={styles.redButton}
                                type="submit"
                                disabled={!agree}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setValidForm(true);
                                    console.log("invalidFields", invalidFields);
                                    document.querySelector<HTMLElement>(`[name=${invalidFields[0]}]`)?.focus();
                                    setClickSubmit(clickSubmit+1)
                                    if (validForm && invalidFields.length === 0) onSubmit();
                                }}
                            >
                                {Fields.submit?.label[lang]}
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}

export default VacancyPage;
