import React, { useState, useRef } from "react";
import styles from "./Avatar.module.scss";
import plusIcon from "../../static/file_add.svg";
import loaderIcon from "../../static/loader.svg";
import deleteIcon from "../../static/remove-white.svg";
import jpgIcon from "../../static/jpg.svg";
import { Fields } from "../../content/fields";
import gs from "../../Layout.module.scss";
import { uploadFile } from "../../service/service";
import { Dispatch } from "../../types/store-types";
import { actions } from "../../store/store";
import { Errors } from "../../content/errors";
import Applicant from "src/types/applicant";

export interface AvatarProps {
  lang: string;
  invalidFields: (string | false)[];
  dispatch: Dispatch;
  state: Applicant;
}

function Avatar({ state, lang, invalidFields, dispatch }: AvatarProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadError, setImageUploadError] = useState<any | null>(null);

  return (
    <div className={gs.row}>
      <div className={gs.col12}>
        <div className={styles.Avatar}>
          {state.photoUrl.length > 0 ? (
            <div className={styles.image}>
              <img src={state.photoUrl} alt="&#128100;"/>
              <div className={styles.dropdown}>
                <button type={"button"} onClick={() => {
                  dispatch({
                    type: actions.PHOTO,
                    data: {
                      id: 0,
                      fileName: "",
                    },
                  });
                  dispatch({
                    type: actions.PHOTO_URL,
                    data: "",
                  });
                }}>
                  <img src={deleteIcon}/>
                </button>
              </div>
            </div>
          ) : (
            <>
            <button name={'photo'} type="button" onClick={() => {
                if (imageUploading) return;
                if (inputRef.current) inputRef.current.value = "";
                inputRef.current?.click()
                setImageUploadError(null)
              }} className={(invalidFields.includes("photo") || imageUploadError) && styles.errorBorder}>
                {imageUploading
                  ? <>
                    <img src={loaderIcon} className={gs.loader}/>
                  </>
                  : imageUploadError
                    ? <>
                      <img src={jpgIcon}/>
                      <span className={styles.red}>{Errors.error[lang]}</span>
                    </>
                    : <>
                      <img src={plusIcon} alt="&#10010;"/>
                      <span>{Fields.photo?.label[lang]}<span className={styles.red}>*</span></span>
                      <span className={styles.placeholder}>{Fields.photo.placeholder?.[lang]}</span>
                    </>
                }
                <input
                  ref={inputRef}
                  type="file"
                  accept={"image/png,image/jpeg"}
                  onChange={async (e) => {
                    setImageUploading(true)
                    const formData = new FormData();
                    formData.append("uploadedFormFile", e.target.files ? e.target.files[0] : "");
                    try {
                      const result = await uploadFile(formData);
                      if (result) {
                        dispatch({
                          type: actions.PHOTO,
                          data: {
                            id: result.fileType,
                            fileName: result.fileName,
                          },
                        });
                        dispatch({
                          type: actions.PHOTO_URL,
                          data: e.target.files ? URL.createObjectURL(e.target.files[0]) : "",
                        });
                      }
                    } catch (e) {
                      setImageUploadError(e)
                    } finally {
                      setImageUploading(false)
                    }
                  }}
                />
              </button>
              {invalidFields.includes("photo") && <span className={styles.red_color_text}>{Errors.photo[lang]}</span>}
              {imageUploadError && <span className={styles.red_color_text}>{Errors.photoUploadTooLarge[lang]}</span>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Avatar;
