import { DistrictProps } from "src/types/common-types"

const SpbDistrict: DistrictProps[] = [
    {
        value: {
            ru: 'Адмиралтейский район',
            en: 'Admiralteysky district',
            kz: 'Адмиралтейский район'
        },
        label: {
            ru: 'Адмиралтейский район',
            en: 'Admiralteysky district',
            kz: 'Адмиралтейский район'
        },
    },
    {
        value: {
            ru: 'Василеостровский район',
            en: 'Vasileostrovsky district',
            kz: 'Василеостровский район'
        },
        label: {
            ru: 'Василеостровский район',
            en: 'Vasileostrovsky district',
            kz: 'Василеостровский район'
        },
    },
    {
        value: {
            ru: 'Выборгский район',
            en: 'Vyborgsky district',
            kz: 'Выборгский район'
        },
        label: {
            ru: 'Выборгский район',
            en: 'Vyborgsky district',
            kz: 'Выборгский район'
        },
    },
    {
        value: {
            ru: 'Калининский район',
            en: 'Kalininsky district',
            kz: 'Калининский район'
        },
        label: {
            ru: 'Калининский район',
            en: 'Kalininsky district',
            kz: 'Калининский район'
        },
    },
    {
        value: {
            ru: 'Кировский район',
            en: 'Kirovsky district',
            kz: 'Кировский район'
        },
        label: {
            ru: 'Кировский район',
            en: 'Kirovsky district',
            kz: 'Кировский район'
        },
    },
    {
        value: {
            ru: 'Колпинский район',
            en: 'Kolpinsky district',
            kz: 'Колпинский район'
        },
        label: {
            ru: 'Колпинский район',
            en: 'Kolpinsky district',
            kz: 'Колпинский район'
        },
    },
    {
        value: {
            ru: 'Красногвардейский район',
            en: 'Krasnogvardeysky district',
            kz: 'Красногвардейский район'
        },
        label: {
            ru: 'Красногвардейский район',
            en: 'Krasnogvardeysky district',
            kz: 'Красногвардейский район'
        },
    },
    {
        value: {
            ru: 'Красносельский район',
            en: 'Krasnoselsky district',
            kz: 'Красносельский район'
        },
        label: {
            ru: 'Красносельский район',
            en: 'Krasnoselsky district',
            kz: 'Красносельский район'
        },
    },
    {
        value: {
            ru: 'Кронштадтcкий район',
            en: 'Kronshtadtsky district',
            kz: 'Кронштадтcкий район'
        },
        label: {
            ru: 'Кронштадтcкий район',
            en: 'Kronshtadtsky district',
            kz: 'Кронштадтcкий район'
        },
    },
    {
        value: {
            ru: 'Курортный район',
            en: 'Kurortny district',
            kz: 'Курортный район'
        },
        label: {
            ru: 'Курортный район',
            en: 'Kurortny district',
            kz: 'Курортный район'
        },
    },
    {
        value: {
            ru: 'Московский район',
            en: 'Moskovsky district',
            kz: 'Московский район'
        },
        label: {
            ru: 'Московский район',
            en: 'Moskovsky district',
            kz: 'Московский район'
        },
    },
    {
        value: {
            ru: 'Невский район',
            en: 'Nevsky district',
            kz: 'Невский район'
        },
        label: {
            ru: 'Невский район',
            en: 'Nevsky district',
            kz: 'Невский район'
        },
    },
    {
        value: {
            ru: 'Петроградский район',
            en: 'Petrogradsky district',
            kz: 'Петроградский район'
        },
        label: {
            ru: 'Петроградский район',
            en: 'Petrogradsky district',
            kz: 'Петроградский район'
        },
    },
    {
        value: {
            ru: 'Петродворцовый район',
            en: 'Petrodvortsovy district',
            kz: 'Петродворцовый район'
        },
        label: {
            ru: 'Петродворцовый район',
            en: 'Petrodvortsovy district',
            kz: 'Петродворцовый район'
        },
    },
    {
        value: {
            ru: 'Приморский район',
            en: 'Primorsky district',
            kz: 'Приморский район'
        },
        label: {
            ru: 'Приморский район',
            en: 'Primorsky district',
            kz: 'Приморский район'
        },
    },
    {
        value: {
            ru: 'Пушкинский район',
            en: 'Pushkinsky district',
            kz: 'Пушкинский район'
        },
        label: {
            ru: 'Пушкинский район',
            en: 'Pushkinsky district',
            kz: 'Пушкинский район'
        },
    },
    {
        value: {
            ru: 'Фрунзенский район',
            en: 'Frunzensky district',
            kz: 'Фрунзенский район'
        },
        label: {
            ru: 'Фрунзенский район',
            en: 'Frunzensky district',
            kz: 'Фрунзенский район'
        },
    },
    {
        value: {
            ru: 'Центральный район',
            en: 'Central District',
            kz: 'Центральный район'
        },
        label: {
            ru: 'Центральный район',
            en: 'Central District',
            kz: 'Центральный район'
        },
    },
]
export default SpbDistrict