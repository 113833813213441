import { DistrictProps } from "src/types/common-types"

const AlaDistrict: DistrictProps[] = [
    {
        value: {
            ru: 'Алатауский район',
            en: 'Alatau district',
            kz: 'Алатау ауданы'
        },
        label: {
            ru: 'Алатауский район',
            en: 'Alatau district',
            kz: 'Алатау ауданы'
        },
    },
    {
        value: {
            ru: 'Алмалинский район',
            en: 'Almaly district',
            kz: 'Алмалы ауданы'
        },
        label: {
            ru: 'Алмалинский район',
            en: 'Almaly district',
            kz: 'Алмалы ауданы'
        },
    },
    {
        value: {
            ru: 'Ауэзовский район',
            en: 'Auezov district',
            kz: 'Әуезов ауданы'
        },
        label: {
            ru: 'Ауэзовский район',
            en: 'Auezov district',
            kz: 'Әуезов ауданы'
        },
    },
    {
        value: {
            ru: 'Бостандыкский район',
            en: 'Bostandyq district',
            kz: 'Бостандық ауданы'
        },
        label: {
            ru: 'Бостандыкский район',
            en: 'Bostandyq district',
            kz: 'Бостандық ауданы'
        },
    },
    {
        value: {
            ru: 'Жетысуский район',
            en: 'Jetysu district',
            kz: 'Жетісу ауданы'
        },
        label: {
            ru: 'Жетысуский район',
            en: 'Jetysu district',
            kz: 'Жетісу ауданы'
        },
    },
    {
        value: {
            ru: 'Медеуский район',
            en: 'Medeu district',
            kz: 'Медеу ауданы'
        },
        label: {
            ru: 'Медеуский район',
            en: 'Medeu district',
            kz: 'Медеу ауданы'
        },
    },
    {
        value: {
            ru: 'Наурызбайский район',
            en: 'Nauryzbai district',
            kz: 'Наурызбай ауданы'
        },
        label: {
            ru: 'Наурызбайский район',
            en: 'Nauryzbai district',
            kz: 'Наурызбай ауданы'
        },
    },
    {
        value: {
            ru: 'Турксибский район',
            en: 'Turksib district',
            kz: 'Түрксіб ауданы'
        },
        label: {
            ru: 'Турксибский район',
            en: 'Turksib district',
            kz: 'Түрксіб ауданы'
        },
    },
]
export default AlaDistrict