import { RegionCity } from '../../types/common-types'

const KzRegionCityListKz: RegionCity[] = [
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Боголюбово',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Булаево',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Дубровное',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Боз',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Қара-Қуға',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Мамлютка',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Налобино',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Новоникольское',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Новопокровка',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Петропавл',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Полудино',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Пресновка',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Соколовка',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Екатриновка',
  },
  {
    region: 'Солтүстік Қазақстан облысы',
    city: 'Қызылағаш',
  },
  {
    region: 'Ақмола облысы',
    city: 'Астана',
  },
  {
    region: 'Ақмола облысы',
    city: 'Атбасар',
  },
  {
    region: 'Ақмола облысы',
    city: 'Новорыбинка',
  },
  {
    region: 'Ақмола облысы',
    city: 'Көкшетав',
  },
  {
    region: 'Ақмола облысы',
    city: 'Есіл',
  },
  {
    region: 'Ақмола облысы',
    city: 'Ақкөл',
  },
  {
    region: 'Ақмола облысы',
    city: 'Молодежная',
  },
  {
    region: 'Ақмола облысы',
    city: 'Қызылтан',
  },
  {
    region: 'Ақмола облысы',
    city: 'Алексеевка',
  },
  {
    region: 'Ақмола облысы',
    city: 'Өзен (Раздольное)',
  },
  {
    region: 'Ақмола облысы',
    city: 'Азат',
  },
  {
    region: 'Ақмола облысы',
    city: 'Өркен (Куропаткино)',
  },
  {
    region: 'Ақмола облысы',
    city: 'Теректі (Лайнеевка)',
  },
  {
    region: 'Ақмола облысы',
    city: 'Ұялы (С.О. Чаглинский)',
  },
  {
    region: 'Ақмола облысы',
    city: 'Күлет',
  },
  {
    region: 'Ақмола облысы',
    city: 'Жамантұз',
  },
  {
    region: 'Ақмола облысы',
    city: 'Щучинск',
  },
  {
    region: 'Ақмола облысы',
    city: 'Ақылбай',
  },
  {
    region: 'Ақмола облысы',
    city: 'Ортак',
  },
  {
    region: 'Ақмола облысы',
    city: 'Ағымдағы',
  },
  {
    region: 'Ақмола облысы',
    city: 'Бидайық (Бидайық)',
  },
  {
    region: 'Ақмола облысы',
    city: 'Каскат',
  },
  {
    region: 'Ақмола облысы',
    city: 'Жаңалық (Ақмола облысы)',
  },
  {
    region: 'Ақмола облысы',
    city: 'Алыс',
  },
  {
    region: 'Ақмола облысы',
    city: 'Бауырқарағай',
  },
  {
    region: 'Ақмола облысы',
    city: 'Ахлбек',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Ақшымрау',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Бейнеу',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Аққабақ',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Ақтөбе',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Алға',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Берчогур',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Бестамақ',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Ұлпан',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Шошқакөл',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Ырғыз',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Калининовка',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Қаратоғай',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Қарауылкелді',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Казак',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Хромтау',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Жұдырықтай жұ',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Ноғайлылар',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Новороссийск',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Бақ',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Сарыоба',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Школь',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Соқырбұлақ',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Қарабұтақ',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Құрмансай (Степановка)',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Темір',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Тоғыз',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Ойыл',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Октябрь таңы',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Жарқамыс',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Шалқар',
  },
  {
    region: 'Ақтөбе облысы',
    city: 'Маржанбұлақ (Прогресс)',
  },
  {
    region: 'Алматы облысы',
    city: 'Алматы',
  },
  {
    region: 'Алматы облысы',
    city: 'Бақанас',
  },
  {
    region: 'Алматы облысы',
    city: 'Бірлік',
  },
  {
    region: 'Алматы облысы',
    city: 'Шалқыдысу',
  },
  {
    region: 'Алматы облысы',
    city: 'Тоқжайлау',
  },
  {
    region: 'Алматы облысы',
    city: 'Қонаев',
  },
  {
    region: 'Алматы облысы',
    city: 'Қараой',
  },
  {
    region: 'Алматы облысы',
    city: 'Кокс',
  },
  {
    region: 'Алматы облысы',
    city: 'Көктұма',
  },
  {
    region: 'Алматы облысы',
    city: 'Құйған',
  },
  {
    region: 'Алматы облысы',
    city: 'Лепсі',
  },
  {
    region: 'Алматы облысы',
    city: 'Матай',
  },
  {
    region: 'Алматы облысы',
    city: 'Жаркент',
  },
  {
    region: 'Алматы облысы',
    city: 'Сарқант',
  },
  {
    region: 'Алматы облысы',
    city: 'Сарыөзек',
  },
  {
    region: 'Алматы облысы',
    city: 'Талды-Қорған',
  },
  {
    region: 'Алматы облысы',
    city: 'Тегермен',
  },
  {
    region: 'Алматы облысы',
    city: 'Текелі',
  },
  {
    region: 'Алматы облысы',
    city: 'Үштөбе',
  },
  {
    region: 'Алматы облысы',
    city: 'Талғар',
  },
  {
    region: 'Алматы облысы',
    city: 'Томар',
  },
  {
    region: 'Алматы облысы',
    city: 'Нарынқол',
  },
  {
    region: 'Алматы облысы',
    city: 'Чунжа',
  },
  {
    region: 'Алматы облысы',
    city: 'Кеген',
  },
  {
    region: 'Алматы облысы',
    city: 'Жаркент',
  },
  {
    region: 'Алматы облысы',
    city: 'Есік',
  },
  {
    region: 'Алматы облысы',
    city: 'Костөбе',
  },
  {
    region: 'Алматы облысы',
    city: 'Боялы',
  },
  {
    region: 'Алматы облысы',
    city: 'Барибаева',
  },
  {
    region: 'Алматы облысы',
    city: 'Ақкөл (Алматы облысы)',
  },
  {
    region: 'Алматы облысы',
    city: 'Бақбақты',
  },

  {
    region: 'Атырау облысы',
    city: 'Атырау',
  },
  {
    region: 'Атырау облысы',
    city: 'Айбас',
  },
  {
    region: 'Атырау облысы',
    city: 'Baišūnas',
  },
  {
    region: 'Атырау облысы',
    city: 'Доссор',
  },
  {
    region: 'Атырау облысы',
    city: 'Ендірбор',
  },
  {
    region: 'Атырау облысы',
    city: 'Қаратон',
  },
  {
    region: 'Атырау облысы',
    city: 'Комсомольский',
  },
  {
    region: 'Атырау облысы',
    city: 'Құлсары',
  },
  {
    region: 'Атырау облысы',
    city: 'Мақат',
  },
  {
    region: 'Атырау облысы',
    city: 'Миялы',
  },
  {
    region: 'Атырау облысы',
    city: 'Мұқыр',
  },
  {
    region: 'Атырау облысы',
    city: 'Мұнайлы',
  },
  {
    region: 'Атырау облысы',
    city: 'Прорва',
  },
  {
    region: 'Атырау облысы',
    city: 'Сарайшық',
  },
  {
    region: 'Атырау облысы',
    city: 'Забурунье',
  },
  {
    region: 'Атырау облысы',
    city: 'Жамансор',
  },
  {
    region: 'Атырау облысы',
    city: 'Жанғали',
  },
  {
    region: 'Атырау облысы',
    city: 'Ақиқат',
  },
  {
    region: 'Атырау облысы',
    city: 'Аққызтоғай',
  },
  {
    region: 'Атырау облысы',
    city: 'Аққыстау',
  },
  {
    region: 'Атырау облысы',
    city: 'Атекб',
  },
  {
    region: 'Атырау облысы',
    city: 'Айртам',
  },
  {
    region: 'Атырау облысы',
    city: 'Азғыр',
  },
  {
    region: 'Атырау облысы',
    city: 'Балқұдық',
  },
  {
    region: 'Атырау облысы',
    city: 'Бірлік',
  },
  {
    region: 'Атырау облысы',
    city: 'Бөгет',
  },
  {
    region: 'Атырау облысы',
    city: 'Қамынин',
  },
  {
    region: 'Атырау облысы',
    city: 'Қарасыр',
  },
  {
    region: 'Атырау облысы',
    city: 'Қазбек',
  },
  {
    region: 'Атырау облысы',
    city: 'Қосшағыл',
  },
  {
    region: 'Атырау облысы',
    city: 'Махамбет',
  },
  {
    region: 'Атырау облысы',
    city: 'Новобогатинское',
  },
  {
    region: 'Атырау облысы',
    city: 'Жаңа Үштаған',
  },
  {
    region: 'Атырау облысы',
    city: 'Орпа',
  },
  {
    region: 'Атырау облысы',
    city: 'Жаяу',
  },
  {
    region: 'Атырау облысы',
    city: 'Сағыз',
  },
  {
    region: 'Атырау облысы',
    city: 'Шоқпартоғай',
  },
  {
    region: 'Атырау облысы',
    city: 'Тандау',
  },
  {
    region: 'Атырау облысы',
    city: 'Тас',
  },
  {
    region: 'Атырау облысы',
    city: 'Төлеген',
  },
  {
    region: 'Атырау облысы',
    city: 'Жанбай',
  },
  {
    region: 'Атырау облысы',
    city: 'Жалғызапан',
  },
  {
    region: 'Атырау облысы',
    city: 'Жасқайрат',
  },
  {
    region: 'Атырау облысы',
    city: 'Құрманғазы',
  },

  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Ақсуат',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Ақжал',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Аягөз',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Бақты',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Белағаш',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Жалаңаш',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Чердояк',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Шұбартау',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Дөнненбай',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Қараауыл ',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Қарабұлақ',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Қарағожиха',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Қаратан',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Қызылоғыз',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Лениногорск',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Рахмановское',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Семей',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Шемонаиха',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Әлеумет',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Таскескен',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Үржар',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Ұрыл',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Өскемен',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Зайсан',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Жарма',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Знаменка',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Зырян',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Сергеевка',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Прапорщиково',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Украинка',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Уварово',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Глубокое',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Белоусовка',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Бобровка (Глубокое ауданы)',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Шарап',
  },
  {
    region: 'Шығыс Қазақстан облысы',
    city: 'Секисовка',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Ақжар',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Алғатарт',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Чо',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Тараз',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Мойынқұм',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Қамқалы',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Қаратау',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Мыңарал',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Отар',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Ұйық',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Жаңатас',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Мерке',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Шалдавар ',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Жамбул (Мерке р. м) деген жолмен ауыстырылсын',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Интернационалды',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Ойталь',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Тәтті',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Қазақ',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Шалғындық',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Құлан',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Көгершін',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Ақыртөбе',
  },
  {
    region: 'Жамбыл облысы',
    city: 'Өрнек (Рысқұлов Р. м) деген жолмен ауыстырылсын',
  },

  {
    region: 'Батыс Қазақстан облысы',
    city: 'Ақсу',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Қайыңды',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Битик',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Болашақ (Казталов)',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Бөгет',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Бостандық',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Бұлдырты',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Чапаев',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Чижа-2',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Жамбайт',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Сарыкөл',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Жалпақтал',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Тайпақ',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Казталовка',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Ақшат',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Мәстексай',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Мергенево ',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Жаңақазан',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Орал',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Ащысай',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Сергей ауласы',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Шалқар',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Аққұрай',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Тау',
  },
  {
    region: 'Батыс Қазақстан облысы',
    city: 'Жетібай',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Абай',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Ағадыр',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Актас',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Ақтоғай',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Актума',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Айшырақ',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Балқаш',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Жезқазған',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қарағанды',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қаражал',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қаражынғыл',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қарқаралы',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қарсақбай',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Кенсе',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Киевка',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қорғасын',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қызылдықан',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қызыл-Жар',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қызыл коммунасы',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қызылтау',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қызылұй',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Мойнақ',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Сәтбаев',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Нұра',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Саран',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Сарышаған',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Саяқ',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Шахтинск ',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Шалғия',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Сонал',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Соролен',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Таң',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Тасарал',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Теміртау',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Торабай',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Успенский',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Егіндібұлақ',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Жантели',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Шахан',
  },
  {
    region: 'Қарағанды облысы',
    city: 'Қаражар',
  },

  {
    region: 'Қостанай облысы',
    city: 'Тамқамыс',
  },
  {
    region: 'Қостанай облысы',
    city: 'Торғай',
  },
  {
    region: 'Қостанай облысы',
    city: 'Ақши',
  },
  {
    region: 'Қостанай облысы',
    city: 'Амангелді',
  },
  {
    region: 'Қостанай облысы',
    city: 'Арқалық',
  },
  {
    region: 'Қостанай облысы',
    city: 'Біралы',
  },
  {
    region: 'Қостанай облысы',
    city: 'Жетіғара',
  },
  {
    region: 'Қостанай облысы',
    city: 'Кокалат',
  },
  {
    region: 'Қостанай облысы',
    city: 'Қостанай',
  },
  {
    region: 'Қостанай облысы',
    city: 'Қызылжар ',
  },
  {
    region: 'Қостанай облысы',
    city: 'Пресногорьковка',
  },
  {
    region: 'Қостанай облысы',
    city: 'Сага',
  },
  {
    region: 'Қостанай облысы',
    city: 'Шёнбер',
  },
  {
    region: 'Қостанай облысы',
    city: 'Фрунзеское',
  },
  {
    region: 'Қостанай облысы',
    city: 'Рудный',
  },
  {
    region: 'Қостанай облысы',
    city: 'Аятское',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Ұзынқайыр',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Байқоңыр',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Accespe    ',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Арал',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Жақсықылыш',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Ауан',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Айдарлы',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Байгекум',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Чийли',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Диермен төбе',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Жусалы',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Ерімбет',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Қасқақұлан',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Қазалы',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Құланды',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Қызылорда',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Әйтеке би',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Сексеуіл',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Сұлутөбе',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Тереңөзек',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Жан-Қорған',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Айдарлы',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Бесарық',
  },
  {
    region: 'Қызылорда облысы',
    city: 'Талап',
  },

  {
    region: 'Маңғыстау облысы',
    city: 'Форт-Шевченко',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Құлтау',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Күйші',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Маңғышлақ',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Жаңаөзен',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Боранқұл (өнер.  Қолдау көрсету)',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Саура',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Сай өтес',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Саина Шапағатова',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Шетпе',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Ақтау',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Таушық',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Үштаған',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Имени Калинин',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Баутино',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Қызық',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Құрық',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Ералиева',
  },
  {
    region: 'Маңғыстау облысы',
    city: 'Жоңғылды (Куйбышево)',
  },
  {
    region: 'Павлодар облысы',
    city: 'Алексеевка',
  },
  {
    region: 'Павлодар облысы',
    city: 'Павлодар',
  },
  {
    region: 'Павлодар облысы',
    city: 'Екібастұз',
  },

  {
    region: 'Түркістан облысы',
    city: 'Ақсұмбе',
  },
  {
    region: 'Түркістан облысы',
    city: 'Арыс',
  },
  {
    region: 'Түркістан облысы',
    city: 'Бағара',
  },
  {
    region: 'Түркістан облысы',
    city: 'Байрқұм',
  },
  {
    region: 'Түркістан облысы',
    city: 'Байжансай',
  },
  {
    region: 'Түркістан облысы',
    city: 'Шардара',
  },
  {
    region: 'Түркістан облысы',
    city: 'Шымкент (Шымкент)',
  },
  {
    region: 'Түркістан облысы',
    city: 'Шолаққорған',
  },
  {
    region: 'Түркістан облысы',
    city: 'Фоголево',
  },
  {
    region: 'Түркістан облысы',
    city: 'Кентау',
  },
  {
    region: 'Түркістан облысы',
    city: 'Көксарай',
  },
  {
    region: 'Түркістан облысы',
    city: 'Сарыағаш',
  },
  {
    region: 'Түркістан облысы',
    city: 'Созақ',
  },
  {
    region: 'Түркістан облысы',
    city: 'Дастарқан',
  },
  {
    region: 'Түркістан облысы',
    city: 'Түркістан',
  },
  {
    region: 'Түркістан облысы',
    city: 'Жетісай',
  },
  {
    region: 'Түркістан облысы',
    city: 'Отырар',
  },
  {
    region: 'Түркістан облысы',
    city: 'Қарашық',
  },
  {
    region: 'Түркістан облысы',
    city: 'Құшата',
  },
  {
    region: 'Түркістан облысы',
    city: 'Баялдыр',
  },
  {
    region: 'Түркістан облысы',
    city: 'Бостандық',
  },
  {
    region: 'Түркістан облысы',
    city: 'Уранғай',
  },
  {
    region: 'Түркістан облысы',
    city: 'Шипан',
  },
  {
    region: 'Түркістан облысы',
    city: 'Коммуна',
  },
  {
    region: 'Түркістан облысы',
    city: 'Қызылжол',
  },
  {
    region: 'Түркістан облысы',
    city: 'Қарнақ',
  },
  {
    region: 'Түркістан облысы',
    city: 'Шаштөбе',
  },
  {
    region: 'Түркістан облысы',
    city: 'Құмайлықас',
  },
  {
    region: 'Түркістан облысы',
    city: 'Серт',
  },
  {
    region: 'Түркістан облысы',
    city: 'Барисовка',
  },
  {
    region: 'Түркістан облысы',
    city: 'Шұбанақ',
  },
  {
    region: 'Түркістан облысы',
    city: 'Старойкан',
  },
  {
    region: 'Түркістан облысы',
    city: 'Иттифак',
  },
  {
    region: 'Түркістан облысы',
    city: 'Теке',
  },
  {
    region: 'Түркістан облысы',
    city: 'Қазақстанның 30 жылдығы',
  },
  {
    region: 'Түркістан облысы',
    city: 'Ибата',
  },
  {
    region: 'Түркістан облысы',
    city: 'Сауран',
  },
  {
    region: 'Түркістан облысы',
    city: 'Жаңа Сауран',
  },
  {
    region: 'Түркістан облысы',
    city: 'Бабайқұрған',
  },
  {
    region: 'Түркістан облысы',
    city: 'Ленгер',
  },
]
export default KzRegionCityListKz
